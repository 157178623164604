import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
const json = {
  1: {
    unit: "Review 4",
    id: "SB5-2024-R4-P94-E1",
    audio: "Audios/Page94/143.mp3",
    exerciseKey: "img/FriendsPlus/Page94/Key/E1answerKey.png",
    component: Circle_Write,
    inputSize: 500,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen and check (✓) the correct answer. <headphone name='143' src='Audios/Page94/143 tieu-de.mp3'></headphone>",
        color: "#203c8f",
        width: "25cm",
        numberImg: ["b"],
      },
    ],
    // hideBtnFooter: true,
    question: {
      // leftContent: 100,
      DrawLines: {
        multipleLine: true,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        Tick: true,
        initialWordStyle: {
          // padding: "1px 8px",
          // border: "2px solid gray",
          // borderRadius: "25%",
          fontWeight: "bold",
          color: "white",
        },
        selectWordStyle: {
          fontWeight: "bold",
          // padding: "1px 8px",
          color: "#00aeef",
          // border: "2px solid gray",
          // borderRadius: "25%",
          // borderBottom: "2px solid #00aeef",
          // borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "✓ <br> ✓ <br> ✓",
          "✓ <br> ✓ <br> ✓",
          "✓ <br> ✓ <br> ✓",
          "✓ <br> ✓ <br> ✓",
          "✓ <br> ✓ <br> ✓",
        ],
        answers: ["0-8", "1-8", "3-8", "2-0", "4-4"],
        initialValue: ["0-8"],
      },
      Layout: `
    <div style='position: relative'>

    <div style='display:flex; margin-left: 100px'>
    <div>
        <div style='line-height:45px; margin-bottom: 10px'>
            <div><b>1.&ensp;</b>What's the matter with Dan?</div>
            <div>
              a. Dan has a toothache and a sore throat.<br>
              b. Dan has a headache and a fever.<br>
              c. Dan has a toothache and a fever.
            </div>
        </div>

        <div style='line-height:45px; margin-bottom: 10px'>
            <div><b>2.&ensp;</b>What should Rose do?</div>
            <div>
              a. She should drink some juice.<br>
              b. She should watch TV.<br>
              c. She should drink some water and go to bed.
            </div>
        </div>

        <div style='line-height:45px; margin-bottom: 10px'>
            <div><b>3.&ensp;</b>Why does Emma have a stomachache?</div>
            <div>
              a. She ate too much candy.<br>
              b. She ate too much apples.<br>
              c. She drank too much soda.
            </div>
        </div>
        
        <div style='line-height:45px; margin-bottom: 10px'>
            <div><b>4.&ensp;</b>What does Andy want to do?</div>
            <div>
              a. He wants to go to bed.<br>
              b. He want to watch TV.<br>
              c. He wants to play outside.
            </div>
        </div>

        <div style='line-height:45px; margin-bottom: 10px'>
            <div><b>5.&ensp;</b>What's the matter with Dad?</div>
            <div>
              a. He's hungry.<br>
              b. He's ill.<br>
              c. He's tired.
            </div>
        </div>
        </div>

        <div>
        
        </div>
        <div style='line-height: 45px; margin-left: 565px; position: absolute; z-index: 1; top: 54px'>
          <div style='margin-bottom: 50px'>
            <input id=0 type='Circle' />
          </div>
          <div style='margin-bottom: 50px'>
            <input id=1 type='Circle' />
          </div>
          <div style='margin-bottom: 50px; '>
            <input id=2 type='Circle' />
          </div>
          <div style='margin-bottom: 50px'>
            <input id=3 type='Circle' />
          </div>
          <div>
            <input id=4 type='Circle' />
          </div>
        </div>
        <div style='position: absolute; top: 50px; left: 636px; z-index: 0'>
          <img style='height:24cm' src='img/FriendsPlus/Page94/E1/1.png'/>
        </div>

        <div>
        
        </div>
    </div>
      
      `,
    },
  },

  2: {
    unit: "Review 4",
    id: "SB5-2024-R4-P94-E2",
    audio: "Audios/Page94/144.mp3",
    exerciseKey: "img/FriendsPlus/Page94/Key/E2answerKey.png",
    video: "",
    component: T6,
    // maxLength: 6,
    textAlign: "center",
    inputSize: 170,
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      // fontSize: 22,
      height: 27,
      textTransform: "lowercase",
      // borderStyle: "dotted",
      textAlign: "center",
    },
    // hintBox: [
    //   {
    //     src: ["fast", "polite", "hard", "neat", "loud", "<s>good</s>"],
    //     width: 682,
    //   },
    // ],
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and complete. <headphone name='144' src='Audios/Page94/144 tieu-de.mp3'></headphone>",
        color: "#203c8f",
        numberImg: ["a"],
      },
    ],
    // hideBtnFooter: true,
    // selectStyle: {
    //   height: 35,
    //   width: 35,
    //   textAlign: "center",
    // },
    // selectOptionRandom: true,
    // selectOptionValues: ["T", "F"],
    questions: [
      {
        title: `
        <div style=' margin-left: -20px'>
          <div style='width: 20cm; line-height: 45px'>In the <u style='color: rgb(54,166,215) '>&ensp;future&ensp;</u>, people <sup><b>1</b></sup># drive motobikes or cars. 
          They will travel around Earth on a <sup><b>2</b></sup># plane. People will live on different <sup><b>3</b></sup># away from Earth, like
           Mars or Venus. Every year, people will fly to other <sup><b>4</b></sup># for vacations by spaceship or rocket. 
           They will wear <sup><b>5</b></sup># clothes and eat space food.

        
          </div>
        <img style='height:8cm; margin-top: 30px' src='img/FriendsPlus/Page94/E2/1.jpg'/>
        </div>
          
        
        `,
        // initialValue: ["math", "", "", "", "", "", "", ""],
        answer: ["won't", "supersonic", "planets", "planets", "astronauts'"],
      },
    ],
  },
};

export default json;
