import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
const json = {
  1: {
    unit: "Unit 5",
    id: "SB5-2024-U5-P36-E1",
    audio: "Audios/Page36/51.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen, point, and repeat. <headphone name='51' src='Audios/Page36/51 tieude.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    styleContent: {
      width: "90%",
      marginLeft: 50,
    },
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page36/E1/1.jpg",
          audioUrl: "Audios/Page36/australia.mp3",
        },
        {
          url: "img/FriendsPlus/Page36/E1/2.jpg",
        },
        {
          url: "img/FriendsPlus/Page36/E1/3.jpg",
          audioUrl: "Audios/Page36/viet nam.mp3",
        },
        {
          url: "img/FriendsPlus/Page36/E1/4.jpg",
        },
        {
          url: "img/FriendsPlus/Page36/E1/5.jpg",
          audioUrl: "Audios/Page36/the usa.mp3",
        },
        {
          url: "img/FriendsPlus/Page36/E1/6.jpg",
        },
        {
          url: "img/FriendsPlus/Page36/E1/7.jpg",
          audioUrl: "Audios/Page36/thailand.mp3",
        },
        {
          url: "img/FriendsPlus/Page36/E1/8.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page36/E1/9.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page36/E1/10.jpg",
        },
        {
          url: "img/FriendsPlus/Page36/E1/11.jpg",
          audioUrl: "Audios/Page36/cambodia.mp3",
        },
        {
          url: "img/FriendsPlus/Page36/E1/12.jpg",
        },
        {
          url: "img/FriendsPlus/Page36/E1/13.jpg",
          audioUrl: "Audios/Page36/singapore.mp3",
        },
        {
          url: "img/FriendsPlus/Page36/E1/14.jpg",
        },
        {
          url: "img/FriendsPlus/Page36/E1/15.jpg",
          audioUrl: "Audios/Page36/france.mp3",
        },
        {
          url: "img/FriendsPlus/Page36/E1/16.jpg",
        },
        {
          url: "img/FriendsPlus/Page36/E1/17.jpg",
          audioUrl: "Audios/Page36/germany.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 5",
    id: "SB5-2024-U5-P36-E2",
    audio: "Audios/Page36/52.mp3",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and chant. <headphone name='52' src='Audios/Page36/52 tieude.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    styleContent: {
      width: "90%",
      marginLeft: 50,
    },
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page36/E1/1.jpg",
          audioUrl: "Audios/Page36/australia.mp3",
        },
        {
          url: "img/FriendsPlus/Page36/E1/2.jpg",
        },
        {
          url: "img/FriendsPlus/Page36/E1/3.jpg",
          audioUrl: "Audios/Page36/viet nam.mp3",
        },
        {
          url: "img/FriendsPlus/Page36/E1/4.jpg",
        },
        {
          url: "img/FriendsPlus/Page36/E1/5.jpg",
          audioUrl: "Audios/Page36/the usa.mp3",
        },
        {
          url: "img/FriendsPlus/Page36/E1/6.jpg",
        },
        {
          url: "img/FriendsPlus/Page36/E1/7.jpg",
          audioUrl: "Audios/Page36/thailand.mp3",
        },
        {
          url: "img/FriendsPlus/Page36/E1/8.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page36/E1/9.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page36/E1/10.jpg",
        },
        {
          url: "img/FriendsPlus/Page36/E1/11.jpg",
          audioUrl: "Audios/Page36/cambodia.mp3",
        },
        {
          url: "img/FriendsPlus/Page36/E1/12.jpg",
        },
        {
          url: "img/FriendsPlus/Page36/E1/13.jpg",
          audioUrl: "Audios/Page36/singapore.mp3",
        },
        {
          url: "img/FriendsPlus/Page36/E1/14.jpg",
        },
        {
          url: "img/FriendsPlus/Page36/E1/15.jpg",
          audioUrl: "Audios/Page36/france.mp3",
        },
        {
          url: "img/FriendsPlus/Page36/E1/16.jpg",
        },
        {
          url: "img/FriendsPlus/Page36/E1/17.jpg",
          audioUrl: "Audios/Page36/germany.mp3",
        },
      ],
    ],
  },
  3: {
    unit: "Unit 5",
    id: "SB5-2024-U5-P36-E3",
    audio: "Audios/Page36/53.mp3",
    video: "Videos/Unit 5.mp4",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "3",
        title:
          "Listen and read. Did Leo and Amy have a great vaction?<headphone name='53' src='Audios/Page36/53 tieude.mp3'></headphone>",
        color: "#203c8f",
        left: 100,
      },
    ],
    hideBtnFooter: true,
    styleContent: {
      width: "70%",
      marginLeft: 150,
    },
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page36/E3/1.jpg",
          audioUrl: "Audios/Page36/53 01.mp3",
        },
        {
          url: "img/FriendsPlus/Page36/E3/2.jpg",
          audioUrl: "Audios/Page36/53 02.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page36/E3/3.jpg",
          audioUrl: "Audios/Page36/53 03.mp3",
        },
        {
          url: "img/FriendsPlus/Page36/E3/4.jpg",
          audioUrl: "Audios/Page36/53 04.mp3",
        },
      ],
    ],
  },
};

export default json;
