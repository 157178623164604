import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    unit: "Unit 12",
    id: "SB5-2024-U12-P93-E1",
    audio: "Audios/Page93/142.mp3",
    exerciseKey: "img/FriendsPlus/Page93/Key/E1answerKey.png",
    video: "",
    component: T6,
    maxLength: 1,
    textAlign: "center",
    // inputSize: 200,
    stylesTextInput: {
      background: "none",
      borderBottom: "1.5px solid gray",
      // fontSize: 22,
      paddingBottom: 3,
      height: 40,
      width: 38,
      textTransform: "uppercase",
    },
    // hintBox: [
    //   {
    //     src: ["English", "math", "P.E", "art", "Vietnamese"],
    //     width: 582,
    //   },
    // ],
    titleQuestion: [
      {
        num: "1",
        title:
          " Listen and write <i>T</i> (Thanh), <i>H</i> (Huy), <i>A</i> (Anh), or <i>K</i> (Khoa). <headphone name='142' src='Audios/Page93/142 tieu-de.mp3'></headphone>",
        color: "#203c8f",
        numberImg: ["b"],
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='position: relative'>
          <img style='height:6cm; margin-top:10px; margin-left: -80px' src='img/FriendsPlus/Page93/E1/1.jpg'/>
          <div style='position: absolute; top: 171px; left: 76px'>#</div>
          <div style='position: absolute; top: 171px; left: 358px'>#</div>
          <div style='position: absolute; top: 171px; left: 920px'>#</div>
        </div>
        
        
        `,
        // initialValue: ["math", "", "", "", "", "", "", ""],
        answer: ["A", "H", "T"],
      },
    ],
  },
  2: {
    unit: "Unit 12",
    id: "SB5-2024-U12-P93-E2",
    audio: "Audios/Page93/142.mp3",
    exerciseKey: "img/FriendsPlus/Page93/Key/E2answerKey.png",
    video: "",
    component: T6,
    // maxLength: 6,
    textAlign: "center",
    inputSize: 140,
    // hintBox: [
    //   {
    //     src: ["English", "math", "P.E", "art", "Vietnamese"],
    //     width: 582,
    //   },
    // ],
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen again and write <i>T (true)</i> or <i>F (false)</i>. <headphone name='142' src='Audios/Page93/142.mp3'></headphone>",
        color: "#203c8f",
        numberImg: ["b"],
      },
    ],
    // hideBtnFooter: true,
    selectStyle: {
      height: 35,
      width: 35,
      textAlign: "center",
    },
    selectOptionRandom: true,
    selectOptionValues: ["T", "F"],
    questions: [
      {
        title: `
        <div style='line-height: 50px'>
        <div style='display: flex; margin-bottom: 12px'><b>1.</b>&ensp;Khoa sometimes watches cartoons.&ensp; <u style='color: rgb(54,166,215)'>&ensp;F&ensp;</u></div>
          <div style='display: flex; align-items: baseline;'><b>2.</b>&ensp;He is going to go to Africa.&ensp; <select id=0></select></div>
          <div style='display: flex; align-items: baseline;'><b>3.</b>&ensp;Thanh's favorite show was on TV yesterday.&ensp; <select id=1></select></div>
          <div style='display: flex; align-items: baseline;'><b>4.</b>&ensp;He saw animals in Australia.&ensp; <select id=2></select></div>

          <div style='display: flex; align-items: baseline;'><b>5.</b>&ensp;Huy only likes watching sports.&ensp; <select id=3></select></div>
          <div style='display: flex; align-items: baseline;'><b>6.</b>&ensp;The Vietnamese team won.&ensp; <select id=4></select></div>
          <div style='display: flex; align-items: baseline;'><b>7.</b>&ensp;Anh saw a movie with her parents.&ensp; <select id=5></select></div>
          <div style='display: flex; align-items: baseline;'><b>8.</b>&ensp;She saw the movie on Sunday.&ensp; <select id=6></select></div>
        </div>
          
        
        `,
        // initialValue: ["math", "", "", "", "", "", "", ""],
        answer: ["F", "F", "T", "F", "T", "T", "F"],
      },
    ],
  },
  3: {
    unit: "Unit 12",
    id: "SB5-2024-U12-P93-E3",
    // audio: "Audios/Page64/audio-e2.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    styleContent: {
      width: "90%",
      marginLeft: 100,
    },
    titleQuestion: [
      {
        num: "3",
        title: "Ask and answer.",
        color: "#203c8f",
        numberImg: ["c"],
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page93/E3/1.jpg",
          // audioUrl: "Audios/Page64/candy.mp3",
        },
      ],
    ],
  },
  4: {
    unit: "Unit 12",
    id: "SB5-2024-U12-P93-E4",
    // audio: "Audios/Page93/audio-e1.mp3",
    exerciseKey: "img/FriendsPlus/Page93/Key/E4answerKey.png",
    video: "",
    component: T6,
    // maxLength: 6,
    textAlign: "left",
    // inputSize: 200,
    textareaStyle: {
      // background: 'none',
      width: 800,
      fontSize: 22,
      paddingTop: 5,
      marginLeft: 30,
      // height: 70,
    },
    titleQuestion: [
      {
        num: "4",
        title: "Write about a TV show. Use the example to help you.",
        color: "#203c8f",
        numberImg: ["a"],
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div >
          <div style='margin-left: -0px'><img style='height:8cm;margin-top:10px' src='img/FriendsPlus/Page93/E4/1.jpg'/><br></div>
          <textarea id='0' rows='8'>
        </div>
        
        
        `,
        // initialValue: ["math", "", "", "", "", "", "", ""],
        answer: [""],
      },
    ],
  },
};

export default json;
