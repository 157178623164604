import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Starter",
    id: "SB5-2024-Starter-P4-E1",
    audio: "Audios/Page4/1.mp3",
    video: "Videos/We're all back together.mp4",
    component: T6,
    recorder: true,
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen and sing. <headphone name='01' src='Audios/Page4/1 tieude.mp3'></headphone>",
        color: "#203c8f",
        left: 70,
      },
    ],
    styleContent: {
      width: "90%",
      marginLeft: 70,
    },
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='background:rgb(200,235,255); border-radius:20px; padding:20px'>
            <div style='text-align:center; font-weight:bold; font-size:30px; color: rgb(36,171,155)'>
              We're all back together!
            </div>
            <div style='display:flex'>
                <div>
                  We're all back together with friends from before.<br>
                  We're ready to work and learn some more.
                </div>
                <div style='margin-left:50px'>
                  It's time to show what we can do.<br>
                  Vacation is over for me and you.
                </div>
            </div>
        
        </div>
          
        
        `,
        initialValue: ["math", "", "", "", "", "", "", ""],
        answer: [
          "math",
          "doing",
          "P.E",
          "playing",
          "English",
          "painting",
          "P.E",
          "running",
        ],
      },
    ],
  },
  2: {
    unit: "Starter",
    id: "SB5-2024-Starter-P4-E2",
    audio: "Audios/Page4/2.mp3",
    video: "Videos/Unit Starter.mp4",
    component: UI,
    recorder: true,
    styleContent: {
      width: "75%",
      marginLeft: 90,
    },
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and read. Where was Amy's bag? <headphone name='02' src='Audios/Page4/2 tieude.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page4/E2/1.jpg",
          audioUrl: "Audios/Page4/2 01.mp3",
        },
        {
          url: "img/FriendsPlus/Page4/E2/2.jpg",
          audioUrl: "Audios/Page4/2 02.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page4/E2/3.jpg",
          audioUrl: "Audios/Page4/2 03.mp3",
        },
        {
          url: "img/FriendsPlus/Page4/E2/4.jpg",
          audioUrl: "Audios/Page4/2 04.mp3",
        },
      ],
    ],
  },
  3: {
    unit: "Starter",
    id: "SB5-2024-Starter-P4-E3",
    audio: "Audios/Page4/2.mp3",
    video: "Videos/Unit  7.mp4",
    component: UI,
    recorder: true,
    styleContent: {
      width: "80%",
      marginLeft: 70,
    },
    titleQuestion: [
      {
        num: "3",
        title:
          "Listen and read. Where did the children go? <headphone name='02' src='Audios/Page4/2 tieude.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page4/E3/1.jpg",
          audioUrl: "Audios/Page4/2 01.mp3",
        },
        {
          url: "img/FriendsPlus/Page4/E3/2.jpg",
          audioUrl: "Audios/Page4/2 02.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page4/E3/3.jpg",
          audioUrl: "Audios/Page4/2 03.mp3",
        },
        {
          url: "img/FriendsPlus/Page4/E3/4.jpg",
          audioUrl: "Audios/Page4/2 04.mp3",
        },
      ],
    ],
  },
};

export default json;
