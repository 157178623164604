import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Fluency Time! 2",
    id: "SB5-2024-FL2-P53-E1",
    audio: "Audios/Page53/75.mp3",
    video: "",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen, point, and repeat. <headphone name='75'  src='Audios/Page53/75 tieude.mp3'></headphone>",
        color: "#203c8f",
        // numberImg: "c",
        left: 100,
      },
    ],
    styleContent: {
      width: "80%",
      marginLeft: 100,
    },
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page53/E1/1.jpg",
          audioUrl: "Audios/Page53/camel.mp3",
        },
        {
          url: "img/FriendsPlus/Page53/E1/2.jpg",
          audioUrl: "Audios/Page53/sand dune.mp3",
        },
        {
          url: "img/FriendsPlus/Page53/E1/3.jpg",
          audioUrl: "Audios/Page53/stem.mp3",
        },
        {
          url: "img/FriendsPlus/Page53/E1/4.jpg",
          audioUrl: "Audios/Page53/plant.mp3",
        },
        {
          url: "img/FriendsPlus/Page53/E1/5.jpg",
          audioUrl: "Audios/Page53/rock.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Fluency Time! 2",
    id: "SB5-2024-FL2-P53-E2",
    exerciseKey: "img/FriendsPlus/Page53/Key/E2answerKey.png",
    audio: "Audios/Page53/76.mp3",
    video: "",
    recorder: true,
    component: T6,
    inputSize: 180,
    hideBtnFooter: true,
    textAlign: "center",
    textareaStyle: { width: 800, paddingTop: 5 },
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and read. <headphone name='76' src='Audios/Page53/76 tieude.mp3'></headphone>",
        color: "#203c8f",
        // numberImg: ["a"],
        left: 100,
      },
    ],

    questions: [
      {
        title: `
        <img style='height:14cm' src='img/FriendsPlus/Page53/E2/1.jpg'/>
      `,
        answer: [
          "twenty-one",
          "sixty-three",
          "eighty-nine",
          "thirty-four",
          "forty-five",
          "ninety-six",
          "seventy-seven",
          "a hundred",
        ],
      },
    ],
  },
  3: {
    unit: "Fluency Time! 2",
    id: "SB5-2024-FL2-P53-E3",
    // audio: "Audios/Page56/81.mp3",
    exerciseKey: "img/FriendsPlus/Page53/Key/E3answerKey.png",
    audio: "",
    video: "",
    component: T6,
    // inputSize: 180,
    // checkDuplicated: true,
    // hideBtnFooter: true,
    maxLength: 1,
    textAlign: "center",
    stylesTextInput: { width: 40 },
    titleQuestion: [
      {
        num: "3",
        title: "Write <i>T</i>(true) or <i>F</i>(false).",
        color: "#203c8f",
        numberImg: ["b"],
        left: 50,
      },
    ],

    questions: [
      {
        title: `
        <div>
          <b>1</b>&ensp;It is always hot in the desert.#<br>
          <b>2</b>&ensp;There are sand dunes in snowy deserts.#<br>
          <b>3</b>&ensp;There in water in a cactus stem.#<br>
          <b>4</b>&ensp;Camels don't drink water for a month.#<br>
        </div>
      `,
        // InputRong: true,
        answer: ["F", "F", "T", "F"],
      },
    ],
  },
  4: {
    unit: "Fluency Time! 2",
    id: "SB5-2024-FL2-P53-E4",
    audio: "",
    video: "",
    recorder: true,
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
    },
    hintBox: [
      {
        src: [
          "In the evening, always eat dinner dinner with my family. My mom get home at ...",
        ],
        styleElement: { color: "#10adcf", marginRight: 10 },
        marginLeft: 20,
      },
    ],
    textAlign: "center",
    textareaStyle: {
      width: 800,
    },
    hideBtnFooter: true,
    component: T6,
    titleQuestion: [
      {
        num: "4",
        title:
          "<headphone name='' margin-left='-20px' typeimg='think'></headphone> What do you know about desert animals? Make a poster.",
        color: "#203c8f",
        left: 50,
        // numberImg: ["a"],
      },
    ],
    questions: [
      {
        title: `
      `,
        answer: [
          "on Saturdays",
          "chicken rice",
          "two cucumbers",
          "helps Mom",
          "always delicious",
        ],
      },
    ],
  },
  5: {
    unit: "Review 1",
    id: "SB5-2024-RV1-P29-E5",
    audio: "",
    video: "",
    component: Circle_Write,
    inputSize: 500,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "5",
        title: "Look and color.",
        color: "#203c8f",
        left: -50,
        // numberImg: ["a"],
      },
    ],
    question: {
      ThSmile: true,
      // leftContent: 100,
      DrawLines: {
        multipleLine: true,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "5px 18px",
          border: "2px solid",
          color: "rgb(254,247,157)",
          marginLeft: 10,
          // background: "gray",
          borderRadius: "50%",
          borderColor: "gray",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          width: 10,
          borderRadius: "50%",
          background: "gray",
          color: "gray",
          border: "2px solid",
          borderColor: "gray",
        },
        limitSelect: 3,
        listWords: ["a", "a a", "a a a"],
        answers: ["0-0"],
        initialValue: [],
      },
      Layout: `

        <div style='background:rgb(254,247,157);border:3px solid rgb(251,95,50);padding:10px 20px;box-shadow:4px 2px 3px gray;border-radius:14px'>
          <sp style='color:rgb(251,95,50); font-weight:bold;font-size:36px;margin-left:10px'>
            My work in Starter Unit, Units 1-3 & Fluency Time! 2 is
          </sp53>
            <div style='display:flex;margin-top:30px'>
              <div>
                <input id=0 select='Tick' type='Circle'/> 
                <span style='color:rgb(251,95,50); font-weight:bold;font-size:36px;margin-left:10px'>OK</span>
              </div>
              <div style='margin:0px 100px'>
                <input id=1 select='Tick' type='Circle'/> 
                <span style='color:rgb(251,95,50); font-weight:bold;font-size:36px;margin-left:10px'>GOOD</span>
              </div>
              <div>
                <input id=2 select='Tick' type='Circle'/> 
                <span style='color:rgb(251,95,50); font-weight:bold;font-size:36px;margin-left:10px'>EXCELLENT</span>
              </div>
            </div>
        </div>

      
    
     
      `,
    },
  },
};

export default json;
