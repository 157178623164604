import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 4",
    id: "SB5-2024-U4-P34-E1",
    audio: "Audios/Page34/48.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen, point, and repeat. <headphone name='48' src='Audios/Page34/48 tieude.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    styleContent: {
      width: "90%",
      marginLeft: 50,
    },
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page34/E1/1.jpg",
          audioUrl: "Audios/Page34/on foot.mp3",
        },
        {
          url: "img/FriendsPlus/Page34/E1/2.jpg",
        },
        {
          url: "img/FriendsPlus/Page34/E1/3.jpg",
          audioUrl: "Audios/Page34/by bike.mp3",
        },
        {
          url: "img/FriendsPlus/Page34/E1/4.jpg",
        },
        {
          url: "img/FriendsPlus/Page34/E1/5.jpg",
          audioUrl: "Audios/Page34/in the middle.mp3",
        },
        {
          url: "img/FriendsPlus/Page34/E1/6.jpg",
        },
        {
          url: "img/FriendsPlus/Page34/E1/7.jpg",
          audioUrl: "Audios/Page34/inside.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 4",
    id: "SB5-2024-U4-P34-E2",
    // audio: "Audios/Page31/44.mp3",
    video: "",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["gets", "Vinh", "at", "seven", "o'clock", "dressed", "."],
      },
      {
        src: ["Vinh", "gets", "dressed", "at", "seven", "o'clock."],
        styleElement: { color: "#10adcf", marginRight: 10 },
        marginLeft: 20,
      },
      {
        src: ["Linh", "snack", "Does", "school", "after", "a", "?"],
      },
      {
        src: ["Tuan", "don't", "Mai", "catch", "and", "bus", "the", "."],
      },
      {
        src: ["brush", "at", "teeth", "Do", "their", "night", "they", "?"],
      },
      {
        src: ["Walk", "school", "doesn't", "He", "to", "."],
      },
    ],
    stylesTextInput: { width: 550 },
    hideBtnFooter: true,
    component: T6,
    titleQuestion: [
      {
        num: "2",
        title: "Look at the text. What can you see in the pictures?",
        color: "#203c8f",
        numberImg: ["a"],
        left: 50,
      },
    ],
    questions: [
      {
        title: `
        <img style='height:14cm' src='img/FriendsPlus/Page34/E3/1.jpg'/><br>
                  <div>
                  What can you see in the pictures?<br>#
                  </div>
                  
        
      `,
        answer: ["s", "es", "s", "s", "s", "es"],
      },
    ],
  },
  3: {
    unit: "Unit 4",
    id: "SB5-2024-U4-P34-E1",
    audio: "Audios/Page34/49.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "3",
        title:
          "Listen and Read <headphone name='49' src='Audios/Page34/49 tieude.mp3'></headphone>",
        color: "#203c8f",
        left: 100,
      },
    ],
    styleContent: {
      width: "80%",
      marginLeft: 150,
    },
    questionImage: [[{ url: "img/FriendsPlus/Page34/E3/1.jpg" }]],
  },
  4: {
    unit: "Unit 4",
    id: "SB5-2024-U4-P34-E4",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page34/Key/E4answerKey.png",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["gets", "Vinh", "at", "seven", "o'clock", "dressed", "."],
      },
      {
        src: ["Vinh", "gets", "dressed", "at", "seven", "o'clock."],
        styleElement: { color: "#10adcf", marginRight: 10 },
        marginLeft: 20,
      },
      {
        src: ["Linh", "snack", "Does", "school", "after", "a", "?"],
      },
      {
        src: ["Tuan", "don't", "Mai", "catch", "and", "bus", "the", "."],
      },
      {
        src: ["brush", "at", "teeth", "Do", "their", "night", "they", "?"],
      },
      {
        src: ["Walk", "school", "doesn't", "He", "to", "."],
      },
    ],
    stylesTextInput: { width: 850 },
    // hideBtnFooter: true,
    component: T6,
    titleQuestion: [
      {
        num: "4",
        title: "Read again and answer the questions.",
        color: "#203c8f",
        numberImg: ["a"],
        left: 50,
      },
    ],
    character: /[/|]/,
    questions: [
      {
        title: `
        <img style='height:15cm' src='img/FriendsPlus/Page34/E3/1.jpg'/><br>
              <div>
                <b>1</b>&ensp;Where is Ben Thanh market?<br>#<br>
                <b>2</b>&ensp;How old is the market?<br>#<br>
                <b>3</b>&ensp;Were there many cars in 1912?<br>#<br>
                <b>4</b>&ensp;How do people usually get to the market today?<br>#<br>
                <b>5</b>&ensp;What can you buy in the market?<br>#<br>
              </div>
        
      `,
        // //notSymbol: true,
        answer: [
          "It's in the middle of Ho Chi Minh City.|It is in the middle of Ho Chi Minh City.",
          "It's over one hundred years old.|It is over one hundred years old.",
          "No. There weren't any cars.|No, there were not.|No, there weren't./ No, there were not any cars.",
          "They usually get there by car or by motorcycle.",
          "You can buy food, gifts, and clothes.|I can buy food, gifts, and clothes./ We can buy food, gifts, and clothes.",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 4",
    id: "SB5-2024-U4-P34-E5",
    audio: "",
    recorder: true,
    video: "",
    exerciseKey: "img/FriendsPlus/Page34/Key/E5answerKey.png",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["gets", "Vinh", "at", "seven", "o'clock", "dressed", "."],
      },
      {
        src: ["Vinh", "gets", "dressed", "at", "seven", "o'clock."],
        styleElement: { color: "#10adcf", marginRight: 10 },
        marginLeft: 20,
      },
      {
        src: ["Linh", "snack", "Does", "school", "after", "a", "?"],
      },
      {
        src: ["Tuan", "don't", "Mai", "catch", "and", "bus", "the", "."],
      },
      {
        src: ["brush", "at", "teeth", "Do", "their", "night", "they", "?"],
      },
      {
        src: ["Walk", "school", "doesn't", "He", "to", "."],
      },
    ],
    textareaStyle: { paddingTop: 3, width: 650 },
    hideBtnFooter: true,
    component: T6,
    titleQuestion: [
      {
        num: "5",
        title: "How do people travel in your town or city?",
        color: "#203c8f",
        // numberImg: ["b"],
        left: 50,
      },
    ],
    questions: [
      {
        title: `
        
      `,
        answer: [""],
      },
    ],
  },
};

export default json;
