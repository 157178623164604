import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 10",
    id: "SB5-2024-U10-P79-E1",
    audio: "Audios/Page79/118.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    styleContent: {
      width: "80%",
      marginLeft: 100,
      marginBottom: 50,
    },
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen, point and repeat. <headphone name='118' src='Audios/Page79/118 tieu-de.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page79/E1/1.jpg",
          audioUrl: "Audios/Page79/you should stay in bed.mp3",
        },
        {
          url: "img/FriendsPlus/Page79/E1/2.jpg",
        },
        {
          url: "img/FriendsPlus/Page79/E1/3.jpg",
          audioUrl: "Audios/Page79/you shouldn't go to school.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page79/E1/4.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page79/E1/5.jpg",
          audioUrl: "Audios/Page79/you must stay at home.mp3",
        },
        {
          url: "img/FriendsPlus/Page79/E1/6.jpg",
        },
        {
          url: "img/FriendsPlus/Page79/E1/7.jpg",
          audioUrl: "Audios/Page79/you mustn't go outside.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 10",
    id: "SB5-2024-U10-P79-E2",
    audio: "Audios/Page79/119.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    styleContent: {
      width: "90%",
      marginLeft: 50,
      marginBottom: 50,
    },
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and read. <headphone name='119' src='Audios/Page79/119 tieu-de.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page79/E2/1.jpg",
        },
      ],
    ],
  },
  3: {
    unit: "Unit 10",
    id: "SB5-2024-U10-P79-E3",
    // audio: "Audios/3-07.mp3",
    exerciseKey: "img/FriendsPlus/Page79/Key/E3answerKey.png",
    component: Circle_Write,
    recorder: true,
    inputSize: 500,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    titleQuestion: [
      {
        num: "3",
        title: "Read again. Underline the words that sound stronger. Then say.",
        color: "#203c8f",
        width: "26cm",
        numberImg: ["b", "c"],
      },
    ],
    // hideBtnFooter: true,
    question: {
      // leftContent: 100,
      DrawLines: {
        multipleLine: true,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "1px 4px",
          // border: "2px solid",
          border: "none",
          color: "#384989",
          // borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          // padding: "1px 4px",
          // borderRadius: "50%",
          color: "#384989",
          border: "none",
          borderBottom: "2px solid #00aeef",
          // borderColor: "#00aeef",
        },
        limitSelect: 6,
        listWords: [
          "He shouldn't visit friends",
          "He should drink lots of water",
          "He must wear a mask",
          "He mustn't play soccer",
        ],
        answers: ["0-2", "1-2", "1-6", "1-10", "2-2", "2-8", "3-2"],
        initialValue: ["0-2"],
      },
      Layout: `
      <div style='position: relative'>
      <img style='height:13cm; margin-top:20px; margin-left: 10px' src='img/FriendsPlus/Page79/E3/1.jpg'/>
      
      <div style='position: absolute; top: 327px; font-size: 23px; left: 175px; line-height: 60px; display: flex'>
        <div style='margin-right: 50px'>
          <div><input id=0 type='Circle' />.</div>
          <div><input id=1 type='Circle' />.</div>
        </div>
        <div>
          <div><input id=2 type='Circle' />.</div>
          <div><input id=3 type='Circle' />.</div>
        </div>
      </div>
      
      </div>
      
      `,
    },
  },
  4: {
    unit: "Unit 10",
    id: "SB5-2024-U10-P79-E4",
    audio: "Audios/Page79/audio-e4.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page79/Key/E4answerKey.png",
    component: T6,
    // maxLength: 2,
    recorder: true,
    textAlign: "left",
    stylesTextInput: {
      background: "none",
      borderBottom: "1.5px solid gray",
      // fontSize: 22,
      height: 30,
      width: 800,
      // textTransform: 'uppercase',
      // borderStyle: "dotted",
      // textAlign: "center",
    },
    titleQuestion: [
      {
        num: "4",
        title: "Put in the correct order, and say.",
        color: "#203c8f",
        numberImg: ["a", "c"],
      },
    ],
    questionImage: [""],
    // isHello: true,
    questions: [
      {
        title: `
        <div style='line-height: 45px'>
          <div>
            <b>1.</b>&ensp;lie / should / down / You / .<br>
            <u style='color: rgb(54,166,215)' >&ensp;You should lie down.</u>       
          </div>
          <div>
            <b>2.</b>&ensp;mustn't / eat / ice / You / cream / .<br>#      
          </div>
          <div>
            <b>3.</b>&ensp;go / should / You / home / .<br>#       
          </div>
          <div>
            <b>4.</b>&ensp;coffee / drink / shouldn't / You / .<br>#       
          </div>
          <div>
            <b>5.</b>&ensp;You / stay / bed / in / must / .<br>#       
          </div>
            
        </div>
        
        `,
        answer: [
          "You mustn't eat ice cream.",
          "You should go home.",
          `You shouldn't drink coffee.`,
          "You must stay in bed.",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 10",
    id: "SB5-2024-U10-P79-E5",
    audio: "",
    video: "",
    recorder: true,
    component: UI,
    styleContent: {
      width: "43%",
      marginLeft: 300,
    },
    // titleQuestion: [
    //   {
    //     num: "5",
    //     title:
    //       "Look again and write.  <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
    //     color: "#203c8f",
    //   },
    // ],
    questionImage: [[{ url: "img/FriendsPlus/Page79/E5/1.jpg" }]],
  },
};

export default json;
