import DesignTypeIn from "../../components/ExcerciseTypes/Design/TypeInNoSubmit";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Match_Write from "../../components/ExcerciseTypes/Match_Write";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";

const json = {
  3: {
    unit: "Review 3",
    id: "SB5-2024-R3-P73-E3",
    audio: "Audios/Page73/108.mp3",
    exerciseKey: "img/FriendsPlus/Page73/Key/E3answerKey.png",
    component: Circle_Write,
    inputSize: 500,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    titleQuestion: [
      {
        num: "3",
        title:
          "Listen and circle the stressed parts of sentences. <headphone name='108' src='Audios/Page73/108 tieu-de.mp3'></headphone>",
        color: "#203c8f",
        width: "25cm",
        numberImg: ["b"],
      },
    ],
    // hideBtnFooter: true,
    question: {
      // leftContent: 100,
      DrawLines: {
        multipleLine: true,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "2px 8px",
          // border: "2px solid gray",
          // fontWeight: "bold",
        },
        selectWordStyle: {
          // fontWeight: "bold",
          // padding: "2px 8px",
          // color: "#00aeef",
          border: "2px solid gray",
          borderRadius: "50%",
          // borderBottom: "2px solid #00aeef",
          // borderColor: "#00aeef",
        },

        limitSelect: 1,
        listWords: [
          "The elephant walks slow|ly",
          "A mouse is small|er",
          "He reads quiet|ly",
          "A giraffe can run very fast",
          "A cheetah is fast|er",
        ],
        answers: ["0-6", "1-6", "2-4", "3-10", "4-6"],
        initialValue: ["0-6"],
      },
      Layout: `
     
        <div style='line-height: 45px; margin-left: 100px; margin-top: 30px'>
          <div><b>1.&ensp;</b> <input id=0 type='Circle' />.</div>
          <div><b>2.&ensp;</b> <input id=1 type='Circle' /> than an elephant.</div>
          <div><b>3.&ensp;</b> <input id=2 type='Circle' />.</div>
          <div><b>4.&ensp;</b> <input id=3 type='Circle' />.</div>
          <div><b>5.&ensp;</b> <input id=4 type='Circle' /> than a horse.</div>
          
        </div>
 
      `,
    },
  },
  4: {
    unit: "Review 3",
    id: "SB5-2024-R3-P73-E4",
    // audio: "Audios/Page64/audio-e1.mp3",
    exerciseKey: "img/FriendsPlus/Page73/Key/E4answerKey.png",
    video: "",
    recorder: true,
    component: T6,
    // maxLength: 6,
    textAlign: "left",
    inputSize: 800,
    stylesTextInput: {
      background: "none",
      borderBottom: "1.5px solid",
      fontSize: 22,
      paddingBottom: 3,
      height: 40,
      // textTransform: "lowercase",
    },
    titleQuestion: [
      {
        num: "4",
        title: "Look at the picture of Ba Na Hills and answer the questions.",
        color: "#203c8f",
        numberImg: ["c"],
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img style='height:10cm;margin-top:10px' src='img/FriendsPlus/Page73/E4/1.jpg'/>
        <div style='display; flex'>
        <div style='line-height: 40px; font-size: 22px'>
          <div style='display: flex'><b>1.</b>&ensp;What can you see?</div> 
          <div style='display: flex'><b>2.</b>&ensp;Is Ba Na Hills higher than your town or city?</div>
          <div style='display: flex'><b>3.</b>&ensp;Is it colder or warmer than your town or city?</div>
          <div style='display: flex'><b>4.</b>&ensp;Did you see a mountain before? Did you climb it?</div>
        </div>
      
        </div>
        
        `,
        // initialValue: ["math", "", "", "", "", "", "", ""],
        answer: [
          "Yes, it is.",
          "It's in Quang Binh province.",
          "It's 586 kilometers long.",
          "No, it isn't.",
        ],
      },
    ],
  },
  5: {
    unit: "Review 3",
    id: "SB5-2024-R3-P73-E4",
    exerciseKey: "img/FriendsPlus/Page73/Key/E5answerKey.png",
    stylesTextInput: { width: 850, marginTop: 20, fontSize: 23 },
    // hideBtnFooter: true,
    component: T6,
    titleQuestion: [
      {
        num: "5",
        title: "Write the words in the correct order.",
        color: "#203c8f",
        numberImg: ["a"],
        // left: 50,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 10,
        padding: "2px 8px",
        borderRadius: "10px",
        fontSize: 23,
      },
    }, //StyleHint là css hàng loạt ,
    //Nếu StyleElement có trong phần tử thì styleHint sẽ không được tính cho phần tử đó
    hintBox: [
      {
        src: [
          "I",
          "Sunday",
          "friends",
          "and",
          ".",
          "my",
          "Last",
          "with",
          "the",
          ",",
          "went",
          "park",
          "to",
          "played",
        ],
      },
      {
        src: [
          "Last",
          "Sunday",
          ",",
          "I",
          "went",
          "to",
          "the",
          "park",
          "and",
          "played",
          "with",
          "my",
          "friends",
          ".",
        ],
        styleElement: {
          color: "#2eb6e1",
          marginRight: 10,
          fontSize: 23,
        },
        //CSS phần tử
        marginLeft: 20,
      },
      {
        src: ["train", ".", "A", "faster", "is", "trolley", "than", "a"],
      },
      {
        src: ["mountain", "Bach Ma", "than", "is", ".", "higher", "Fansipan"],
      },
      {
        src: ["must", "We", "exams", ".", "hard", "for", "study", "our"],
      },
      {
        src: [
          "talk",
          "library",
          "in",
          "loudly",
          "the",
          "Don't",
          ".",
          "please",
          ",",
        ],
      },
    ],
    questions: [
      {
        title: `
        <div style='margin-left: -30px'>
        
            <div style='display:flex;margin-top:20px'>
              <b>1</b>. <hintbox id=0></hintbox>
            </div>
              <hintbox id=1></hintbox>
            <div style='display:flex;margin-top:20px'>
              <b>2</b>. <hintbox id=2></hintbox>
            </div>#
            <div style='display:flex;margin-top:20px'>
              <b>3</b>. <hintbox id=3></hintbox>
            </div>#
            <div style='display:flex;margin-top:20px'>
              <b>4</b>. <hintbox id=4></hintbox>
            </div>#
            <div style='display:flex;margin-top:20px'>
              <b>5</b>. <hintbox id=5></hintbox>
            </div>#
        </div>
    `,
        answer: [
          "A train is faster than a trolley.",
          "Fansipan is higher than Bach Ma mountain.",
          "We must study hard for our exams.",
          "Don't talk loudly in the library, please.",
        ],
      },
    ],
  },
};
export default json;
