import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 12",
    id: "SB5-2024-U12-P89-E1",
    audio: "Audios/Page89/135.mp3",
    video: "Videos/Unit 12.mp4",
    component: UI,
    recorder: true,
    styleContent: {
      width: "80%",
      marginLeft: 50,
    },
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen to the story and repeat. <headphone name='135' src='Audios/Page89/135 tieu-de.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page88/E3/1.jpg",
          audioUrl: "Audios/Page88/hinh1.mp3",
        },
        {
          url: "img/FriendsPlus/Page88/E3/2.jpg",
          audioUrl: "Audios/Page88/hinh2.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page88/E3/3.jpg",
          audioUrl: "Audios/Page88/hinh3.mp3",
        },
        {
          url: "img/FriendsPlus/Page88/E3/4.jpg",
          audioUrl: "Audios/Page88/hinh4.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 12",
    id: "SB5-2024-U12-P89-E2",
    audio: "Audios/Page89/136.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    styleContent: {
      width: "90%",
      marginLeft: 30,
    },
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and say. <headphone name='136' src='Audios/Page89/136 tieu-de.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [{ url: "img/FriendsPlus/Page89/E2/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page89/E2/2.jpg" },
        {
          url: "img/FriendsPlus/Page89/E2/3.jpg",
          audioUrl: "Audios/Page89/136 01.mp3",
        },
        { url: "img/FriendsPlus/Page89/E2/4.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page89/E2/5.jpg" },
        {
          url: "img/FriendsPlus/Page89/E2/6.jpg",
          audioUrl: "Audios/Page89/136 02.mp3",
        },
        { url: "img/FriendsPlus/Page89/E2/7.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page89/E2/8.jpg" }],
    ],
  },
  3: {
    unit: "Unit 12",
    id: "SB5-2024-U12-P89-E3",
    // audio: "Audios/Page55/80.mp3",
    exerciseKey: "img/FriendsPlus/Page89/Key/E3answerKey.png",
    video: "",
    component: T6,
    maxLength: 1,
    textAlign: "center",
    inputSize: 80,
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      // fontSize: 22,
      height: 27,
      textTransform: "lowercase",
      // borderStyle: "dotted",
      textAlign: "center",
    },
    // hintBox: [
    //   {
    //     src: ["fast", "polite", "hard", "neat", "loud", "<s>good</s>"],
    //     width: 682,
    //   },
    // ],
    titleQuestion: [
      {
        num: "3",
        title: "Match and write the letter.",
        color: "#203c8f",
        numberImg: ["b"],
      },
    ],
    // hideBtnFooter: true,
    selectStyle: {
      height: 35,
      width: 35,
      textAlign: "center",
    },
    selectOptionRandom: true,
    selectOptionValues: ["T", "F"],
    questions: [
      {
        title: `
        <div style='display: flex; line-height: 50px; margin-top: 30px'>
          <div style='margin-right: 70px'>
            <div style=''><b>1.</b>&ensp;I'm going to watch <u style='color: rgb(54,166,215) '>&ensp;e&ensp;</u></div>
            <div style=''><b>2.</b>&ensp;She isn't going to play # </div>
            <div style=''><b>3.</b>&ensp;They aren't going to visit # </div>
            <div style=''><b>4.</b>&ensp;He's going to use # </div>
            <div style=''><b>5.</b>&ensp;We're going to go # </div>
          </div>
          <div>
            <div><b>a.&ensp;</b>the museum.</div>
            <div><b>b.&ensp;</b>on vacation.</div>
            <div><b>c.&ensp;</b>a video game.</div>
            <div><b>d.&ensp;</b>his smartphone.</div>
            <div><b>e.&ensp;</b>a DVD.</div>
          </div>
        </div>
          
        
        `,
        // initialValue: ["math", "", "", "", "", "", "", ""],
        answer: ["c", "a", "d", "b"],
      },
    ],
  },
  4: {
    unit: "Unit 12",
    id: "SB5-2024-U12-P89-E4",
    // audio: "Audios/Page55/80.mp3",
    exerciseKey: "img/FriendsPlus/Page89/Key/E4answerKey.png",
    // video: "",
    component: T6,
    // maxLength: 1,
    textAlign: "left",
    inputSize: 250,
    stylesTextInput: {
      background: "none",
      borderBottom: "1.5px solid",
      // fontSize: 22,
      paddingBottom: 3,
      height: 40,
      // textTransform: "lowercase",
    },
    // hintBox: [
    //   {
    //     src: ["English", "math", "P.E", "art", "Vietnamese"],
    //     width: 582,
    //   },
    // ],
    titleQuestion: [
      {
        num: "4",
        title: "Look and write.",
        color: "#203c8f",
        numberImg: ["a"],
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='line-height: 50px; '>
          <img style='height:5cm;margin-top:20px' src='img/FriendsPlus/Page89/E4/1.jpg'/>
          <div style='margin-left: 50px'>
          <div style='display: flex; margin-top: 30px'><b>1.</b>&ensp;Is she going to watch a movie on her smartphone? &ensp;<u style='color:rgb(54,166,215);'>&ensp;No, she isn't.&ensp;</u></div>
          <div style='display: flex'><b>2.</b>&ensp;Are they going to be movies stars? #</div>
          <div style='display: flex'><b>3.</b>&ensp;Is he going to take a photo? #</div>
          <div style='display: flex'><b>4.</b>&ensp;Is she going to use her tablet? #</div>
          </div>
          </div>

        `,
        // initialValue: ["math", "", "", "", "", "", "", ""],
        answer: [
          "Yes, they are.",
          "Yes, he is.",
          "No, she isn't.|No, she is not.",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 12",
    id: "SB5-2024-U12-P89-E5",
    // audio: "img/FriendsPlus/Page110/Audio/audio-e2-p40.mp3",
    video: "",
    component: UI,
    recorder: true,
    styleContent: {
      width: "35%",
      marginLeft: 350,
    },
    questionImage: [[{ url: "img/FriendsPlus/Page89/E5/1.jpg" }]],
  },
};

export default json;
