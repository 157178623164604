import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
const json = {
  1: {
    unit: "Unit 6",
    id: "SB5-2024-U6-P45-E1",
    recorder: true,
    audio: "Audios/Page45/66.mp3",
    // video: "Videos/Unit 01.mp4",
    component: UI,
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen,point, and repeat. <headphone name='66' src='Audios/Page45/66 tieude.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    styleContent: {
      width: "85%",
      marginLeft: 70,
    },
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page45/E1/1.jpg",
          audioUrl: "Audios/Page45/cleaned.mp3",
        },

        {
          url: "img/FriendsPlus/Page45/E1/2.jpg",
          audioUrl: "Audios/Page45/played.mp3",
        },
        {
          url: "img/FriendsPlus/Page45/E1/3.jpg",
          audioUrl: "Audios/Page45/painted.mp3",
        },
        {
          url: "img/FriendsPlus/Page45/E1/4.jpg",
          audioUrl: "Audios/Page45/folded.mp3",
        },

        {
          url: "img/FriendsPlus/Page45/E1/5.jpg",
          audioUrl: "Audios/Page45/kicked.mp3",
        },
        {
          url: "img/FriendsPlus/Page45/E1/6.jpg",
          audioUrl: "Audios/Page45/watched.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 6",
    id: "SB5-2024-U6-P45-E2",
    audio: "Audios/Page45/67.mp3",
    video: "",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and chant. <headphone name='67' src='Audios/Page45/67 tieude.mp3'></headphone>",
        color: "#203c8f",
        left: 50,
      },
    ],
    styleContent: {
      width: "80%",
      marginLeft: 50,
    },
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page45/E2/1.jpg",
        },
      ],
    ],
  },
  3: {
    unit: "Unit 6",
    id: "SB5-2024-U6-P45-E3",
    audio: "",
    video: "",
    recorder: true,
    exerciseKey: "img/FriendsPlus/Page45/Key/E3answerKey.png",
    component: Circle_Write,
    inputSize: 500,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    titleQuestion: [
      {
        num: "3",
        title:
          "Read the chant again. Underline the words that end with <i>ed</i>. Then say. ",
        color: "#203c8f",
        width: "28cm",
        left: 0,
        numberImg: ["b", "c"],
      },
    ],
    // hideBtnFooter: true,
    question: {
      // leftContent: 100,
      DrawLines: {
        multipleLine: true,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "1px 4px",
          // border: "2px solid",
          border: "none",
          // borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          // padding: "1px 4px",
          // borderRadius: "50%",
          color: "black",
          border: "none",
          borderBottom: "2px solid #00aeef",
          // borderColor: "#00aeef",
        },
        limitSelect: 6,
        listWords: [
          "I cleaned my room|.",
          "I played for hours|.",
          "I painted a picture|.",
          "I folded some flowers|.",
          "I watched TV|.",
          "I kicked a ball|.",
          "I was so busy|,",
          "All day long|!",
        ],
        answers: ["0-8", "0-2", "1-2", "2-2", "3-2", "4-2", "5-2"],
        initialValue: ["0-8", "0-2"],
      },
      Layout: `
      <div style='position: relative'>
        <img style='height:13cm;margin-top:20px' src='img/FriendsPlus/Page45/E3/1.jpg'/>
        <div style=' position: absolute; top: 123px; font-size: 22px; left: 749px; text-Align:center'>
          <div><input id=0 type='Circle' /></div>
          <div><input id=1 type='Circle' /></div>
          <div><input id=2 type='Circle' /></div>
          <div><input id=3 type='Circle' /></div>
        </div>
        <div style=' position: absolute; top: 263px; font-size: 22px; left: 788px; text-Align:center'>
          <div><input id=4 type='Circle' /></div>
          <div><input id=5 type='Circle' /></div>
          <div><input id=6 type='Circle' /></div>
          <div><input id=7 type='Circle' /></div>
        </div>
      </div>
      
      `,
    },
  },
  4: {
    unit: "Unit 6",
    id: "SB5-2024-U6-P45-E4",
    audio: "",
    video: "",
    recorder: true,
    exerciseKey: "img/FriendsPlus/Page45/Key/E4answerKey.png",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["gets", "Vinh", "at", "seven", "o'clock", "dressed", "."],
      },
      {
        src: ["Vinh", "gets", "dressed", "at", "seven", "o'clock."],
        styleElement: { color: "#10adcf", marginRight: 10 },
        marginLeft: 20,
      },
      {
        src: ["Linh", "snack", "Does", "school", "after", "a", "?"],
      },
      {
        src: ["Tuan", "don't", "Mai", "catch", "and", "bus", "the", "."],
      },
      {
        src: ["brush", "at", "teeth", "Do", "their", "night", "they", "?"],
      },
      {
        src: ["Walk", "school", "doesn't", "He", "to", "."],
      },
    ],
    stylesTextInput: { textAlign: "center", width: 150 },
    // hideBtnFooter: true,
    component: Circle_Write,
    titleQuestion: [
      {
        num: "4",
        title: "Look, match, and say.",
        color: "#203c8f",
        numberImg: ["b", "c"],
        left: 50,
      },
    ],
    question: {
      // leftContent: 100,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "77px",
              left: "78px",
              width: "131px",
              height: "61px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "60px",
              left: "535px",
              width: "127px",
              height: "61px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "174px",
              left: "293px",
              width: "132px",
              height: "61px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "283px",
              left: "490px",
              width: "117px",
              height: "61px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "328px",
              left: "132px",
              width: "148px",
              height: "61px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "427px",
              left: "364px",
              width: "118px",
              height: "61px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 5
        ],
        answers: ["2-5", "3-4", "0-1"],
        initialValue: ["0-1"],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "1px 4px",
          // border: "2px solid",
          border: "none",
          // borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          // padding: "1px 4px",
          // borderRadius: "50%",
          color: "black",
          border: "none",
          borderBottom: "2px solid #00aeef",
          // borderColor: "#00aeef",
        },
        limitSelect: 6,
        listWords: [
          "What's in the boxes|?",
          "What's in there|?",
          "Are there some cakes|?",
          "Or some cookies to share|?",
          "Are there some sandwiches|?",
          "Or some balloons|?",
          "Look|! Masks and hats|!",
          "The party's this afternoon|.",
        ],
        answers: [],
        initialValue: [],
      },
      Layout: `
        <img style='height:12cm;margin-top:20px' src='img/FriendsPlus/Page45/E4/1.jpg'/>
        <input id=0 type='boxMatch'/>
        <input id=1 type='boxMatch'/>
        <input id=2 type='boxMatch'/>
        <input id=3 type='boxMatch'/>
        <input id=4 type='boxMatch'/>
        <input id=5 type='boxMatch'/>
        
      
      `,
    },
  },
  5: {
    unit: "Unit 6",
    id: "SB5-2024-U6-P45-E5",
    // audio: "img/FriendsPlus/Page450/Audio/audio-e2-p40.mp3",
    video: "",
    component: UI,
    recorder: true,
    styleContent: {
      width: "40%",
      marginLeft: 300,
    },
    questionImage: [[{ url: "img/FriendsPlus/Page45/E5/1.jpg" }]],
  },
};

export default json;
