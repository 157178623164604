import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  8: {
    unit: "Review 2",
    id: "SB5-2024-R2-P51-E8",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page51/Key/E8answerKey.png",
    styleHint: {
      marginBottom: -25,
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["She had a good vacation in Nha Trang last summer."],
        styleElement: {
          borderBottom: "2px solid gray",
          color: "#10adcf",
          marginRight: 10,
        },
        marginLeft: 20,
      },
    ],
    // maxLength: 1,
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      // textAlign: "center",
      width: 800,
    },
    // isHello: true,
    // KeyMau: true,
    component: T6,
    titleQuestion: [
      {
        num: "8",
        title: "Write the words in the correct order.",
        color: "#203c8f",
        numberImg: ["a"],
        left: 50,
      },
    ],
    questions: [
      {
        title: `
        <div>
          <b>1</b>&ensp;good / She / summer / had / a / Nha Trang / vacation / last / . / in<br><hintbox id=0></hintbox><br>
          <b>2</b>&ensp;we / Last / didn't / Saturday / , / go / rainy / it / outside / because / was / .<br>#<br>
          <b>3</b>&ensp;the / What / was / weather / vacation / like / ? / year / their / last / on<br>#<br>
          <b>4</b>&ensp;mom / cooked / . / My / yesterday / food / delicious<br>#<br>
          <b>5</b>&ensp;buildings / There / hometown / weren't / . / 100 / many / my / in / ago / years<br>#<br>
          <b>6</b>&ensp;yesterday / ? / What / did / finish / you / time / school<br>#<br>
        </div>
      `,
        answer: [
          "Last Saturday, we didn't go outside because it was rainy.",
          "What was the weather like on their vacation last year?",
          "My mom cooked delicious food yesterday.",
          "There weren't many buildings in my hometown 100 years ago.",
          "What time did you finish school yesterday?",
        ],
      },
    ],
  },
  9: {
    unit: "Review 2",
    id: "SB5-2024-R2-P51-E9",
    exerciseKey: "img/FriendsPlus/Page51/Key/E9answerKey.png",
    video: "",
    // recorder: true,
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "There weren't many restaurants in my city 50 years ago. People cooked at home. ...",
        ],
        styleElement: {
          color: "#10adcf",
          marginRight: 10,
        },
      },
    ],
    textareaStyle: { paddingTop: 3, width: 900 },
    hideBtnFooter: true,
    component: T6,
    titleQuestion: [
      {
        num: "9",
        title:
          "What was your town or city like 50 years ago? Write a paragraph of 30 to 40 words.",
        color: "#203c8f",
        width: "27cm",
        numberImg: ["a"],
        left: 50,
      },
    ],
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <textarea id=0 rows=5></textarea>
        
      `,
        answer: [
          "on Saturdays",
          "chicken rice",
          "two cucumbers",
          "helps Mom",
          "always delicious",
        ],
      },
    ],
  },
};

export default json;
