import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 6",
    id: "SB5-2024-U6-P43-E1",
    audio: "Audios/Page42/63.mp3",
    video: "Videos/Unit 6.mp4",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen to the story and repeat. <headphone name='63' src='Audios/Page42/63 tieude.mp3'></headphone>",
        color: "#203c8f",
        left: 100,
      },
    ],
    styleContent: {
      width: "70%",
      marginLeft: 150,
    },
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page42/E3/1.jpg",
          audioUrl: "Audios/Page42/63 01.mp3",
        },
        {
          url: "img/FriendsPlus/Page42/E3/2.jpg",
          audioUrl: "Audios/Page42/63 02.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page42/E3/3.jpg",
          audioUrl: "Audios/Page42/63 03.mp3",
        },
        {
          url: "img/FriendsPlus/Page42/E3/4.jpg",
          audioUrl: "Audios/Page42/63 04.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 6",
    id: "SB5-2024-U6-P43-E2",
    audio: "Audios/Page43/64.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and say. <headphone name='64' src='Audios/Page43/64 tieude.mp3'></headphone>",
        color: "#203c8f",
        left: 100,
      },
    ],
    styleContent: {
      width: "80%",
      marginLeft: 100,
    },
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page43/E2/1.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page43/E2/2.jpg",
        },
        {
          url: "img/FriendsPlus/Page43/E2/3.jpg",
          audioUrl: "Audios/Page43/64 01.mp3",
        },
        {
          url: "img/FriendsPlus/Page43/E2/4.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page43/E2/5.jpg",
        },
        {
          url: "img/FriendsPlus/Page43/E2/6.jpg",
          audioUrl: "Audios/Page43/64 02.mp3",
        },
        {
          url: "img/FriendsPlus/Page43/E2/7.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page43/E2/8.jpg",
        },
      ],
    ],
  },
  3: {
    unit: "Unit 6",
    id: "SB5-2024-U6-P43-E3",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page43/Key/E3answerKey.png",
    component: Circle_Write,

    titleQuestion: [
      {
        num: "3",
        title: "Look, read, and circle",
        color: "#203c8f",
        width: "25cm",
        left: -50,
        numberImg: ["b"],
      },
    ],
    // hideBtnFooter: true,
    question: {
      // leftContent: 100,
      DrawLines: {
        multipleLine: true,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid white",
          // borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          // padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid #00aeef",
        },
        limitSelect: 1,
        listWords: [
          "finished / didn't_finish",
          "loved / didn't_love",
          "started / didn't_start",
          "hated / didn't_hate",
        ],
        answers: ["0-0", "2-4", "1-0", "3-0"],
        initialValue: ["0-0"],
      },
      Layout: `
        <div style='display:flex;font-size:23px;'>
          <div style='margin-right:50px'>
            <div style='text-align:center;'>
              <img style='height:5cm' src='img/FriendsPlus/Page43/E3/1.jpg'/>  
              <div style='width:13cm'>Ly <input id=0 type='Circle' /> her homework.</div>
            </div>
            <div style='text-align:center;margin-top:20px'>
              <img style='height:5cm' src='img/FriendsPlus/Page43/E3/3.jpg'/>  
              <div style='width:13cm'>The children <input id=1 type='Circle' /> the cake.</div>
            </div>
          </div>
          <div>
            <div style='text-align:center;'>
              <img style='height:5cm' src='img/FriendsPlus/Page43/E3/2.jpg'/>  
              <div style='width:13cm'>Minh <input id=2 type='Circle' /> the race.</div>
            </div>
            <div style='text-align:center;margin-top:20px'>
              <img style='height:5cm' src='img/FriendsPlus/Page43/E3/4.jpg'/>  
              <div style='width:13cm'>He <input id=3 type='Circle' /> the dinner.</div>
            </div>
          </div>
      </div>

      `,
    },
  },
  4: {
    unit: "Unit 6",
    id: "SB5-2024-U6-P43-E4",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page43/Key/E4answerKey.png",
    // styleHint: {
    //   border: "none",
    //   paddingInline: 3,
    //   paddingBlock: "0px",
    //   styleElementHintBox: {
    //     background: "#fee7d2",
    //     marginRight: 20,
    //     padding: "2px 5px",
    //     borderRadius: "10px",
    //   },
    // },
    hintBox: [
      {
        src: ["on", "in", "at"],
        width: 216,
      },
      {
        src: ["Vinh", "gets", "dressed", "at", "seven", "o'clock."],
        marginLeft: 20,
      },
      {
        src: ["Linh", "snack", "Does", "school", "after", "a", "?"],
      },
      {
        src: ["Tuan", "don't", "Mai", "catch", "and", "bus", "the", "."],
      },
      {
        src: ["brush", "at", "teeth", "Do", "their", "night", "they", "?"],
      },
      {
        src: ["Walk", "school", "doesn't", "He", "to", "."],
      },
    ],
    stylesTextInput: { width: 520 },
    // hideBtnFooter: true,
    component: T6,
    titleQuestion: [
      {
        num: "4",
        title: "Read the story again  and answer the questions.",
        color: "#203c8f",
        numberImg: ["a"],
        left: 50,
      },
    ],
    character: /[/|]/,
    questions: [
      {
        title: `
              <div style='margin-top:10px'>
                <b>1</b>&ensp;When did the Mayans live?<br>#<br>
                <b>2</b>&ensp;Did the Mayans use words in their alphabet?<br>#<br>
                <b>3</b>&ensp;What did the Mayans cook?<br>#<br>
                <b>4</b>&ensp;What did Max and Amy cook?<br>#<br>
                <b>5</b>&ensp;Did Holly finish the soup?<br>#<br>
              </div>
        
      `,
        answer: [
          "They lived 4,000 years ago.",
          "No, they did not.|No, they didn't. They used pictures.|No, they did not. They used pictures.",
          "They cooked soup / good food.|They cooked soup.|They cooked good food.",
          "They cooked soup.",
          "Yes, she did.",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 6",
    id: "SB5-2024-U6-P43-E5",
    audio: "",
    video: "",
    recorder: true,
    component: T6,
    // maxLength: 5,
    // textAlign: "center",
    textareaStyle: {
      width: 778,
      paddingTop: 5,
    },
    inputSize: 720,
    // titleQuestion: [
    //   {
    //     num: "5",
    //     title: "Write a tourist leaflet. Use the example to help you.",
    //     color: "#203c8f",
    //     numberImg: "a",
    //     left: 80,
    //   },
    // ],
    questionImage: [],
    hideBtnFooter: true,
    questions: [
      {
        title: `
          <img style='height:11cm' src='img/FriendsPlus/Page43/E5/1.jpg'/>
				  
        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
