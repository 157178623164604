import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    unit: "Unit 11",
    id: "SB5-2024-U11-P82-E1",
    audio: "Audios/Page82/123.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen, point and repeat. <headphone name='123' src='Audios/Page82/123 tieu-de.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    styleContent: {
      width: "90%",
      marginLeft: 50,
    },
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page82/E1/1.jpg",
          audioUrl: "Audios/Page82/the future.mp3",
        },
        {
          url: "img/FriendsPlus/Page82/E1/2.jpg",
        },
        {
          url: "img/FriendsPlus/Page82/E1/3.jpg",
          audioUrl: "Audios/Page82/travel.mp3",
        },
        {
          url: "img/FriendsPlus/Page82/E1/4.jpg",
        },
        {
          url: "img/FriendsPlus/Page82/E1/5.jpg",
          audioUrl: "Audios/Page82/earth.mp3",
        },
        {
          url: "img/FriendsPlus/Page82/E1/6.jpg",
        },
        {
          url: "img/FriendsPlus/Page82/E1/7.jpg",
          audioUrl: "Audios/Page82/moon.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page82/E1/8.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page82/E1/9.jpg",
          audioUrl: "Audios/Page82/rocket.mp3",
        },
        {
          url: "img/FriendsPlus/Page82/E1/10.jpg",
        },
        {
          url: "img/FriendsPlus/Page82/E1/11.jpg",
          audioUrl: "Audios/Page82/astronaut.mp3",
        },
        {
          url: "img/FriendsPlus/Page82/E1/12.jpg",
        },
        {
          url: "img/FriendsPlus/Page82/E1/13.jpg",
          audioUrl: "Audios/Page82/supersonic plane.mp3",
        },
        {
          url: "img/FriendsPlus/Page82/E1/14.jpg",
        },
        {
          url: "img/FriendsPlus/Page82/E1/15.jpg",
          audioUrl: "Audios/Page82/spaceship.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 11",
    id: "SB5-2024-U11-P82-E2",
    audio: "Audios/Page82/124.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and chant. <headphone name='124' src='Audios/Page82/124 tieu-de.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    styleContent: {
      width: "90%",
      marginLeft: 50,
    },
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page82/E1/1.jpg",
          audioUrl: "Audios/Page82/the future.mp3",
        },
        {
          url: "img/FriendsPlus/Page82/E1/2.jpg",
        },
        {
          url: "img/FriendsPlus/Page82/E1/3.jpg",
          audioUrl: "Audios/Page82/travel.mp3",
        },
        {
          url: "img/FriendsPlus/Page82/E1/4.jpg",
        },
        {
          url: "img/FriendsPlus/Page82/E1/5.jpg",
          audioUrl: "Audios/Page82/earth.mp3",
        },
        {
          url: "img/FriendsPlus/Page82/E1/6.jpg",
        },
        {
          url: "img/FriendsPlus/Page82/E1/7.jpg",
          audioUrl: "Audios/Page82/moon.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page82/E1/8.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page82/E1/9.jpg",
          audioUrl: "Audios/Page82/rocket.mp3",
        },
        {
          url: "img/FriendsPlus/Page82/E1/10.jpg",
        },
        {
          url: "img/FriendsPlus/Page82/E1/11.jpg",
          audioUrl: "Audios/Page82/astronaut.mp3",
        },
        {
          url: "img/FriendsPlus/Page82/E1/12.jpg",
        },
        {
          url: "img/FriendsPlus/Page82/E1/13.jpg",
          audioUrl: "Audios/Page82/supersonic plane.mp3",
        },
        {
          url: "img/FriendsPlus/Page82/E1/14.jpg",
        },
        {
          url: "img/FriendsPlus/Page82/E1/15.jpg",
          audioUrl: "Audios/Page82/spaceship.mp3",
        },
      ],
    ],
  },
  3: {
    unit: "Unit 11",
    id: "SB5-2024-U11-P82-E3",
    audio: "Audios/Page82/125.mp3",
    video: "Videos/Unit 11.mp4",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "3",
        title:
          "Listen and read. What will there be in the future? <headphone name='125' src='Audios/Page82/125 tieu-de.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    styleContent: {
      width: "80%",
      marginLeft: 100,
    },
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page82/E3/1.jpg",
          audioUrl: "Audios/Page82/hinh1.mp3",
        },
        {
          url: "img/FriendsPlus/Page82/E3/2.jpg",
          audioUrl: "Audios/Page82/hinh2.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page82/E3/3.jpg",
          audioUrl: "Audios/Page82/hinh3.mp3",
        },
        {
          url: "img/FriendsPlus/Page82/E3/4.jpg",
          audioUrl: "Audios/Page82/hinh4.mp3",
        },
      ],
    ],
  },
};

export default json;
