import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";
const json = {
  3: {
    unit: "Review 4",
    id: "SB5-2024-R4-P95-E3",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page95/Key/E3answerKey.png",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "3",
        title: "Match the sentences with the same strong sounds.",
        color: "#203c8f",
        width: "25cm",
        left: -50,
        numberImg: ["b"],
      },
    ],
    // hideBtnFooter: true,
    question: {
      // leftContent: 100,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "30px",
              left: "410px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "126px",
              left: "410px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "175px",
              left: "410px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "226px",
              left: "410px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0

          //RIGHT
          {
            boxMatchStyle: {
              position: "absolute",
              top: "30px",
              left: "550px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "78px",
              left: "550px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "125px",
              left: "550px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "176px",
              left: "550px",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: ["0-7", "1-6", "2-5", "3-4"],
        initialValue: ["0-7"],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "1px 4px",
          // border: "2px solid",
          border: "none",
          // borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          // padding: "1px 4px",
          // borderRadius: "50%",
          color: "black",
          border: "none",
          borderBottom: "2px solid #00aeef",
          // borderColor: "#00aeef",
        },
        limitSelect: 6,
        listWords: ["What's in the boxes|?"],
        answers: [],
        initialValue: [],
      },
      Layout: `
        <div style='position: relative; width: 25cm; margin-bottom: 100px'>
          <div style='display: flex; line-height: 50px'>
              <div style='margin-right: 150px'>
              <div><b>1. </b>Max shouldn't go to school this morning.</div>
              <div><b>2. </b>You should eat a melon.</div>
              <div><b>3. </b>Is it going to rain?</div>
              <div><b>4. </b>What are you going to do?</div>
              </div>
              <div>
              <div><b>a. </b>Where is he going to go?</div>
              <div><b>b. </b>Are you going to play?</div>
              <div><b>c. </b>You should drink some water.</div>
              <div><b>d. </b>Jo shouldn't eat a cake for breakfast.</div>
              </div>
          </div>

          <div>
            <input id=0 type='boxMatch'/>
            <input id=1 type='boxMatch'/>
            <input id=2 type='boxMatch'/>
            <input id=3 type='boxMatch'/>

            <input id=4 type='boxMatch'/>
            <input id=5 type='boxMatch'/>
            <input id=6 type='boxMatch'/>
            <input id=7 type='boxMatch'/>
           
          </div>
        </div>
      `,
    },
  },
  4: {
    unit: "Review 4",
    id: "SB5-2024-R4-P95-E4",
    // audio: "Audios/Page83/audio-e2.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    styleContent: {
      width: "90%",
      marginLeft: 50,
    },
    titleQuestion: [
      {
        num: "4",
        title: "Talk about school in the future.",
        color: "#203c8f",
        numberImg: ["c"],
      },
    ],
    questionImage: [
      [
        { url: "img/FriendsPlus/Page95/E4/1.jpg" },
        { url: "img/FriendsPlus/Page95/E4/2.jpg" },
      ],
      // [{ url: "img/FriendsPlus/Page95/E4/1.jpg" }],
    ],
  },
  5: {
    unit: "Review 4",
    id: "SB5-2024-R4-P95-E5",
    // audio: "Audios/Page83/audio-e2.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    styleContent: {
      width: "90%",
      marginLeft: 50,
    },
    titleQuestion: [
      {
        num: "5",
        title:
          "Look at the pictures and say what the boy or girl should or shouldn’t do.",
        color: "#203c8f",
        numberImg: ["c"],
      },
    ],
    questionImage: [
      [{ url: "img/FriendsPlus/Page95/E5/1.jpg" }],
      // [{ url: "img/FriendsPlus/Page95/E4/1.jpg" }],
    ],
  },
};
export default json;
