import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import Select_Image from "../../components/ExcerciseTypes/Select/Select_Image";

const json = {
  6: {
    unit: "Review 4",
    id: "SB5-2024-R4-P96-E6",
    // audio: "Audios/Page55/80.mp3",
    exerciseKey: "img/FriendsPlus/Page96/Key/E6answerKey.png",
    video: "",
    component: T6,
    maxLength: 1,
    textAlign: "center",
    inputSize: 50,
    stylesTextInput: {
      background: "transparent",
      borderBottom: "2px solid gray",
      // fontSize: 22,
      height: 27,
      // textTransform: "lowercase",
      // borderStyle: "dotted",
      textAlign: "center",
    },
    // hintBox: [
    //   {
    //     src: ["fast", "polite", "hard", "neat", "loud", "<s>good</s>"],
    //     width: 682,
    //   },
    // ],
    titleQuestion: [
      {
        num: "6",
        title: "Reorder the vacation plan of Lan’s family.",
        color: "#203c8f",
        numberImg: ["b"],
      },
    ],
    // hideBtnFooter: true,
    // selectStyle: {
    //   height: 35,
    //   width: 35,
    //   textAlign: "center",
    // },
    // selectOptionRandom: true,
    // selectOptionValues: ["T", "F"],
    questions: [
      {
        title: `
        <div style='position: relative; margin-top: 30px; margin-left: -40px'>
          <img style='height:8cm;margin-top:20px' src='img/FriendsPlus/Page96/E6/1.jpg'/>
         
            
            <div style='position: absolute; top: 142px; left: 40px'><input id=0/></div>
            <div style='position: absolute; top: 197px; left: 40px'><input id=1/></div>
            <div style='position: absolute; top: 251px; left: 40px'><input id=2/></div>
            
          
         
        </div>
          
        
        `,
        // initialValue: ["math", "", "", "", "", "", "", ""],
        answer: ["4", "3", "2"],
      },
    ],
  },
  7: {
    unit: "Review 4",
    id: "SB5-2024-R4-P96-E7",
    // audio: "Audios/Page86/audio-e1.mp3",
    exerciseKey: "img/FriendsPlus/Page96/Key/E7answerKey.png",
    video: "",
    component: T6,
    // maxLength: 6,
    textAlign: "left",
    textareaStyle: {
      paddingTop: 5,
      width: 700,
      marginBottom: -53,
      resize: "none",
    },

    inputSize: 850,
    stylesTextInput: {
      background: "none",
      borderBottom: "1.5px solid",
      fontSize: 22,
      paddingBottom: 3,
      height: 40,
      // textTransform: "lowercase",
    },
    // hintBox: [
    //   {
    //     src: ["English", "math", "P.E", "art", "Vietnamese"],
    //     width: 582,
    //   },
    // ],
    titleQuestion: [
      {
        num: "7",
        title: "Read again and answer the questions.",
        color: "#203c8f",
        numberImg: ["a"],
      },
    ],
    character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img style='height:15cm;margin-top:10px; margin-left: -30px' src='img/FriendsPlus/Page96/E7/1.jpg'/>
        <div style='display; flex'>
        <div style=''>
          <div style='display: flex'><b>1.</b>&ensp;What should you do to be strong and healthy?</div> <textarea id=0 rows=2></textarea><br>
          <div style='display: flex'><b>2.</b>&ensp;Who can you play sports with?</div><textarea id=1 rows=2></textarea><br>
          <div style='display: flex'><b>3.</b>&ensp;Should you do exercise right after eating? Why / Why not?</div><textarea id=2 rows=2></textarea><br>
          <div style='display: flex'><b>4.</b>&ensp;What should you do after doing exercise?</div><textarea id=3 rows=2></textarea><br>
          
        </div>
      
        </div>
        
        `,
        // initialValue: ["math", "", "", "", "", "", "", ""],
        answer: [
          "I should get exercise regularly./ We should get exercise regularly.",
          "I can play sports with my friends and family./ We can play sports with our friends and family.",
          "No, I shouldn't because I'll have a stomachache. /  No, we shouldn't because we'll have a stomachache.",
          "I should rest for 15 minutes./ We should rest for 15 minutes.",
        ],
      },
    ],
  },
};

export default json;
