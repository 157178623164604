import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    unit: "Unit 10",
    id: "SB5-2024-U10-P76-E1",
    audio: "Audios/Page76/113.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen, point and repeat. <headphone name='113' src='Audios/Page76/113 tieu-de.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    styleContent: {
      width: "90%",
      // marginTop: 300,
    },
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page76/E1/1.jpg",
          audioUrl: "Audios/Page76/a headache.mp3",
        },
        {
          url: "img/FriendsPlus/Page76/E1/2.jpg",
        },
        {
          url: "img/FriendsPlus/Page76/E1/3.jpg",
          audioUrl: "Audios/Page76/a fever.mp3",
        },
        {
          url: "img/FriendsPlus/Page76/E1/4.jpg",
        },
        {
          url: "img/FriendsPlus/Page76/E1/5.jpg",
          audioUrl: "Audios/Page76/a toothache.mp3",
        },
        {
          url: "img/FriendsPlus/Page76/E1/6.jpg",
        },
        {
          url: "img/FriendsPlus/Page76/E1/7.jpg",
          audioUrl: "Audios/Page76/a cold.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page76/E1/8.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page76/E1/9.jpg",
          audioUrl: "Audios/Page76/a cough.mp3",
        },
        {
          url: "img/FriendsPlus/Page76/E1/10.jpg",
        },
        {
          url: "img/FriendsPlus/Page76/E1/11.jpg",
          audioUrl: "Audios/Page76/an earache.mp3",
        },
        {
          url: "img/FriendsPlus/Page76/E1/12.jpg",
        },
        {
          url: "img/FriendsPlus/Page76/E1/13.jpg",
          audioUrl: "Audios/Page76/a stomachache.mp3",
        },
        {
          url: "img/FriendsPlus/Page76/E1/14.jpg",
        },
        {
          url: "img/FriendsPlus/Page76/E1/15.jpg",
          audioUrl: "Audios/Page76/a sore throat.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 10",
    id: "SB5-2024-U10-P76-E2",
    audio: "Audios/Page76/114.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and chant. <headphone name='114' src='Audios/Page76/114 tieu-de.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    styleContent: {
      width: "90%",
      // marginTop: 300,
    },
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page76/E1/1.jpg",
          audioUrl: "Audios/Page76/a headache.mp3",
        },
        {
          url: "img/FriendsPlus/Page76/E1/2.jpg",
        },
        {
          url: "img/FriendsPlus/Page76/E1/3.jpg",
          audioUrl: "Audios/Page76/a fever.mp3",
        },
        {
          url: "img/FriendsPlus/Page76/E1/4.jpg",
        },
        {
          url: "img/FriendsPlus/Page76/E1/5.jpg",
          audioUrl: "Audios/Page76/a toothache.mp3",
        },
        {
          url: "img/FriendsPlus/Page76/E1/6.jpg",
        },
        {
          url: "img/FriendsPlus/Page76/E1/7.jpg",
          audioUrl: "Audios/Page76/a cold.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page76/E1/8.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page76/E1/9.jpg",
          audioUrl: "Audios/Page76/a cough.mp3",
        },
        {
          url: "img/FriendsPlus/Page76/E1/10.jpg",
        },
        {
          url: "img/FriendsPlus/Page76/E1/11.jpg",
          audioUrl: "Audios/Page76/an earache.mp3",
        },
        {
          url: "img/FriendsPlus/Page76/E1/12.jpg",
        },
        {
          url: "img/FriendsPlus/Page76/E1/13.jpg",
          audioUrl: "Audios/Page76/a stomachache.mp3",
        },
        {
          url: "img/FriendsPlus/Page76/E1/14.jpg",
        },
        {
          url: "img/FriendsPlus/Page76/E1/15.jpg",
          audioUrl: "Audios/Page76/a sore throat.mp3",
        },
      ],
    ],
  },
  3: {
    unit: "Unit 10",
    id: "SB5-2024-U10-P76-E3",
    audio: "Audios/Page76/115.mp3",
    video: "Videos/Unit 10.mp4",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "3",
        title:
          "Listen and read. What's the matter with Max? <headphone name='115' src='Audios/Page76/115 tieu-de.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    styleContent: {
      width: "80%",
      marginLeft: 100,
    },
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page76/E3/1.jpg",
          audioUrl: "Audios/Page76/hinh1.mp3",
        },
        {
          url: "img/FriendsPlus/Page76/E3/2.jpg",
          audioUrl: "Audios/Page76/hinh2.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page76/E3/3.jpg",
          audioUrl: "Audios/Page76/hinh3.mp3",
        },
        {
          url: "img/FriendsPlus/Page76/E3/4.jpg",
          audioUrl: "Audios/Page76/hinh4.mp3",
        },
      ],
    ],
  },
};

export default json;
