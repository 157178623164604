import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
const json = {
  1: {
    unit: "Unit 8",
    id: "SB5-2024-U8-P62-E1",
    // audio: "Audios/Page61/audio-e2.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    styleContent: {
      width: "90%",
      // marginTop: 300,
    },
    titleQuestion: [
      {
        num: "1",
        title:
          "Ask and answer. <img style='height:1cm; width: 1.2cm; margin-bottom: 5px' src='img/FriendsPlus/IconTitle/2.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page62/E1/1.jpg" }]],
  },
  2: {
    unit: "Unit 8",
    id: "SB5-2024-U8-P62-E2",
    // audio: "Audios/Page55/80.mp3",
    exerciseKey: "img/FriendsPlus/Page62/Key/E2answerKey.png",
    video: "",
    component: T6,
    // maxLength: 6,
    textAlign: "left",
    inputSize: 800,
    stylesTextInput: {
      background: "none",
      borderBottom: "1.5px solid",
      fontSize: 22,
      paddingBottom: 3,
      height: 40,
      // textTransform: "lowercase",
    },
    // hintBox: [
    //   {
    //     src: ["English", "math", "P.E", "art", "Vietnamese"],
    //     width: 582,
    //   },
    // ],
    titleQuestion: [
      {
        num: "2",
        title: "Write three sentences.",
        color: "#203c8f",
        numberImg: ["a"],
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
      
        <img style='height:8cm;margin-top:5px' src='img/FriendsPlus/Page62/E1/1.jpg'/>
        <div style='line-height: 50px'>
          <div style='display: flex'><span style='color:rgb(54,166,215);'>Planes are faster than trains. Australia ...</span></div>
          <div style='display: flex'><b>1</b>&ensp;#</div>
          <div style='display: flex'><b>2</b>&ensp;#</div>
          <div style='display: flex'><b>3</b>&ensp;#</div>
        </div>
      
      
        
        `,
        // initialValue: ["math", "", "", "", "", "", "", ""],
        answer: ["", "", ""],
      },
    ],
  },
  3: {
    unit: "Unit 8",
    id: "SB5-2024-U8-P62-E3",
    audio: "Audios/Page62/91.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    styleContent: {
      width: "88%",
      // marginTop: 300,
    },
    titleQuestion: [
      {
        num: "3",
        title:
          "Listen and chant. <headphone name='91' src='Audios/Page62/91 tieu-de.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page62/E3/1.jpg",
          audioUrl: "Audios/Page62/candy.mp3",
        },
        {
          url: "img/FriendsPlus/Page62/E3/2.jpg",
        },
        {
          url: "img/FriendsPlus/Page62/E3/3.jpg",
          audioUrl: "Audios/Page62/candy.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page62/E3/4.jpg",
          audioUrl: "Audios/Page62/candy.mp3",
        },
        {
          url: "img/FriendsPlus/Page62/E3/5.jpg",
        },
        {
          url: "img/FriendsPlus/Page62/E3/6.jpg",
          audioUrl: "Audios/Page62/candy.mp3",
        },
      ],
    ],
  },
  4: {
    unit: "Unit 8",
    id: "SB5-2024-U8-P62-E4",
    // audio: "Audios/Page62/audio-e4.mp3",
    video: "Videos/My quiz.mp4",
    component: UI,
    recorder: true,
    question: [],
    styleContent: {
      width: "88%",
      // marginTop: 300,
    },
    titleQuestion: [
      {
        num: "4",
        title: "Chant and do.",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page62/E3/1.jpg",
          // audioUrl: "Audios/Page62/candy.mp3",
        },
        {
          url: "img/FriendsPlus/Page62/E3/2.jpg",
        },
        {
          url: "img/FriendsPlus/Page62/E3/3.jpg",
          // audioUrl: "Audios/Page62/candy.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page62/E3/4.jpg",
          // audioUrl: "Audios/Page62/candy.mp3",
        },
        {
          url: "img/FriendsPlus/Page62/E3/5.jpg",
        },
        {
          url: "img/FriendsPlus/Page62/E3/6.jpg",
          // audioUrl: "Audios/Page62/candy.mp3",
        },
      ],
    ],
  },
};

export default json;
