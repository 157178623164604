import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
const json = {
  1: {
    unit: "Unit 4",
    id: "SB5-2024-U4-P30-E1",
    audio: "Audios/Page30/41.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen, point, and repeat. <headphone name='41' src='Audios/Page30/41 tieude.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    styleContent: {
      width: "90%",
      marginLeft: 50,
    },
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page30/E1/1.jpg",
          audioUrl: "Audios/Page30/bus.mp3",
        },
        {
          url: "img/FriendsPlus/Page30/E1/2.jpg",
        },
        {
          url: "img/FriendsPlus/Page30/E1/3.jpg",
          audioUrl: "Audios/Page30/electric bike.mp3",
        },
        {
          url: "img/FriendsPlus/Page30/E1/4.jpg",
        },
        {
          url: "img/FriendsPlus/Page30/E1/5.jpg",
          audioUrl: "Audios/Page30/motorcycle.mp3",
        },
        {
          url: "img/FriendsPlus/Page30/E1/6.jpg",
        },
        {
          url: "img/FriendsPlus/Page30/E1/7.jpg",
          audioUrl: "Audios/Page30/plane.mp3",
        },
        {
          url: "img/FriendsPlus/Page30/E1/8.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page30/E1/9.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page30/E1/10.jpg",
        },
        {
          url: "img/FriendsPlus/Page30/E1/11.jpg",
          audioUrl: "Audios/Page30/taxi.mp3",
        },
        {
          url: "img/FriendsPlus/Page30/E1/12.jpg",
        },
        {
          url: "img/FriendsPlus/Page30/E1/13.jpg",
          audioUrl: "Audios/Page30/train.mp3",
        },
        {
          url: "img/FriendsPlus/Page30/E1/14.jpg",
        },
        {
          url: "img/FriendsPlus/Page30/E1/15.jpg",
          audioUrl: "Audios/Page30/trolley.mp3",
        },
        {
          url: "img/FriendsPlus/Page30/E1/16.jpg",
        },
        {
          url: "img/FriendsPlus/Page30/E1/17.jpg",
          audioUrl: "Audios/Page30/scooter.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 4",
    id: "SB5-2024-U4-P30-E2",
    audio: "Audios/Page30/42.mp3",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and chant. <headphone name='42' src='Audios/Page30/42 tieude.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    styleContent: {
      width: "90%",
      marginLeft: 50,
    },
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page30/E1/1.jpg",
          audioUrl: "Audios/Page30/bus.mp3",
        },
        {
          url: "img/FriendsPlus/Page30/E1/2.jpg",
        },
        {
          url: "img/FriendsPlus/Page30/E1/3.jpg",
          audioUrl: "Audios/Page30/electric bike.mp3",
        },
        {
          url: "img/FriendsPlus/Page30/E1/4.jpg",
        },
        {
          url: "img/FriendsPlus/Page30/E1/5.jpg",
          audioUrl: "Audios/Page30/motorcycle.mp3",
        },
        {
          url: "img/FriendsPlus/Page30/E1/6.jpg",
        },
        {
          url: "img/FriendsPlus/Page30/E1/7.jpg",
          audioUrl: "Audios/Page30/plane.mp3",
        },
        {
          url: "img/FriendsPlus/Page30/E1/8.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page30/E1/9.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page30/E1/10.jpg",
        },
        {
          url: "img/FriendsPlus/Page30/E1/11.jpg",
          audioUrl: "Audios/Page30/taxi.mp3",
        },
        {
          url: "img/FriendsPlus/Page30/E1/12.jpg",
        },
        {
          url: "img/FriendsPlus/Page30/E1/13.jpg",
          audioUrl: "Audios/Page30/train.mp3",
        },
        {
          url: "img/FriendsPlus/Page30/E1/14.jpg",
        },
        {
          url: "img/FriendsPlus/Page30/E1/15.jpg",
          audioUrl: "Audios/Page30/trolley.mp3",
        },
        {
          url: "img/FriendsPlus/Page30/E1/16.jpg",
        },
        {
          url: "img/FriendsPlus/Page30/E1/17.jpg",
          audioUrl: "Audios/Page30/scooter.mp3",
        },
      ],
    ],
  },
  3: {
    unit: "Unit 4",
    id: "SB5-2024-U4-P30-E3",
    audio: "Audios/Page30/43.mp3",
    video: "Videos/unit 4.mp4",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "3",
        title:
          "Listen and read. Are they at a museum?<headphone name='43' src='Audios/Page30/43 tieude.mp3'></headphone>",
        color: "#203c8f",
        left: 100,
      },
    ],
    hideBtnFooter: true,
    styleContent: {
      width: "70%",
      marginLeft: 150,
    },
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page30/E3/1.jpg",
          audioUrl: "Audios/Page30/43 01.mp3",
        },
        {
          url: "img/FriendsPlus/Page30/E3/2.jpg",
          audioUrl: "Audios/Page30/43 02.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page30/E3/3.jpg",
          audioUrl: "Audios/Page30/43 03.mp3",
        },
        {
          url: "img/FriendsPlus/Page30/E3/4.jpg",
          audioUrl: "Audios/Page30/43 04.mp3",
        },
      ],
    ],
  },
};

export default json;
