import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    unit: "Unit 8",
    id: "SB5-2024-U8-P65-E1",
    audio: "Audios/Page65/96.mp3",
    exerciseKey: "img/FriendsPlus/Page65/Key/E1answerKey.png",
    video: "",
    component: T6,
    // maxLength: 6,
    textAlign: "left",
    // inputSize: 200,
    stylesTextInput: {
      background: "none",
      borderBottom: "2px solid gray",
      fontSize: 20.5,
      paddingBottom: 3,
      height: 40,
      width: 130,
      // textTransform: "lowercase",
    },
    // hintBox: [
    //   {
    //     src: ["English", "math", "P.E", "art", "Vietnamese"],
    //     width: 582,
    //   },
    // ],
    titleQuestion: [
      {
        num: "1",
        title:
          "Copy the table. Listen and complete. <headphone name='96' src='Audios/Page65/96 tieu-de.mp3'></headphone>",
        color: "#203c8f",
        numberImg: ["a"],
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='position: relative'>
          <img style='height:8cm;margin-top:10px' src='img/FriendsPlus/Page65/E1/1.jpg'/>
          <div style='position: absolute; top: 130px; left: 257px'>#</div>
          <div style='position: absolute; top: 182px; left: 257px'>#</div>
          <div style='position: absolute; top: 234px; left: 257px'>#</div>
          <div style='position: absolute; top: 130px; left: 450px'><input id='3' width= 285px></div>
          <div style='position: absolute; top: 182px; left: 450px'><input id='4' width= 285px></div>
          <div style='position: absolute; top: 234px; left: 450px'><input id='5' width= 285px></div>
        </div>
        
        
        `,
        // initialValue: ["math", "", "", "", "", "", "", ""],
        answer: [
          "lake",
          "river",
          "island",
          "larger than Lak lake",
          "shorter than Dong Nai river",
          "smaller than Phu Quoc",
        ],
      },
    ],
  },

  2: {
    unit: "Unit 8",
    id: "SB5-2024-U8-P65-E2",
    // audio: "Audios/Page64/audio-e2.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    styleContent: {
      width: "90%",
      marginLeft: 15,
    },
    titleQuestion: [
      {
        num: "2",
        title:
          "Look at the table. Then ask and answer. <img style='height:1cm; width: 1.2cm; margin-bottom: 5px' src='img/FriendsPlus/IconTitle/2.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page65/E2/2.jpg",
          // audioUrl: "Audios/Page64/candy.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page65/E2/1.jpg",
          // audioUrl: "Audios/Page64/candy.mp3",
        },
      ],
    ],
  },
  3: {
    unit: "Unit 8",
    id: "SB5-2024-U8-P65-E3",
    // audio: "Audios/Page64/audio-e2.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    styleContent: {
      width: "90%",
      marginLeft: 15,
    },
    titleQuestion: [
      {
        num: "3",
        title:
          "Read the facts and say. <img style='height:1cm; width: 1.2cm; margin-bottom: 5px' src='img/FriendsPlus/IconTitle/2.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page65/E3/1.jpg",
          // audioUrl: "Audios/Page64/candy.mp3",
        },
      ],
    ],
  },
  4: {
    unit: "Unit 8",
    id: "SB5-2024-U8-P65-E4",
    // audio: "Audios/Page65/audio-e1.mp3",
    exerciseKey: "img/FriendsPlus/Page65/Key/E4answerKey.png",
    video: "",
    component: T6,
    // maxLength: 6,
    textAlign: "left",
    // inputSize: 200,
    textareaStyle: {
      // background: 'none',
      width: 800,
      fontSize: 22,
      paddingTop: 5,
      // height: 70,
    },
    titleQuestion: [
      {
        num: "4",
        title: "Write a fact file. Use the example to help you.",
        color: "#203c8f",
        numberImg: ["a"],
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div >
          <div style='margin-left: -50px'><img style='height:9cm;margin-top:10px' src='img/FriendsPlus/Page65/E4/1.jpg'/><br></div>
          <textarea id='0' rows='8'>
        </div>
        
        
        `,
        // initialValue: ["math", "", "", "", "", "", "", ""],
        answer: ["lake"],
      },
    ],
  },
};

export default json;
