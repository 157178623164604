import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    unit: "Unit 7",
    id: "SB5-2024-U7-P54-E1",
    audio: "Audios/Page54/77.mp3",
    video: "",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen, point and repeat. <headphone name='77' src='Audios/Page54/77 tieu-de.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    styleContent: {
      width: "80%",
      marginLeft: 70,
    },
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page54/E1/1.jpg",
          audioUrl: "Audios/Page54/dinosaur.mp3",
        },
        { url: "img/FriendsPlus/Page54/E1/2.jpg" },
        {
          url: "img/FriendsPlus/Page54/E1/3.jpg",
          audioUrl: "Audios/Page54/bones.mp3",
        },
        { url: "img/FriendsPlus/Page54/E1/4.jpg" },
        {
          url: "img/FriendsPlus/Page54/E1/5.jpg",
          audioUrl: "Audios/Page54/model.mp3",
        },
        { url: "img/FriendsPlus/Page54/E1/6.jpg" },
        {
          url: "img/FriendsPlus/Page54/E1/7.jpg",
          audioUrl: "Audios/Page54/scary.mp3",
        },
        { url: "img/FriendsPlus/Page54/E1/8.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page54/E1/9.jpg" },
        {
          url: "img/FriendsPlus/Page54/E1/10.jpg",
          audioUrl: "Audios/Page54/scream.mp3",
        },
        { url: "img/FriendsPlus/Page54/E1/11.jpg" },
        {
          url: "img/FriendsPlus/Page54/E1/12.jpg",
          audioUrl: "Audios/Page54/roar.mp3",
        },
        { url: "img/FriendsPlus/Page54/E1/13.jpg" },
        {
          url: "img/FriendsPlus/Page54/E1/14.jpg",
          audioUrl: "Audios/Page54/alive.mp3",
        },
        { url: "img/FriendsPlus/Page54/E1/15.jpg" },
        {
          url: "img/FriendsPlus/Page54/E1/16.jpg",
          audioUrl: "Audios/Page54/dead.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 7",
    id: "SB5-2024-U7-P54-E2",
    audio: "Audios/Page54/78.mp3",
    video: "",
    component: UI,
    recorder: true,
    styleContent: {
      width: "80%",
      marginLeft: 70,
    },
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and chant. <headphone name='78' src='Audios/Page54/78 tieu-de.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page54/E1/1.jpg",
          audioUrl: "Audios/Page54/dinosaur.mp3",
        },
        { url: "img/FriendsPlus/Page54/E1/2.jpg" },
        {
          url: "img/FriendsPlus/Page54/E1/3.jpg",
          audioUrl: "Audios/Page54/bones.mp3",
        },
        { url: "img/FriendsPlus/Page54/E1/4.jpg" },
        {
          url: "img/FriendsPlus/Page54/E1/5.jpg",
          audioUrl: "Audios/Page54/model.mp3",
        },
        { url: "img/FriendsPlus/Page54/E1/6.jpg" },
        {
          url: "img/FriendsPlus/Page54/E1/7.jpg",
          audioUrl: "Audios/Page54/scary.mp3",
        },
        { url: "img/FriendsPlus/Page54/E1/8.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page54/E1/9.jpg" },
        {
          url: "img/FriendsPlus/Page54/E1/10.jpg",
          audioUrl: "Audios/Page54/scream.mp3",
        },
        { url: "img/FriendsPlus/Page54/E1/11.jpg" },
        {
          url: "img/FriendsPlus/Page54/E1/12.jpg",
          audioUrl: "Audios/Page54/roar.mp3",
        },
        { url: "img/FriendsPlus/Page54/E1/13.jpg" },
        {
          url: "img/FriendsPlus/Page54/E1/14.jpg",
          audioUrl: "Audios/Page54/alive.mp3",
        },
        { url: "img/FriendsPlus/Page54/E1/15.jpg" },
        {
          url: "img/FriendsPlus/Page54/E1/16.jpg",
          audioUrl: "Audios/Page54/dead.mp3",
        },
      ],
    ],
  },
  3: {
    unit: "Unit 7",
    id: "SB5-2024-U7-P54-E3",
    audio: "Audios/Page54/79.mp3",
    video: "Videos/Unit 7.mp4",
    component: UI,
    recorder: true,
    styleContent: {
      width: "80%",
      marginLeft: 70,
    },
    titleQuestion: [
      {
        num: "3",
        title:
          "Listen and read. Where did the children go? <headphone name='79' src='Audios/Page54/79 tieu-de.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page54/E3/1.jpg",
          audioUrl: "Audios/Page54/79 01.mp3",
        },
        {
          url: "img/FriendsPlus/Page54/E3/2.jpg",
          audioUrl: "Audios/Page54/79 02.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page54/E3/3.jpg",
          audioUrl: "Audios/Page54/79 03.mp3",
        },
        {
          url: "img/FriendsPlus/Page54/E3/4.jpg",
          audioUrl: "Audios/Page54/79 04.mp3",
        },
      ],
    ],
  },
};

export default json;
