import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 10",
    id: "SB5-2024-U10-P77-E1",
    audio: "Audios/Page77/115.mp3",
    video: "Videos/Unit 10.mp4",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen to the story and repeat. <headphone name='115' src='Audios/Page77/115-tieude.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    styleContent: {
      width: "80%",
      marginLeft: 100,
    },
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page76/E3/1.jpg",
          audioUrl: "Audios/Page76/hinh1.mp3",
        },
        {
          url: "img/FriendsPlus/Page76/E3/2.jpg",
          audioUrl: "Audios/Page76/hinh2.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page76/E3/3.jpg",
          audioUrl: "Audios/Page76/hinh3.mp3",
        },
        {
          url: "img/FriendsPlus/Page76/E3/4.jpg",
          audioUrl: "Audios/Page76/hinh4.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 10",
    id: "SB5-2024-U10-P77-E2",
    audio: "Audios/Page77/116.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and say. <headphone name='116' src='Audios/Page77/116 tieu-de.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    styleContent: {
      width: "90%",
      marginLeft: 20,
    },
    questionImage: [
      [{ url: "img/FriendsPlus/Page77/E2/1.jpg" }],
      [
        {
          url: "img/FriendsPlus/Page77/E2/2.jpg",
          // audioUrl: "Audios/Page77/116 01.mp3",
        },
        {
          url: "img/FriendsPlus/Page77/E2/3.jpg",
        },
      ],
      [
        { url: "img/FriendsPlus/Page77/E2/4.jpg" },
        {
          url: "img/FriendsPlus/Page77/E2/5.jpg",
          // audioUrl: "Audios/Page77/116 02.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page77/E2/6.jpg",
        },
      ],
    ],
  },
  3: {
    unit: "Unit 10",
    id: "SB5-2024-U10-P77-E3",
    // audio: "Audios/3-07.mp3",
    exerciseKey: "img/FriendsPlus/Page77/Key/E3answerKey.png",
    component: Circle_Write,
    // recorder: true,
    inputSize: 500,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    titleQuestion: [
      {
        num: "3",
        title: "Circle.",
        color: "#203c8f",
        width: "26cm",
        numberImg: ["b"],
      },
    ],
    // hideBtnFooter: true,
    question: {
      // leftContent: 100,
      DrawLines: {
        multipleLine: true,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          // border: "2px solid",
          border: "none",
          // borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid #00aeef",
          // borderBottom: "2px solid #00aeef",
          // borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "should / shouldn't",
          "should / shouldn't",
          "should / shouldn't",
          "should / shouldn't",
        ],
        answers: ["0-0", "1-4", "2-0", "3-4"],
        initialValue: ["0-0"],
      },
      Layout: `
      <div style='display: flex; margin-bottom: 50px'>
      
      <div style='line-height: 45px'>
        <div>Max is ill. He has a fever and a cough.</div>
        <div><b>1.&ensp;</b>Max <input id=0 type='Circle' /> drink water.</div>
        <div><b>2.&ensp;</b>He <input id=1 type='Circle' /> play outside.</div>
        <div><b>3.&ensp;</b>He <input id=2 type='Circle' /> go to bed.</div>
        <div><b>4.&ensp;</b>He <input id=3 type='Circle' /> go to school.</div>
      </div>
      <div><img style='height:6cm' src='img/FriendsPlus/Page77/E3/1.jpg'/></div>
      </div>
      
      `,
    },
  },
  4: {
    unit: "Unit 10",
    id: "SB5-2024-U10-P77-E4",
    // audio: "Audios/3-07.mp3",
    exerciseKey: "img/FriendsPlus/Page77/Key/E4answerKey.png",
    component: Circle_Write,
    // recorder: true,
    // inputSize: 500,
    // stylesTextInput: { width: 170, paddingTop: 10, textAlign: "center" },
    titleQuestion: [
      {
        num: "4",
        title: "Circle and write.",
        color: "#203c8f",
        width: "25cm",
        numberImg: ["b", "a"],
      },
    ],
    // hideBtnFooter: true,
    question: {
      // leftContent: 100,
      DrawLines: {
        multipleLine: true,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: {
          width: 150,
          fontSize: 23,
          textAlign: "center",
          paddingBottom: 10,
        },
        answers: ["cold", "earache"],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          // border: "2px solid",
          border: "none",
          // borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid #00aeef",
          // borderBottom: "2px solid #00aeef",
          // borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "should / shouldn't",
          "Yes. / No.",
          "should / shouldn't",
          "Yes. / No.",
        ],
        answers: ["0-0", "1-4", "2-0", "3-4"],
        initialValue: [],
      },
      Layout: `
      
        <div style='line-height: 50px'>
          <div>
            <img style='height:9cm;margin-left:40px' src='img/FriendsPlus/Page77/E4/1.jpg'/>
          </div>
          <div style='display: flex; font-size: 23px'>
            <div>
              <div><b>a.</b>&ensp;He has a <input id=0 />.</div>
              <div><b>b.</b>&ensp;He <input id=0 type='Circle' /> drink juice.</div>
              <div><b>c.</b>&ensp;Should he go outside?<br> <input id=1 type='Circle' /></div>
            </div>
            <div style='margin-left: 45px'>
              <div><b>a.</b>&ensp;He has an <input id=1 />.</div>
              <div><b>b.</b>&ensp;He <input id=2 type='Circle' /> go to the doctor.</div>
              <div><b>c.</b>&ensp;Should he listen to loud music?<br> <input id=3 type='Circle' /></div>
            </div>
          </div>
          
        </div>
      
      `,
    },
  },
  5: {
    unit: "Unit 9",
    id: "SB5-2024-U9-P77-E5",
    // audio: "img/FriendsPlus/Page110/Audio/audio-e2-p40.mp3",
    video: "",
    component: UI,
    recorder: true,
    styleContent: {
      width: "35%",
      marginLeft: 350,
    },
    questionImage: [[{ url: "img/FriendsPlus/Page77/E4/2.jpg" }]],
  },
};

export default json;
