import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
const json = {
  1: {
    unit: "Unit 3",
    id: "SB5-2024-U3-P20-E1",
    audio: "Audios/Page20/26.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen, point, and repeat. <headphone name='26' src='Audios/Page20/26 tieude.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    styleContent: {
      width: "90%",
      marginLeft: 50,
    },
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page20/E1/1.jpg",
          audioUrl: "Audios/Page20/lemonade.mp3",
        },
        {
          url: "img/FriendsPlus/Page20/E1/2.jpg",
        },
        {
          url: "img/FriendsPlus/Page20/E1/3.jpg",
          audioUrl: "Audios/Page20/mushrooms.mp3",
        },
        {
          url: "img/FriendsPlus/Page20/E1/4.jpg",
        },
        {
          url: "img/FriendsPlus/Page20/E1/5.jpg",
          audioUrl: "Audios/Page20/meat.mp3",
        },
        {
          url: "img/FriendsPlus/Page20/E1/6.jpg",
        },
        {
          url: "img/FriendsPlus/Page20/E1/7.jpg",
          audioUrl: "Audios/Page20/melon.mp3",
        },
        {
          url: "img/FriendsPlus/Page20/E1/8.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page20/E1/9.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page20/E1/10.jpg",
        },
        {
          url: "img/FriendsPlus/Page20/E1/11.jpg",
          audioUrl: "Audios/Page20/cucumber.mp3",
        },
        {
          url: "img/FriendsPlus/Page20/E1/12.jpg",
        },
        {
          url: "img/FriendsPlus/Page20/E1/13.jpg",
          audioUrl: "Audios/Page20/onion.mp3",
        },
        {
          url: "img/FriendsPlus/Page20/E1/14.jpg",
        },
        {
          url: "img/FriendsPlus/Page20/E1/15.jpg",
          audioUrl: "Audios/Page20/soda.mp3",
        },
        {
          url: "img/FriendsPlus/Page20/E1/16.jpg",
        },
        {
          url: "img/FriendsPlus/Page20/E1/17.jpg",
          audioUrl: "Audios/Page20/ice tea.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 3",
    id: "SB5-2024-U3-P20-E2",
    audio: "Audios/Page20/27.mp3",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and chant. <headphone name='27' src='Audios/Page20/27 tieude.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    styleContent: {
      width: "90%",
      marginLeft: 50,
    },
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page20/E1/1.jpg",
          audioUrl: "Audios/Page20/lemonade.mp3",
        },
        {
          url: "img/FriendsPlus/Page20/E1/2.jpg",
        },
        {
          url: "img/FriendsPlus/Page20/E1/3.jpg",
          audioUrl: "Audios/Page20/mushrooms.mp3",
        },
        {
          url: "img/FriendsPlus/Page20/E1/4.jpg",
        },
        {
          url: "img/FriendsPlus/Page20/E1/5.jpg",
          audioUrl: "Audios/Page20/meat.mp3",
        },
        {
          url: "img/FriendsPlus/Page20/E1/6.jpg",
        },
        {
          url: "img/FriendsPlus/Page20/E1/7.jpg",
          audioUrl: "Audios/Page20/melon.mp3",
        },
        {
          url: "img/FriendsPlus/Page20/E1/8.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page20/E1/9.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page20/E1/10.jpg",
        },
        {
          url: "img/FriendsPlus/Page20/E1/11.jpg",
          audioUrl: "Audios/Page20/cucumber.mp3",
        },
        {
          url: "img/FriendsPlus/Page20/E1/12.jpg",
        },
        {
          url: "img/FriendsPlus/Page20/E1/13.jpg",
          audioUrl: "Audios/Page20/onion.mp3",
        },
        {
          url: "img/FriendsPlus/Page20/E1/14.jpg",
        },
        {
          url: "img/FriendsPlus/Page20/E1/15.jpg",
          audioUrl: "Audios/Page20/soda.mp3",
        },
        {
          url: "img/FriendsPlus/Page20/E1/16.jpg",
        },
        {
          url: "img/FriendsPlus/Page20/E1/17.jpg",
          audioUrl: "Audios/Page20/ice tea.mp3",
        },
      ],
    ],
  },
  3: {
    unit: "Unit 3",
    id: "SB5-2024-U3-P20-E3",
    audio: "Audios/Page20/28.mp3",
    video: "Videos/Unit 3.mp4",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "3",
        title:
          "Listen and read. What fruit do they need?<headphone name='28' src='Audios/Page20/28 tieude.mp3'></headphone>",
        color: "#203c8f",
        left: 100,
      },
    ],
    hideBtnFooter: true,
    styleContent: {
      width: "70%",
      marginLeft: 150,
    },
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page20/E3/1.jpg",
          audioUrl: "Audios/Page20/28 01.mp3",
        },
        {
          url: "img/FriendsPlus/Page20/E3/2.jpg",
          audioUrl: "Audios/Page20/28 02.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page20/E3/3.jpg",
          audioUrl: "Audios/Page20/28 03.mp3",
        },
        {
          url: "img/FriendsPlus/Page20/E3/4.jpg",
          audioUrl: "Audios/Page20/28 04.mp3",
        },
      ],
    ],
  },
};

export default json;
