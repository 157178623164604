import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 9",
    id: "SB5-2024-U9-P69-E1",
    audio: "Audios/Page69/102.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    styleContent: {
      width: "70%",
      marginLeft: 100,
      marginBottom: 50,
    },
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen, point and repeat. <headphone name='102' src='Audios/Page69/102 tieu-de.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page69/E1/1.jpg",
          audioUrl: "Audios/Page69/eat slowly.mp3",
        },
        {
          url: "img/FriendsPlus/Page69/E1/2.jpg",
        },
        {
          url: "img/FriendsPlus/Page69/E1/3.jpg",
          audioUrl: "Audios/Page69/shout loudly.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page69/E1/4.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page69/E1/5.jpg",
          audioUrl: "Audios/Page69/speak politely.mp3",
        },
        {
          url: "img/FriendsPlus/Page69/E1/6.jpg",
        },
        {
          url: "img/FriendsPlus/Page69/E1/7.jpg",
          audioUrl: "Audios/Page69/play quietly.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page69/E1/8.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page69/E1/9.jpg",
          audioUrl: "Audios/Page69/run quickly.mp3",
        },
        {
          url: "img/FriendsPlus/Page69/E1/10.jpg",
        },
        {
          url: "img/FriendsPlus/Page69/E1/11.jpg",
          audioUrl: "Audios/Page69/write neatly.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 9",
    id: "SB5-2024-U9-P69-E2",
    audio: "Audios/Page69/103.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    styleContent: {
      width: "90%",
      marginLeft: 50,
      marginBottom: 50,
    },
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and chant. <headphone name='103' src='Audios/Page69/103 tieu-de.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page69/E2/1.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page69/E2/2.jpg",
        },
        {
          url: "img/FriendsPlus/Page69/E2/3.jpg",
          // audioUrl: "Audios/Page69/candy.mp3",
        },
        {
          url: "img/FriendsPlus/Page69/E2/4.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page69/E2/5.jpg",
        },
        {
          url: "img/FriendsPlus/Page69/E2/6.jpg",
          // audioUrl: "Audios/Page69/candy.mp3",
        },
        {
          url: "img/FriendsPlus/Page69/E2/7.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page69/E2/8.jpg",
        },
        {
          url: "img/FriendsPlus/Page69/E2/9.jpg",
          audioUrl: "Audios/Page69/candy.mp3",
        },
        {
          url: "img/FriendsPlus/Page69/E2/10.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page69/E2/11.jpg",
        },
        {
          url: "img/FriendsPlus/Page69/E2/12.jpg",
          audioUrl: "Audios/Page69/candy.mp3",
        },
        {
          url: "img/FriendsPlus/Page69/E2/13.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page69/E2/14.jpg",
        },
        {
          url: "img/FriendsPlus/Page69/E2/15.jpg",
          audioUrl: "Audios/Page69/candy.mp3",
        },
        {
          url: "img/FriendsPlus/Page69/E2/16.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page69/E2/17.jpg",
        },
        {
          url: "img/FriendsPlus/Page69/E2/18.jpg",
          audioUrl: "Audios/Page69/candy.mp3",
        },
        {
          url: "img/FriendsPlus/Page69/E2/19.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page69/E2/20.jpg",
        },
        {
          url: "img/FriendsPlus/Page69/E2/21.jpg",
          audioUrl: "Audios/Page69/candy.mp3",
        },
        {
          url: "img/FriendsPlus/Page69/E2/22.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page69/E2/23.jpg",
        },
        {
          url: "img/FriendsPlus/Page69/E2/24.jpg",
          audioUrl: "Audios/Page69/candy.mp3",
        },
        {
          url: "img/FriendsPlus/Page69/E2/25.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page69/E2/26.jpg",
        },
      ],
    ],
  },
  3: {
    unit: "Unit 9",
    id: "SB5-2024-U9-P69-E3",
    // audio: "Audios/3-07.mp3",
    exerciseKey: "img/FriendsPlus/Page69/Key/E3answerKey.png",
    component: Circle_Write,
    recorder: true,
    inputSize: 500,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    titleQuestion: [
      {
        num: "3",
        title:
          "Read the chant again. Underline the stronger sounds. Then say. <img style='height:1cm; width: 2cm; margin-bottom: 5px' src='img/FriendsPlus/IconTitle/1.jpg'/> <img style='height:1cm; width: 1.2cm; margin-bottom: 5px' src='img/FriendsPlus/IconTitle/2.jpg'/>",
        color: "#203c8f",
        width: "26cm",
        // numberImg: ["a"],
      },
    ],
    // hideBtnFooter: true,
    question: {
      // leftContent: 100,
      DrawLines: {
        multipleLine: true,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "1px 4px",
          // border: "2px solid",
          // border: "none",
          // borderColor: "transparent",
          // fontWeight: "bold",
        },
        selectWordStyle: {
          // fontWeight: "bold",
          // padding: "1px 4px",
          // borderRadius: "50%",
          color: "black",
          border: "none",
          borderBottom: "2px solid #00aeef",
          // borderColor: "#00aeef",
        },
        limitSelect: 10,
        listWords: [
          "The elephant walks slow|ly",
          "Moves slow|ly",
          "eats slow|ly",
          "It only moves slow|ly",
          "When it's surprised",
          "The tiger walks quick|ly",
          "Sits quiet|ly",
          "waits quiet|ly",
          "It only roars quiet|ly",
          "When it's surprised.",
        ],
        answers: ["0-6", "1-2", "2-2", "3-6", "5-6", "6-2", "7-2", "8-6"],
        initialValue: ["0-6"],
      },
      Layout: `
      <div style='position: relative'>
      <img style='height:11cm;margin-top:20px' src='img/FriendsPlus/Page69/E3/1.jpg'/>
      
      <div style='position: absolute; top: 69px; font-size: 23px; left: 96px; text-align: center; line-height: 40px;'>
        <div><input id=0 type='Circle' />,</div>
        <div><input id=1 type='Circle' />, <input id=2 type='Circle' />.</div>
        <div><input id=3 type='Circle' /></div>
        <div> <input id=4 type='Circle' />.</div>
        <div> <input id=5 type='Circle' />,</div>
        <div> <input id=6 type='Circle' />,  <input id=7 type='Circle' />.</div>
        
        <div> <input id=8 type='Circle' /></div>
        <div> <input id=9 type='Circle' /></div>
      </div>
      
      </div>
      
      `,
    },
  },
  4: {
    unit: "Unit 9",
    id: "SB5-2024-U9-P69-E4",
    // audio: "Audios/3-07.mp3",
    exerciseKey: "img/FriendsPlus/Page69/Key/E4answerKey.png",
    component: Circle_Write,
    recorder: true,
    inputSize: 500,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    titleQuestion: [
      {
        num: "4",
        title:
          "Say the sentences. Underline the stronger sounds. <img style='height:1cm; width: 1.2cm; margin-bottom: 5px' src='img/FriendsPlus/IconTitle/2.jpg'/><img style='height:1cm; width: 2cm; margin-bottom: 5px' src='img/FriendsPlus/IconTitle/1.jpg'/>",
        color: "#203c8f",
        width: "25cm",
        // numberImg: ["a"],
      },
    ],
    // hideBtnFooter: true,
    question: {
      // leftContent: 100,
      DrawLines: {
        multipleLine: true,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "1px 4px",
          // border: "2px solid",
          border: "none",
          // borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          // padding: "1px 4px",
          // borderRadius: "50%",
          color: "black",
          border: "none",
          borderBottom: "2px solid #00aeef",
          // borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "s_l_o_w l_y",
          "p_o_l_i_t_e l_y",
          "q_u_i_c_k l_y",
          "l_o_u_d l_y",
          "q_u_i_e_t l_y",
          "n_e_a_t l_y",
        ],
        answers: ["0-0", "1-0", "2-0", "3-0", "4-0", "5-0"],
        initialValue: ["0-0"],
      },
      Layout: `
    
        <div style='line-height: 50px; margin-left: 50px'>
          <div><b>1.</b>&ensp;Eat your dinner <input id=0 type='Circle' />.</div>
          <div><b>2.</b>&ensp;Speak <input id=1 type='Circle' /> to your mom.</div>
          <div><b>3.</b>&ensp;Go inside <input id=2 type='Circle' />.</div>
          <div><b>4.</b>&ensp;You mustn't shout <input id=3 type='Circle' />.</div>
          <div><b>5.</b>&ensp;Shh! Talk <input id=4 type='Circle' />, please.</div>
          <div><b>6.</b>&ensp;Write <input id=5 type='Circle' /> in your notebook.</div>
        </div>
        
      `,
    },
  },
  5: {
    unit: "Unit 9",
    id: "SB5-2024-U9-P69-E5",
    // audio: "img/FriendsPlus/Page110/Audio/audio-e2-p40.mp3",
    video: "",
    component: UI,
    recorder: true,
    styleContent: {
      width: "35%",
      marginLeft: 350,
    },
    questionImage: [[{ url: "img/FriendsPlus/Page69/E4/1.jpg" }]],
  },
};

export default json;
