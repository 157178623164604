import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 11",
    id: "SB5-2024-U11-P86-E1",
    audio: "Audios/Page86/130.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    styleContent: {
      width: "95%",
      marginLeft: 15,
    },
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen, point and repeat. <headphone name='130' src='Audios/Page86/130 tieu-de.mp3'></headphone>",
        color: "#203c8f",
        // numberImg: ["c", "b"],
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page86/E1/1.jpg",
          audioUrl: "Audios/Page86/robot.mp3",
        },
        {
          url: "img/FriendsPlus/Page86/E1/2.jpg",
        },
        {
          url: "img/FriendsPlus/Page86/E1/3.jpg",
          audioUrl: "Audios/Page86/traffic.mp3",
        },
        {
          url: "img/FriendsPlus/Page86/E1/4.jpg",
        },
        {
          url: "img/FriendsPlus/Page86/E1/5.jpg",
          audioUrl: "Audios/Page86/crowded.mp3",
        },
        {
          url: "img/FriendsPlus/Page86/E1/6.jpg",
        },
        {
          url: "img/FriendsPlus/Page86/E1/7.jpg",
          audioUrl: "Audios/Page86/road.mp3",
        },
        {
          url: "img/FriendsPlus/Page86/E1/8.jpg",
        },
        {
          url: "img/FriendsPlus/Page86/E1/9.jpg",
          audioUrl: "Audios/Page86/storm.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 11",
    id: "SB5-2024-U11-P86-E2",
    // audio: "Audios/Page86/audio-e2.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    styleContent: {
      width: "85%",
      marginLeft: 80,
    },
    titleQuestion: [
      {
        num: "2",
        title: "Look at the text. What can you see in the pictures?",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page86/E3/1.jpg",
          // audioUrl: "Audios/Page86/candy.mp3",
        },
      ],
    ],
  },
  3: {
    unit: "Unit 11",
    id: "SB5-2024-U11-P86-E3",
    audio: "Audios/Page86/131.mp3",
    exerciseKey: "img/FriendsPlus/Page86/Key/E3answerKey.png",
    video: "",
    component: T6,
    // maxLength: 6,
    textAlign: "center",
    inputSize: 200,
    stylesTextInput: {
      background: "none",
      borderBottom: "1.5px solid",
      fontSize: 22,
      paddingBottom: 3,
      height: 40,
      textTransform: "lowercase",
    },
    // hintBox: [
    //   {
    //     src: ["English", "math", "P.E", "art", "Vietnamese"],
    //     width: 582,
    //   },
    // ],
    titleQuestion: [
      {
        num: "3",
        title:
          "Listen and read. <headphone name='131' src='Audios/Page86/131 tieu-de.mp3'></headphone>",
        color: "#203c8f",
        // numberImg: ["a"],
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img style='height:16.5cm;margin-top:20px' src='img/FriendsPlus/Page86/E3/1.jpg'/>
      
        </div>
        
        `,
        // initialValue: ["math", "", "", "", "", "", "", ""],
        answer: [],
      },
    ],
  },
  4: {
    unit: "Unit 11",
    id: "SB5-2024-U11-P86-E4",
    // audio: "Audios/Page86/audio-e1.mp3",
    exerciseKey: "img/FriendsPlus/Page86/Key/E4answerKey.png",
    video: "",
    component: T6,
    // maxLength: 6,
    textAlign: "left",
    inputSize: 850,
    stylesTextInput: {
      background: "none",
      borderBottom: "1.5px solid",
      fontSize: 22,
      paddingBottom: 3,
      height: 40,
      // textTransform: "lowercase",
    },
    // hintBox: [
    //   {
    //     src: ["English", "math", "P.E", "art", "Vietnamese"],
    //     width: 582,
    //   },
    // ],
    titleQuestion: [
      {
        num: "4",
        title: "Read again and answer the questions.",
        color: "#203c8f",
        numberImg: ["a"],
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img style='height:15cm;margin-top:10px' src='img/FriendsPlus/Page86/E3/1.jpg'/>
        <div style='display; flex'>
        <div style='line-height: 40px; font-size: 22px'>
          <div style='display: flex'><b>1.</b>&ensp;What jobs will robots do in the future?</div> #<br>
          <div style='display: flex'><b>2.</b>&ensp;Does Lam like robots? Why / Why not?</div>#<br>
          <div style='display: flex'><b>3.</b>&ensp;Will there be lots of people on Earth?</div>#<br>
          <div style='display: flex'><b>4.</b>&ensp;Will there be any traffic?</div>#<br>
          <div style='display: flex'><b>5.</b>&ensp;What will happen to the weather?</div>#<br>
          <div style='display: flex'><b>6.</b>&ensp;What will happen to the polar bears?</div>#<br>
          
        </div>
      
        </div>
        
        `,
        // initialValue: ["math", "", "", "", "", "", "", ""],
        answer: [
          "They will do dangerous jobs.|They will do all the dangerous jobs and help in the house.",
          "Yes, he does because they will help him with his homework.|Yes, he does because they will help him with his homework.",
          "Yes. Earth will be crowded.|Yes, there will.",
          "Yes. There will be lots of traffic.|Yes, there will.",
          "It will be warmer and there will be more storms.|It will be warmer and there will be more storms.",
          "They won't have homes.|They won't have homes because there won't be any snow.",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 11",
    id: "SB5-2024-U11-P86-E5",
    // audio: "Audios/Page86/audio-e1.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    styleContent: {
      width: "20%",
      marginLeft: 15,
    },
    titleQuestion: [
      {
        num: "5",
        title: "Which do you think life will be like in 50 years?",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page64/E5/1.jpg",
          // audioUrl: "Audios/Page86/candy.mp3",
        },
      ],
    ],
  },
};

export default json;
