import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
const json = {
  1: {
    unit: "Unit 1",
    id: "SB5-2024-U1-P11-E1",
    recorder: true,
    audio: "Audios/Page11/10.mp3",
    // video: "Videos/Unit 01.mp4",
    component: UI,
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen,point, and repeat. <headphone name='10' src='Audios/Page11/10 tieude.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    styleContent: {
      width: "90%",
      marginLeft: 50,
    },
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page11/E1/1.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page11/E1/2.jpg",
          audioUrl: "Audios/Page11/walks.mp3",
        },
        {
          url: "img/FriendsPlus/Page11/E1/3.jpg",
          audioUrl: "Audios/Page11/sits.mp3",
        },
        {
          url: "img/FriendsPlus/Page11/E1/4.jpg",
          audioUrl: "Audios/Page11/loves.mp3",
        },

        {
          url: "img/FriendsPlus/Page11/E1/5.jpg",
          audioUrl: "Audios/Page11/rides.mp3",
        },
        {
          url: "img/FriendsPlus/Page11/E1/6.jpg",
          audioUrl: "Audios/Page11/brushes.mp3",
        },

        {
          url: "img/FriendsPlus/Page11/E1/7.jpg",
          audioUrl: "Audios/Page11/catches.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 1",
    id: "SB5-2024-U1-P11-E2",
    audio: "Audios/Page11/11.mp3",
    video: "",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and chant. <headphone name='11' src='Audios/Page11/11 tieude.mp3'></headphone>",
        color: "#203c8f",
        left: 50,
      },
    ],
    styleContent: {
      width: "75%",
      marginLeft: 100,
    },
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page11/E2/1.jpg",
        },
      ],
    ],
  },
  3: {
    unit: "Unit 1",
    id: "SB5-2024-U1-P11-E3",
    audio: "",
    video: "",
    recorder: true,
    exerciseKey: "img/FriendsPlus/Page11/Key/E3answerKey.png",
    component: Circle_Write,
    inputSize: 500,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    titleQuestion: [
      {
        num: "3",
        title:
          "Read the chant again. Underline the words that  end with s and es. Then say. ",
        color: "#203c8f",
        width: "25cm",
        left: -50,
        numberImg: ["b", "c"],
      },
    ],
    // hideBtnFooter: true,
    question: {
      // leftContent: 100,
      DrawLines: {
        multipleLine: true,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "1px 4px",
          // border: "2px solid",
          border: "none",
          // borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          // padding: "1px 4px",
          // borderRadius: "50%",
          color: "black",
          border: "none",
          borderBottom: "2px solid #00aeef",
          // borderColor: "#00aeef",
        },
        limitSelect: 6,
        listWords: [
          "Linh brushes her teeth in the morning,",
          "And then she walks to school.",
          "She goes to class, she sits on her chair.",
          "She loves going to school!",
        ],
        answers: ["0-2", "1-6", "2-10", "3-2", "2-2"],
        initialValue: ["0-2"],
      },
      Layout: `
      <div style='position: relative'>
        <img style='height:9cm;margin-top:20px' src='img/FriendsPlus/Page11/E3/1.jpg'/>
        <div style='position: absolute; top: 110px; font-size: 24px; left: 74px; text-Align:center'>
          <div><input id=0 type='Circle' />.</div>
          <div><input id=1 type='Circle' />.</div>
          <div><input id=2 type='Circle' />.</div>
          <div><input id=3 type='Circle' />.</div>
        </div>
      </div>
      
      `,
    },
  },
  4: {
    unit: "Unit 1",
    id: "SB5-2024-U1-P11-E4",
    audio: "Audios/Page11/12.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page11/Key/E4answerKey.png",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["gets", "Vinh", "at", "seven", "o'clock", "dressed", "."],
      },
      {
        src: ["Vinh", "gets", "dressed", "at", "seven", "o'clock."],
        styleElement: { color: "#10adcf", marginRight: 10 },
        marginLeft: 20,
      },
      {
        src: ["Linh", "snack", "Does", "school", "after", "a", "?"],
      },
      {
        src: ["Tuan", "don't", "Mai", "catch", "and", "bus", "the", "."],
      },
      {
        src: ["brush", "at", "teeth", "Do", "their", "night", "they", "?"],
      },
      {
        src: ["Walk", "school", "doesn't", "He", "to", "."],
      },
    ],
    stylesTextInput: { textAlign: "center", width: 50 },
    // hideBtnFooter: true,
    component: T6,
    titleQuestion: [
      {
        num: "4",
        title:
          "Read and chant again. Underline the words that end with s and es. <headphone name='12' src='Audios/Page11/12 tieude.mp3'></headphone>",
        color: "#203c8f",
        numberImg: ["a"],
        left: 50,
      },
    ],
    questions: [
      {
        title: `
              <div style='display:flex; text-align:center'>
                <div>
                  <div>
                    <img style='height:4cm' src='img/FriendsPlus/Page11/E4/1.jpg'/><br>
                    sit#
                  </div>
                  <div>
                    <img style='height:4cm' src='img/FriendsPlus/Page11/E4/2.jpg'/><br>
                    brush#
                  </div>
                </div>
                <div style='margin-left:50px'>
                  <div>
                    <img style='height:4cm' src='img/FriendsPlus/Page11/E4/3.jpg'/><br>
                    walk#
                  </div>
                  <div>
                    <img style='height:4cm' src='img/FriendsPlus/Page11/E4/4.jpg'/><br>
                    love#
                  </div>
                </div>
                <div style='margin-left:50px'>
                  <div>
                    <img style='height:4cm' src='img/FriendsPlus/Page11/E4/5.jpg'/><br>
                    ride#
                  </div>
                  <div>
                    <img style='height:4cm' src='img/FriendsPlus/Page11/E4/6.jpg'/><br>
                    catch#
                  </div>
                </div>
              </div>
        
      `,
        answer: ["s", "es", "s", "s", "s", "es"],
      },
    ],
  },
  5: {
    unit: "Unit 1",
    id: "SB5-2024-U1-P11-E5",
    // audio: "img/FriendsPlus/Page110/Audio/audio-e2-p40.mp3",
    video: "",
    component: UI,
    recorder: true,
    styleContent: {
      width: "35%",
      marginLeft: 350,
    },
    questionImage: [[{ url: "img/FriendsPlus/Page11/E5/1.jpg" }]],
  },
};

export default json;
