import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 1",
    id: "SB5-2024-U1-P8-E1",
    audio: "Audios/Page8/5.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen, point, and repeat. <headphone name='05' src='Audios/Page8/5 tieude.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    styleContent: {
      width: "90%",
      marginLeft: 50,
    },
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page8/E1/1.jpg",
          audioUrl: "Audios/Page8/take a shower.mp3",
        },
        {
          url: "img/FriendsPlus/Page8/E1/2.jpg",
        },
        {
          url: "img/FriendsPlus/Page8/E1/3.jpg",
          audioUrl: "Audios/Page8/brush my teeth.mp3",
        },
        {
          url: "img/FriendsPlus/Page8/E1/4.jpg",
        },
        {
          url: "img/FriendsPlus/Page8/E1/5.jpg",
          audioUrl: "Audios/Page8/get dressed.mp3",
        },
        {
          url: "img/FriendsPlus/Page8/E1/6.jpg",
        },
        {
          url: "img/FriendsPlus/Page8/E1/7.jpg",
          audioUrl: "Audios/Page8/catch the bus.mp3",
        },
        {
          url: "img/FriendsPlus/Page8/E1/8.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page8/E1/9.jpg",
        },
        {
          url: "img/FriendsPlus/Page8/E1/10.jpg",
          audioUrl: "Audios/Page8/walk to school.mp3",
        },
        {
          url: "img/FriendsPlus/Page8/E1/11.jpg",
        },
        {
          url: "img/FriendsPlus/Page8/E1/12.jpg",
          audioUrl: "Audios/Page8/have a snack.mp3",
        },
        {
          url: "img/FriendsPlus/Page8/E1/13.jpg",
        },
        {
          url: "img/FriendsPlus/Page8/E1/14.jpg",
          audioUrl: "Audios/Page8/do my homework.mp3",
        },
        {
          url: "img/FriendsPlus/Page8/E1/15.jpg",
        },
        {
          url: "img/FriendsPlus/Page8/E1/16.jpg",
          audioUrl: "Audios/Page8/ride my bike.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 1",
    id: "SB5-2024-U1-P8-E2",
    audio: "Audios/Page8/6.mp3",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and chant. <headphone name='06' src='Audios/Page8/6 tieude.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    styleContent: {
      width: "90%",
      marginLeft: 50,
    },
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page8/E1/1.jpg",
          audioUrl: "Audios/Page8/take a shower.mp3",
        },
        {
          url: "img/FriendsPlus/Page8/E1/2.jpg",
        },
        {
          url: "img/FriendsPlus/Page8/E1/3.jpg",
          audioUrl: "Audios/Page8/brush my teeth.mp3",
        },
        {
          url: "img/FriendsPlus/Page8/E1/4.jpg",
        },
        {
          url: "img/FriendsPlus/Page8/E1/5.jpg",
          audioUrl: "Audios/Page8/get dressed.mp3",
        },
        {
          url: "img/FriendsPlus/Page8/E1/6.jpg",
        },
        {
          url: "img/FriendsPlus/Page8/E1/7.jpg",
          audioUrl: "Audios/Page8/catch the bus.mp3",
        },
        {
          url: "img/FriendsPlus/Page8/E1/8.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page8/E1/9.jpg",
        },
        {
          url: "img/FriendsPlus/Page8/E1/10.jpg",
          audioUrl: "Audios/Page8/walk to school.mp3",
        },
        {
          url: "img/FriendsPlus/Page8/E1/11.jpg",
        },
        {
          url: "img/FriendsPlus/Page8/E1/12.jpg",
          audioUrl: "Audios/Page8/have a snack.mp3",
        },
        {
          url: "img/FriendsPlus/Page8/E1/13.jpg",
        },
        {
          url: "img/FriendsPlus/Page8/E1/14.jpg",
          audioUrl: "Audios/Page8/do my homework.mp3",
        },
        {
          url: "img/FriendsPlus/Page8/E1/15.jpg",
        },
        {
          url: "img/FriendsPlus/Page8/E1/16.jpg",
          audioUrl: "Audios/Page8/ride my bike.mp3",
        },
      ],
    ],
  },
  3: {
    unit: "Unit 1",
    id: "SB5-2024-U1-P8-E3",
    audio: "Audios/Page8/7.mp3",
    video: "Videos/Unit 1.mp4",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "3",
        title:
          "Listen and read. What does Jim do?<headphone name='07' src='Audios/Page8/7 tieude.mp3'></headphone>",
        color: "#203c8f",
        left: 100,
      },
    ],
    styleContent: {
      width: "70%",
      marginLeft: 150,
    },
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page8/E3/1.jpg",
          audioUrl: "Audios/Page8/7 01.mp3",
        },
        {
          url: "img/FriendsPlus/Page8/E3/2.jpg",
          audioUrl: "Audios/Page8/7 02.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page8/E3/3.jpg",
          audioUrl: "Audios/Page8/7 03.mp3",
        },
        {
          url: "img/FriendsPlus/Page8/E3/4.jpg",
          audioUrl: "Audios/Page8/7 04.mp3",
        },
      ],
    ],
  },
  4: {
    unit: "Unit 1",
    id: "SB5-2024-U1-P8-E4",
    audio: "",
    video: "",

    exerciseKey: "img/FriendsPlus/Page8/Key/E4answerKey.png",
    titleQuestion: [
      {
        num: "4",
        title: "Read again and circle the correct answer. ",
        color: "#203c8f",
        numberImg: ["b"],
        left: 0,
      },
    ],
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "580px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "105px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "105px",
              left: "580px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "171px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 4
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",

          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "a.| <br> b.| <br> c.|",
          "a.| <br> b.| <br> c.|",
          "a.| <br> b.| <br> c.|",
          "a.| <br> b.| <br> c.| ",
          "a.| <br> b.| <br> c.|",
        ],
        answers: ["0-6", "1-12", "4-0", "2-6", "3-0"],
        initialValue: [],
      },
      Layout: `
      <img style='height:10cm' src='img/FriendsPlus/Page8/E3/1.jpg'/>
     <div style='display:flex'>
      <div style='margin-right:50px;'>
       <div><b>1.</b> Mr. Khanh was____________.
        <div style='display:flex'>
          <div><input id='0' type='Circle'/></div>
          <div>a scientist <br> a farmer <br> a tourist</div>
        </div>
       </div>
       <div style='width:10cm'><b>3.</b> When Mr. Khanh found the cave he was____________.
        <div style='display:flex'>
          <div><input id='4' type='Circle'/></div>
          <div>2023 <br> 2009 <br> 1991</div>
        </div>
       </div>
      </div> 
       
      <div> 
       <div style='width:10cm'><b>2.</b> Mr. Khanh first found the cave in____________.
        <div style='display:flex'>
          <div><input id='1' type='Circle'/></div>
          <div>suprised <br> sad <br> scared</div>
        </div>
       </div>
       <div><b>4.</b> The cave is very____________.
        <div style='display:flex'>
          <div><input id='2' type='Circle'/></div>
          <div>old <br> big <br> small</div>
        </div>
       </div>
      </div> 
    </div> 
       <div><b>5.</b> Inside the cave there is____________.
        <div style='display:flex'>
          <div><input id='3' type='Circle'/></div>
          <div>a jungle <br> a farm <br> a museum</div>
        </div>
       </div>
      `,
    },
  },
  5: {
    unit: "Unit 1",
    id: "SB5-2024-U1-P8-E5",
    audio: "",
    video: "",

    component: T6,
    // maxLength: 5,
    // textAlign: "center",
    textareaStyle: {
      width: 700,
      paddingTop: 5,
    },
    inputSize: 720,
    titleQuestion: [
      {
        num: "5",
        title: "Would you like to visit a cave? Why / Why not?",
        color: "#203c8f",
        left: 80,
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    questions: [
      {
        title: `
				<textarea id='0' rows=7 ></textarea>
        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
