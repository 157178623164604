import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import ScoreByColor from "../../components/ExcerciseTypes/Design/ScoreByColor";
const json = {
  1: {
    unit: "Fluency Time! 3",
    id: "SB5-2024-FT3-P75-E1",
    audio: "Audios/Page75/111.mp3",
    // video: "Videos/Unit 08.mp4",
    component: UI,
    recorder: true,
    styleContent: {
      width: "85%",
      marginLeft: 50,
    },
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen, point and repeat. <headphone name='111' src='Audios/Page75/111 tieu-de.mp3'></headphone>",
        color: "#F03D50",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page75/E1/1.jpg",
          audioUrl: "Audios/Page75/spring.mp3",
        },
        {
          url: "img/FriendsPlus/Page75/E1/2.jpg",
        },
        {
          url: "img/FriendsPlus/Page75/E1/3.jpg",
          audioUrl: "Audios/Page75/summer.mp3",
        },
        {
          url: "img/FriendsPlus/Page75/E1/4.jpg",
        },
        {
          url: "img/FriendsPlus/Page75/E1/5.jpg",
          audioUrl: "Audios/Page75/fall.mp3",
        },
        {
          url: "img/FriendsPlus/Page75/E1/6.jpg",
        },
        {
          url: "img/FriendsPlus/Page75/E1/7.jpg",
          audioUrl: "Audios/Page75/winter.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Fluency Time! 3",
    id: "SB5-2024-FT3-P75-E2",
    audio: "Audios/Page75/112.mp3",
    // video: "Videos/Unit 08.mp4",
    component: UI,
    recorder: true,
    styleContent: {
      width: "90%",
      marginLeft: 30,
    },
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and read. <headphone name='112' src='Audios/Page75/112 tieu-de.mp3'></headphone>",
        color: "#F03D50",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page75/E2/1.jpg",
          // audioUrl: "Audios/Page75/hinh1.mp3",
        },
      ],
    ],
  },
  3: {
    unit: "Fluency Time! 3",
    id: "SB5-2024-FT3-P75-E3",
    // audio: "Audios/Page55/80.mp3",
    exerciseKey: "img/FriendsPlus/Page75/Key/E3answerKey.png",
    video: "",
    component: T6,
    // maxLength: 6,
    textAlign: "left",
    inputSize: 500,
    stylesTextInput: {
      background: "none",
      borderBottom: "1.5px solid",
      // fontSize: 22,
      paddingBottom: 3,
      height: 40,
      // textTransform: "lowercase",
    },
    // hintBox: [
    //   {
    //     src: ["English", "math", "P.E", "art", "Vietnamese"],
    //     width: 582,
    //   },
    // ],
    titleQuestion: [
      {
        num: "3",
        title: "Write three words in each space.",
        color: "#F03D50",
        numberImg: ["a"],
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img style='height:12cm' src='img/FriendsPlus/Page75/E2/1.jpg'/>
        <div style='line-height: 45px'>
          <div style='display: flex'><b>1.</b>&ensp;There are four seasons every year in <u style='color:rgb(54,166,215);'>&ensp;lots of countries&ensp;</u>.</div>
          <div style='display: flex'><b>2.</b>&ensp;The warm spring rain helps # .</div>
          <div style='display: flex'><b>3.</b>&ensp;In summer, we can see # .</div>
          <div style='display: flex'><b>4.</b>&ensp;Fall leaves are usually # .</div>
          <div style='display: flex'><b>5.</b>&ensp;In winter, many trees # .</div>
        </div>
        
        `,
        // initialValue: ["math", "", "", "", "", "", "", ""],
        answer: [
          "plants to grow",
          "fruit and flowers",
          "red and orange",
          "don't have leaves",
        ],
      },
    ],
  },
  4: {
    unit: "Fluency Time! 3",
    id: "SB5-2024-FT3-P75-E4",
    // audio: "Audios/Page75/audio-e2.mp3",
    // video: "Videos/Unit 08.mp4",
    component: UI,
    recorder: true,
    styleContent: {
      width: "90%",
      marginLeft: 30,
    },
    titleQuestion: [
      {
        num: "4",
        title:
          "<div style='display: flex'><img style='height:1.2cm' src='img/FriendsPlus/Page75/E4/2.jpg'/> <div>What's your favorite season? Draw and label a picture and tell your friends.</div></div>",
        color: "#F03D50",
        numberImg: ["a", "c"],
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page75/E4/1.jpg",
          // audioUrl: "Audios/Page75/hinh1.mp3",
        },
      ],
    ],
  },
  5: {
    unit: "Fluency Time! 3",
    id: "SB5-2024-FT3-P75-E5",
    // audio: "Audios/Page29/E6/audio-e4.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page29/E6/Key/answerKey.png",
    component: Circle_Write,
    inputSize: 500,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    titleQuestion: [
      {
        num: "5",
        title: "Look and color.",
        color: "#F03D50",
        // numberImg: ["a", "c"],
      },
    ],
    hideBtnFooter: true,
    question: {
      ThSmile: true,
      // leftContent: 100,
      DrawLines: {
        multipleLine: true,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "8px 18px",
          border: "2px solid",
          color: "transparent",
          marginLeft: 10,
          // background: "gray",
          borderRadius: "50%",
          borderColor: "gray",
          fontWeight: "bold",
          backgroundColor: "white",
        },
        selectWordStyle: {
          fontWeight: "bold",
          width: 10,
          borderRadius: "50%",
          background: "#90DAF7",
          color: "transparent",
          border: "2px solid",
          borderColor: "#90DAF7",
          // backgroundColor: "white",
        },
        limitSelect: 3,
        listWords: ["a", "a a", "a a a"],
        answers: [""],
        initialValue: [],
      },
      Layout: `
      <div style='border:3px solid rgb(240,61,80);padding:10px 20px;box-shadow:4px 2px 3px gray;border-radius:14px'>
        <span style='color:rgb(240,61,80); font-weight:bold;font-size:30px;margin-left:10px'>
          My work in Units 7-9 & Fluency Time! 3 is
        </span>
          <div style='display:flex;margin-top:30px'>
            <div>
              <input id=0 select='Tick' type='Circle'/> 
              <span style='color:rgb(240,61,80); font-weight:bold;font-size:30px;margin-left:10px'>OK</span>
            </div>
            <div style='margin:0px 100px'>
              <input id=1 select='Tick' type='Circle'/> 
              <span style='color:rgb(240,61,80); font-weight:bold;font-size:30px;margin-left:10px'>GOOD</span>
            </div>
            <div>
              <input id=2 select='Tick' type='Circle'/> 
              <span style='color:rgb(240,61,80); font-weight:bold;font-size:30px;margin-left:10px'>EXCELLENT</span>
            </div>
          </div>
      </div>
        
      `,
    },
  },
};

export default json;
