import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Select_Image from "../../components/ExcerciseTypes/Select/Select_Image";
const json = {
  1: {
    unit: "Unit 7",
    id: "SB5-2024-U7-P57-E1",
    audio: "Audios/Page57/82.mp3",
    video: "",
    component: UI,
    recorder: true,
    styleContent: {
      width: "80%",
      marginLeft: 70,
    },
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen, point, and repeat. <headphone name='82' src='Audios/Page57/82 tieu-de.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page57/E1/1.jpg",
          audioUrl: "Audios/Page57/82 01.mp3",
        },
        {
          url: "img/FriendsPlus/Page57/E1/2.jpg",
          audioUrl: "Audios/Page57/82 02.mp3",
        },
        { url: "img/FriendsPlus/Page57/E1/3.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page57/E1/4.jpg" },
        {
          url: "img/FriendsPlus/Page57/E1/5.jpg",
          audioUrl: "Audios/Page57/82 03.mp3",
        },
        {
          url: "img/FriendsPlus/Page57/E1/6.jpg",
          audioUrl: "Audios/Page57/82 04.mp3",
        },
      ],
      [
        { url: "img/FriendsPlus/Page57/E1/7.jpg" },
        {
          url: "img/FriendsPlus/Page57/E1/8.jpg",
          audioUrl: "Audios/Page57/82 05.mp3",
        },
        {
          url: "img/FriendsPlus/Page57/E1/9.jpg",
          audioUrl: "Audios/Page57/82 06.mp3",
        },
        { url: "img/FriendsPlus/Page57/E1/10.jpg" },
      ],
    ],
  },
  2: {
    unit: "Unit 7",
    id: "SB5-2024-U7-P57-E2",
    audio: "Audios/Page57/83.mp3",
    video: "",
    recorder: true,
    component: UI,
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and chant. <headphone name='83' src='Audios/Page57/83 tieu-de.mp3'></headphone>",
        color: "#203c8f",
        left: 100,
      },
    ],
    styleContent: {
      width: "70%",
      marginLeft: 160,
    },
    questionImage: [[{ url: "img/FriendsPlus/Page57/E2/1.jpg" }]],
  },
  3: {
    unit: "Unit 7",
    id: "SB5-2024-U7-P57-E3",
    audio: "",
    video: "",
    component: UI,
    recorder: true,

    titleQuestion: [
      {
        num: "3",
        title: "Read the chant again. Follow the rhythm. Then say.",
        color: "#203c8f",
        numberImg: ["c"],
        left: 100,
      },
    ],
    styleContent: {
      width: "70%",
      marginLeft: 160,
    },
    questionImage: [[{ url: "img/FriendsPlus/Page57/E2/1.jpg" }]],
  },
  4: {
    unit: "Unit 7",
    id: "SB5-2024-U7-P57-E4",
    audio: "",
    video: "",
    titleQuestion: [
      {
        num: "4",
        title:
          "Say the sentences. Draw arrows. <img style='height:1cm' src='img/FriendsPlus/ListPage/c.jpg'/>",
        color: "#203c8f",
        numberImg: ["b"],
        left: -30,
      },
    ],
    component: Select_Image,
    exerciseKey: "img/FriendsPlus/Page57/key/E4answerKey.png",

    selectStyle: {
      height: "1cm",
    },
    styleImgSelect: [
      { height: 40, width: 247 },
      { height: 40, width: 150 },
      { height: 40, width: 380 },
      { height: 40, width: 175 },
      { height: 40, width: 315 },
      { height: 40, width: 130 },
      { height: 40, width: 235 },
      { height: 40, width: 125 },
    ],
    selectGroupOption: [
      [
        `img/FriendsPlus/Page57/E4/z1.jpg`,
        `img/FriendsPlus/Page57/E4/z1-1.jpg`,
      ],
      [
        `img/FriendsPlus/Page57/E4/z2.jpg`,
        `img/FriendsPlus/Page57/E4/z2-1.jpg`,
      ],
      [
        `img/FriendsPlus/Page57/E4/z3.jpg`,
        `img/FriendsPlus/Page57/E4/z3-1.jpg`,
      ],
      [
        `img/FriendsPlus/Page57/E4/z4.jpg`,
        `img/FriendsPlus/Page57/E4/z4-1.jpg`,
      ],
      [
        `img/FriendsPlus/Page57/E4/z5.jpg`,
        `img/FriendsPlus/Page57/E4/z5-1.jpg`,
      ],
      [
        `img/FriendsPlus/Page57/E4/z6.jpg`,
        `img/FriendsPlus/Page57/E4/z6-1.jpg`,
      ],
      [
        `img/FriendsPlus/Page57/E4/z7.jpg`,
        `img/FriendsPlus/Page57/E4/z7-1.jpg`,
      ],
      [
        `img/FriendsPlus/Page57/E4/z8.jpg`,
        `img/FriendsPlus/Page57/E4/z8-1.jpg`,
      ],
    ],
    questionImage: [],
    questions: [
      {
        title: `
          <div style='position: relative;'>
            <div><img src='img/FriendsPlus/Page57/E4/a.jpg' alt='' style='height:11cm'/></div>
            <div style='position: absolute; top: 76px; left: 40px;'> <select id='0' group='0'  width='245' height='40'></select></div>
            <div style='position: absolute; top: 76px; left: 300px;'><select id='1' group='1'  width='150' height='40'></select></div>
            <div style='position: absolute; top: 159px; left: 40px;'><select id='2' group='2'  width='380' height='40'></select></div>
            <div style='position: absolute; top: 159px; left:435px;'><select id='3' group='3'  width='175' height='40'></select></div>
            <div style='position: absolute; top: 242px; left: 40px;'><select id='4' group='4'  width='315' height='40'></select></div>
            <div style='position: absolute; top: 242px; left:365px;'><select id='5' group='5'  width='135' height='40'></select></div>
            <div style='position: absolute; top: 325px; left: 40px;'><select id='6' group='6'  width='237' height='40'></select></div>
            <div style='position: absolute; top: 325px; left:290px;'><select id='7' group='7'  width='130' height='40'></select></div>

            
          </div>
        `,
        answer: [
          `img/FriendsPlus/Page57/E4/z1.jpg`,
          `img/FriendsPlus/Page57/E4/z2.jpg`,
          `img/FriendsPlus/Page57/E4/z3.jpg`,
          `img/FriendsPlus/Page57/E4/z4.jpg`,
          `img/FriendsPlus/Page57/E4/z5.jpg`,
          `img/FriendsPlus/Page57/E4/z6.jpg`,
          `img/FriendsPlus/Page57/E4/z7.jpg`,
          `img/FriendsPlus/Page57/E4/z8.jpg`,
        ],
      },
    ],
  },
  5: {
    unit: "Unit 7",
    id: "SB5-2024-U7-P57-E5",
    audio: "",
    video: "",
    component: UI,
    recorder: true,

    styleContent: {
      width: "35%",
      marginLeft: 350,
    },
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page57/E5/1.jpg",
        },
      ],
    ],
  },
};

export default json;
