import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 12",
    id: "SB5-2024-U12-P90-E1",
    // audio: "Audios/Page72/audio-e1.mp3",
    exerciseKey: "img/FriendsPlus/Page90/Key/E1answerKey.png",
    component: Circle_Write,
    inputSize: 500,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    titleQuestion: [
      {
        num: "1",
        title: "Read. Check (✓) the true sentences for you.",
        color: "#203c8f",
        width: "25cm",
        numberImg: ["b"],
      },
    ],
    hideBtnFooter: true,
    question: {
      // leftContent: 100,
      DrawLines: {
        multipleLine: true,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        Tick: true,
        initialWordStyle: {
          // padding: "1px 8px",
          // border: "2px solid gray",
          // borderRadius: "25%",
          fontWeight: "bold",
          color: "transparent",
        },
        selectWordStyle: {
          fontWeight: "bold",
          // padding: "1px 8px",
          color: "#00aeef",
          // border: "2px solid gray",
          // borderRadius: "25%",
          // borderBottom: "2px solid #00aeef",
          // borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: ["✓", "✓", "✓", "✓"],
        answers: [""],
        initialValue: [""],
      },
      Layout: `
    <div style='position: relative; min-height: 20cm'>

    <img style='height:10cm; position: absolute; z-index:0; top: 50px; left: 20px' src='img/FriendsPlus/Page90/E1/1.jpg'/>
    <div style='position: absolute; z-index: 1; top: 172px; left: 207px'>
      <input id=0 type='Circle' />
    </div>
    <div style='position: absolute; z-index: 1; top: 162px; left: 453px'>
      <input id=1 type='Circle' />
    </div>
    <div style='position: absolute; z-index: 1; top: 182px; left: 693px'>
      <input id=2 type='Circle' />
    </div>
    <div style='position: absolute; z-index: 1; top: 343px; left: 925px'>
      <input id=3 type='Circle' />
    </div>
    </div>
      
      `,
    },
  },
  2: {
    unit: "Unit 12",
    id: "SB5-2024-U12-P90-E2",
    audio: "Audios/Page90/audio-e2.mp3",
    video: "",
    component: UI,
    recorder: true,
    styleContent: {
      width: "90%",
      // marginTop: 300,
    },
    question: [],
    titleQuestion: [
      {
        num: "2",
        title: "Talk to a friend.",
        color: "#203c8f",
        numberImg: ["c"],
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page90/E2/1.jpg" }]],
  },
  3: {
    unit: "Unit 12",
    id: "SB5-2024-U12-P90-E3",
    // audio: "Audios/Page55/80.mp3",
    exerciseKey: "img/FriendsPlus/Page90/Key/E3answerKey.png",
    video: "",
    component: T6,
    // maxLength: 6,
    textAlign: "left",
    inputSize: 800,
    stylesTextInput: {
      background: "none",
      borderBottom: "1.5px solid",
      fontSize: 22,
      paddingBottom: 3,
      height: 40,
      // textTransform: "lowercase",
    },
    // hintBox: [
    //   {
    //     src: ["English", "math", "P.E", "art", "Vietnamese"],
    //     width: 582,
    //   },
    // ],
    titleQuestion: [
      {
        num: "3",
        title: "Write three sentences about your friend's answers.",
        color: "#203c8f",
        numberImg: ["a"],
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='line-height: 50px'>
          <div style='display: flex'><span style='color:rgb(54,166,215);'>Tuan Anh isn't going to study a lot. He's going to ...</span></div>
          <div style='display: flex'><b>1.</b>&ensp;#</div>
          <div style='display: flex'><b>2.</b>&ensp;#</div>
          <div style='display: flex'><b>3.</b>&ensp;#</div>
        </div>
      
        `,
        // initialValue: ["math", "", "", "", "", "", "", ""],
        answer: [],
      },
    ],
  },
  4: {
    unit: "Unit 12",
    id: "SB5-2024-U12-P90-E4",
    audio: "Audios/Page90/137.mp3",
    // video: "Videos/Come and play with me.mp4",
    component: UI,
    recorder: true,
    question: [],
    styleContent: {
      width: "85%",
      marginLeft: 100,
    },
    titleQuestion: [
      {
        num: "4",
        title:
          "Listen and sing. <headphone name='137' src='Audios/Page90/137 tieu-de.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page90/E4/1.jpg",
          // audioUrl: "Audios/Page90/candy.mp3",
        },
      ],
    ],
  },
  5: {
    unit: "Unit 12",
    id: "SB5-2024-U12-P90-E5",
    // audio: "Audios/Page90/137.mp3",
    video: "Videos/Are you going to play with me.mp4",
    component: UI,
    recorder: true,
    question: [],
    styleContent: {
      width: "85%",
      marginLeft: 100,
    },
    titleQuestion: [
      {
        num: "5",
        title: "Sing and do.",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page90/E4/1.jpg",
          // audioUrl: "Audios/Page90/candy.mp3",
        },
      ],
    ],
  },
};

export default json;
