import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 8",
    id: "SB5-2024-U8-P63-E1",
    audio: "Audios/Page63/92.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    styleContent: {
      width: "70%",
      marginLeft: 100,
    },
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen, point and repeat. <headphone name='92' src='Audios/Page63/92 tieu-de.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page63/E1/1.jpg",
          audioUrl: "Audios/Page63/larger than.mp3",
        },
        {
          url: "img/FriendsPlus/Page63/E1/2.jpg",
        },
        {
          url: "img/FriendsPlus/Page63/E1/3.jpg",
          audioUrl: "Audios/Page63/longer than.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page63/E1/4.jpg",
          audioUrl: "Audios/Page63/smaller than.mp3",
        },
        {
          url: "img/FriendsPlus/Page63/E1/5.jpg",
        },
        {
          url: "img/FriendsPlus/Page63/E1/6.jpg",
          audioUrl: "Audios/Page63/slower than.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page63/E1/7.jpg",
          audioUrl: "Audios/Page63/wider than.mp3",
        },
        {
          url: "img/FriendsPlus/Page63/E1/8.jpg",
        },
        {
          url: "img/FriendsPlus/Page63/E1/9.jpg",
          audioUrl: "Audios/Page63/deeper than.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 8",
    id: "SB5-2024-U8-P63-E2",
    audio: "Audios/Page63/93.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    styleContent: {
      width: "90%",
      marginLeft: 50,
    },
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and chant. <headphone name='93' src='Audios/Page63/93 tieu-de.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page63/E2/1.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page63/E2/2.jpg",
          // audioUrl: "Audios/Page63/candy.mp3",
        },
        {
          url: "img/FriendsPlus/Page63/E2/3.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page63/E2/4.jpg",
          // audioUrl: "Audios/Page63/candy.mp3",
        },
        {
          url: "img/FriendsPlus/Page63/E2/5.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page63/E2/6.jpg",
          // audioUrl: "Audios/Page63/candy.mp3",
        },
        {
          url: "img/FriendsPlus/Page63/E2/7.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page63/E2/8.jpg",
          // audioUrl: "Audios/Page63/candy.mp3",
        },
        {
          url: "img/FriendsPlus/Page63/E2/9.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page63/E2/10.jpg",
        },
      ],
    ],
  },
  3: {
    unit: "Unit 8",
    id: "SB5-2024-U8-P63-E3",
    // audio: "Audios/3-07.mp3",
    exerciseKey: "img/FriendsPlus/Page63/Key/E3answerKey.png",
    component: Circle_Write,
    inputSize: 500,
    textAlign: "center",
    recorder: true,
    stylesTextInput: { width: 170, paddingTop: 10 },
    titleQuestion: [
      {
        num: "3",
        title:
          "Read the chant again. Underline the words that sound stronger. Then say. <img style='height:1cm; width: 2cm; margin-bottom: 5px' src='img/FriendsPlus/IconTitle/1.jpg'/> <img style='height:1cm; width: 1.2cm; margin-bottom: 5px' src='img/FriendsPlus/IconTitle/2.jpg'/>",
        color: "#203c8f",
        width: "25cm",
        // numberImg: ["a"],
      },
    ],
    // hideBtnFooter: true,
    question: {
      // leftContent: 100,
      DrawLines: {
        multipleLine: true,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "1px 4px",
          // border: "2px solid",
          border: "none",
          // borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          // padding: "1px 4px",
          // borderRadius: "50%",
          color: "black",
          border: "none",
          borderBottom: "2px solid #00aeef",
          // borderColor: "#00aeef",
        },
        limitSelect: 6,
        listWords: [
          "It's deeper than a swimming_pool",
          "It's wider than a plane",
          "It's longer than the road to school",
          "What is it? What's the name",
        ],
        answers: ["0-2", "0-8", "1-2", "1-8", "2-2", "2-8", "2-12"],
        initialValue: [],
      },
      Layout: `
      <div style='position: relative'>
      <img style='height:11cm;margin-top:20px' src='img/FriendsPlus/Page63/E3/1.jpg'/>
      
      <div style='position: absolute; top: 139px; font-size: 24px; left: 31px'>
        <div><input id=0 type='Circle' />.</div>
        <div><input id=1 type='Circle' />.</div>
        <div><input id=2 type='Circle' />.</div>
        <div><input id=3 type='Circle' />.</div>
        
      </div>
      
      </div>
      
      `,
    },
  },
  4: {
    unit: "Unit 8",
    id: "SB5-2024-U8-P63-E4",
    // audio: "Audios/3-07.mp3",
    exerciseKey: "img/FriendsPlus/Page63/Key/E4answerKey.png",
    component: Circle_Write,
    inputSize: 500,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    titleQuestion: [
      {
        num: "4",
        title:
          "Say the sentences. Underline the words that sound stronger. <img style='height:1cm; width: 1.2cm; margin-bottom: 5px' src='img/FriendsPlus/IconTitle/2.jpg'/><img style='height:1cm; width: 2cm; margin-bottom: 5px' src='img/FriendsPlus/IconTitle/1.jpg'/>",
        color: "#203c8f",
        width: "25cm",
        // numberImg: ["a"],
      },
    ],
    // hideBtnFooter: true,
    recorder: true,
    question: {
      // leftContent: 100,
      DrawLines: {
        multipleLine: true,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "1px 4px",
          // border: "2px solid",
          border: "none",
          // borderColor: "transparent",
          // fontWeight: "bold",
        },
        selectWordStyle: {
          // fontWeight: "bold",
          // padding: "1px 4px",
          // borderRadius: "50%",
          color: "black",
          border: "none",
          borderBottom: "2px solid #00aeef",
          // borderColor: "#00aeef",
        },
        limitSelect: 7,
        listWords: [
          "A dinosaur is larger than an elephant",
          "Planes are faster than trains",
          "An ocean is wider than a river",
          "The Dong_Nai_river is longer than the Huong_river",
          "A mouse is smaller than a cat",
          "Ba_Be_lake is larger than Tri_An_lake",
        ],
        answers: [
          "0-2",
          "0-12",
          "0-6",
          "1-0",
          "1-4",
          "1-8",
          "2-2",
          "2-6",
          "2-12",
          "3-2",
          "3-6",
          "3-12",
          "4-2",
          "4-6",
          "4-12",
          "5-0",
          "5-4",
          "5-8",
        ],
        initialValue: ["0-2", "0-12", "0-6"],
      },
      Layout: `
     
        <div style='line-height: 50px; margin-left: 50px'>
          <div><b>1</b>&ensp;<input id=0 type='Circle' />.</div>
          <div><b>2</b>&ensp;<input id=1 type='Circle' />.</div>
          <div><b>3</b>&ensp;<input id=2 type='Circle' />.</div>
          <div><b>4</b>&ensp;<input id=3 type='Circle' />.</div>
          <div><b>5</b>&ensp;<input id=4 type='Circle' />.</div>
          <div><b>6</b>&ensp;<input id=5 type='Circle' />.</div>
        </div>
        
     
      
      `,
    },
  },
  5: {
    unit: "Unit 8",
    id: "SB5-2024-U8-P63-E5",
    // audio: "img/FriendsPlus/Page110/Audio/audio-e2-p40.mp3",
    video: "",
    component: UI,
    recorder: true,
    styleContent: {
      width: "35%",
      marginLeft: 350,
    },
    questionImage: [[{ url: "img/FriendsPlus/Page63/E4/1.jpg" }]],
  },
};

export default json;
