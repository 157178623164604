import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  3: {
    unit: "Review 2",
    id: "SB5-2024-R2-P49-E3",
    audio: "",
    video: "",
    recorder: true,
    exerciseKey: "img/FriendsPlus/Page49/Key/E3answerKey.png",
    hintBox: [
      {
        src: [
          "used",
          "watched",
          "brushed",
          "added",
          "walked",
          "ended",
          "started",
          "loved",
          "played",
        ],
        width: 1123,
      },
    ],
    // maxLength: 1,
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      textAlign: "center",
      width: 300,
    },
    // isHello: true,
    // KeyMau: true,
    component: T6,
    titleQuestion: [
      {
        num: "3",
        title:
          "Say the words that end with <i>ed</i>. Then write them in the correct column.",
        color: "#203c8f",
        numberImg: ["c", "a"],
        left: 50,
      },
    ],
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='position:relative;margin-left:20px'>
          <img style='height:5cm' src='img/FriendsPlus/Page49/E3/1.jpg'/>
          <div style='position:absolute;top:49px;left:29px'>#</div>
          <div style='position:absolute;top:90px;left:29px'>#</div>
          <div style='position:absolute;top:130px;left:29px'>#</div>
          <div style='position:absolute;top:49px;left:378px'>#</div>
          <div style='position:absolute;top:90px;left:378px'>#</div>
          <div style='position:absolute;top:130px;left:378px'>#</div>
          <div style='position:absolute;top:49px;left:733px'>#</div>
          <div style='position:absolute;top:90px;left:733px'>#</div>
          <div style='position:absolute;top:130px;left:733px'>#</div>

          
        </div>
      `,
        answer: [
          "watched",
          "brushed",
          "walked",
          "used",
          "loved",
          "played",
          "added",
          "ended",
          "started",
        ],
      },
    ],
  },
  4: {
    unit: "Review 2",
    id: "SB5-2024-R2-P49-E4",
    exerciseKey: "img/FriendsPlus/Page49/Key/E4answerKey.png",
    video: "",
    recorder: true,
    hintBox: [
      {
        src: [
          "get up",
          "brush my teeth",
          "start school",
          "finish school",
          "do homework",
          "take a shower",
        ],
        width: 1119,
      },
    ],
    stylesTextInput: { textAlign: "center", width: 200 },
    hideBtnFooter: true,
    component: T6,
    titleQuestion: [
      {
        num: "4",
        title: "Talk about transportation in your country.",
        color: "#203c8f",
        numberImg: ["c"],
        left: 50,
      },
    ],
    questions: [
      {
        title: `
        <img style='height:11cm' src='img/FriendsPlus/Page49/E4/1.jpg'/>
        
      `,
        answer: [
          "on Saturdays",
          "chicken rice",
          "two cucumbers",
          "helps Mom",
          "always delicious",
        ],
      },
    ],
  },
  5: {
    unit: "Review 2",
    id: "SB5-2024-R2-P49-E5",
    exerciseKey: "img/FriendsPlus/Page49/Key/E5answerKey.png",
    video: "",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
    },
    recorder: true,
    hintBox: [
      {
        src: [
          "In the evening, always eat dinner dinner with my family. My mom get home at ...",
        ],
        styleElement: { color: "#10adcf", marginRight: 10 },
        marginLeft: 20,
      },
    ],
    inputSize: 700,
    textareaStyle: {
      textAlign: "center",
      paddingTop: 3,
      resize: "none",
      width: 900,
    },
    hideBtnFooter: true,
    component: T6,
    titleQuestion: [
      {
        num: "5",
        title: "Look at the picture and answer the questions.",
        color: "#203c8f",
        width: 1000,
        numberImg: ["c"],
        left: 50,
      },
    ],
    questions: [
      {
        title: `
        <img style='height:7cm' src='img/FriendsPlus/Page49/E5/1.jpg'/>
        <div>
          <b>1</b>&ensp;Where did the ancient Egyptians live?<br>
          <b>2</b>&ensp;How did they travel?<br>
          <b>3</b>&ensp;What special buildings did they have?<br>
          <b>4</b>&ensp;What did they use to build pyramids?<br>
        </div>
      `,
        answer: [
          "on Saturdays",
          "chicken rice",
          "two cucumbers",
          "helps Mom",
          "always delicious",
        ],
      },
    ],
  },
};

export default json;
