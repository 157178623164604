import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 2",
    id: "SB5-2024-U2-P18-E1",
    audio: "Audios/Page18/23.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen, point, and repeat. <headphone name='23' src='Audios/Page18/23 tieude.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    styleContent: {
      width: "90%",
      marginLeft: 50,
    },
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page18/E1/1.jpg",
          audioUrl: "Audios/Page18/concert.mp3",
        },
        {
          url: "img/FriendsPlus/Page18/E1/2.jpg",
        },
        {
          url: "img/FriendsPlus/Page18/E1/3.jpg",
          audioUrl: "Audios/Page18/actor.mp3",
        },
        {
          url: "img/FriendsPlus/Page18/E1/4.jpg",
        },
        {
          url: "img/FriendsPlus/Page18/E1/5.jpg",
          audioUrl: "Audios/Page18/movie.mp3",
        },
        {
          url: "img/FriendsPlus/Page18/E1/6.jpg",
        },
        {
          url: "img/FriendsPlus/Page18/E1/7.jpg",
          audioUrl: "Audios/Page18/character.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 2",
    id: "SB5-2024-U2-P18-E2",
    // audio: "Audios/Page18/E2/audio-e2.mp3",
    video: "",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["gets", "Vinh", "at", "seven", "o'clock", "dressed", "."],
      },
      {
        src: ["Vinh", "gets", "dressed", "at", "seven", "o'clock."],
        styleElement: { color: "#10adcf", marginRight: 10 },
        marginLeft: 20,
      },
      {
        src: ["Linh", "snack", "Does", "school", "after", "a", "?"],
      },
      {
        src: ["Tuan", "don't", "Mai", "catch", "and", "bus", "the", "."],
      },
      {
        src: ["brush", "at", "teeth", "Do", "their", "night", "they", "?"],
      },
      {
        src: ["Walk", "school", "doesn't", "He", "to", "."],
      },
    ],
    stylesTextInput: { width: 550 },
    hideBtnFooter: true,
    component: T6,
    titleQuestion: [
      {
        num: "2",
        title: "Look at the text. What country are the children from? ",
        color: "#203c8f",
        numberImg: ["a"],
        left: 50,
      },
    ],
    questions: [
      {
        title: `
        <img style='height:14cm' src='img/FriendsPlus/Page18/E3/1.jpg'/><br>
                  <div>
                  What country are the children from?<br>#
                  </div>
                  
        
      `,
        answer: ["s", "es", "s", "s", "s", "es"],
      },
    ],
  },
  3: {
    unit: "Unit 2",
    id: "SB5-2024-U2-P18-E1",
    audio: "Audios/Page18/24.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "3",
        title:
          "Listen and Read <headphone name='24' src='Audios/Page18/24 tieude.mp3'></headphone>",
        color: "#203c8f",
        left: 100,
      },
    ],
    styleContent: {
      width: "80%",
      marginLeft: 150,
    },
    questionImage: [[{ url: "img/FriendsPlus/Page18/E3/1.jpg" }]],
  },
  4: {
    unit: "Unit 2",
    id: "SB5-2024-U2-P18-E4",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page18/Key/E4answerKey.png",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["gets", "Vinh", "at", "seven", "o'clock", "dressed", "."],
      },
      {
        src: ["Vinh", "gets", "dressed", "at", "seven", "o'clock."],
        styleElement: { color: "#10adcf", marginRight: 10 },
        marginLeft: 20,
      },
      {
        src: ["Linh", "snack", "Does", "school", "after", "a", "?"],
      },
      {
        src: ["Tuan", "don't", "Mai", "catch", "and", "bus", "the", "."],
      },
      {
        src: ["brush", "at", "teeth", "Do", "their", "night", "they", "?"],
      },
      {
        src: ["Walk", "school", "doesn't", "He", "to", "."],
      },
    ],
    stylesTextInput: { width: 600 },
    // hideBtnFooter: true,
    component: T6,
    titleQuestion: [
      {
        num: "4",
        title: "Read again and answer the questions.",
        color: "#203c8f",
        numberImg: ["b"],
        left: 0,
      },
    ],
    questions: [
      {
        title: `
        <img style='height:15cm' src='img/FriendsPlus/Page18/E3/1.jpg'/><br>
              <div>
                <b>1</b>&ensp;Who do Hana and Reiku visit?<br>#<br>
                <b>2</b>&ensp;What do they find?<br>#<br>
                <b>3</b>&ensp;What's in the room?<br>#<br>
                <b>4</b>&ensp;Are Hana and Reiku scared of the dragon?<br>#<br>
                <b>5</b>&ensp;Is the movie sad?<br>#<br>
              </div>
        
      `,
        answer: [
          "their uncle|They visit their uncle.",
          "an old door|They find an old door.",
          "There's a small, friendly dragon.|A small, friendly dragon is in the room.",
          "No, they aren't.|No, they are not.",
          "No, it isn't.|No, it is not.",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 2",
    id: "SB5-2024-U2-P18-E5",
    audio: "",
    video: "",
    recorder: true,
    exerciseKey: "img/FriendsPlus/Page18/Key/E5answerKey.png",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["gets", "Vinh", "at", "seven", "o'clock", "dressed", "."],
      },
      {
        src: ["Vinh", "gets", "dressed", "at", "seven", "o'clock."],
        styleElement: { color: "#10adcf", marginRight: 10 },
        marginLeft: 20,
      },
      {
        src: ["Linh", "snack", "Does", "school", "after", "a", "?"],
      },
      {
        src: ["Tuan", "don't", "Mai", "catch", "and", "bus", "the", "."],
      },
      {
        src: ["brush", "at", "teeth", "Do", "their", "night", "they", "?"],
      },
      {
        src: ["Walk", "school", "doesn't", "He", "to", "."],
      },
    ],
    textareaStyle: { paddingTop: 3, width: 650 },
    hideBtnFooter: true,
    component: T6,
    titleQuestion: [
      {
        num: "5",
        title: "What kinds of movie do you like watching?",
        color: "#203c8f",
        // numberImg: ["b"],
        left: 0,
      },
    ],
    questions: [
      {
        title: `
        
      `,
        answer: [""],
      },
    ],
  },
};

export default json;
