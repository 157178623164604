import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
const json = {
  1: {
    unit: "Unit 3",
    id: "SB5-2024-U3-P23-E1",
    recorder: true,
    audio: "Audios/Page23/31.mp3",
    // video: "Videos/Unit 01.mp4",
    component: UI,
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen,point, and repeat. <headphone name='10' src='Audios/Page23/31 tieude.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    styleContent: {
      width: "90%",
      marginLeft: 50,
    },
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page23/E1/1.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page23/E1/2.jpg",
          audioUrl: "Audios/Page23/balloons.mp3",
        },
        {
          url: "img/FriendsPlus/Page23/E1/3.jpg",
          audioUrl: "Audios/Page23/cookies.mp3",
        },
        {
          url: "img/FriendsPlus/Page23/E1/4.jpg",
          audioUrl: "Audios/Page23/hats.mp3",
        },

        {
          url: "img/FriendsPlus/Page23/E1/5.jpg",
          audioUrl: "Audios/Page23/masks.mp3",
        },
        {
          url: "img/FriendsPlus/Page23/E1/6.jpg",
          audioUrl: "Audios/Page23/sandwiches.mp3",
        },

        {
          url: "img/FriendsPlus/Page23/E1/7.jpg",
          audioUrl: "Audios/Page23/boxes.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 3",
    id: "SB5-2024-U3-P23-E2",
    audio: "Audios/Page23/32.mp3",
    video: "",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and chant. <headphone name='32' src='Audios/Page23/32 tieude.mp3'></headphone>",
        color: "#203c8f",
        left: 50,
      },
    ],
    styleContent: {
      width: "75%",
      marginLeft: 100,
    },
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page23/E2/1.jpg",
        },
      ],
    ],
  },
  3: {
    unit: "Unit 3",
    id: "SB5-2024-U3-P23-E3",
    audio: "",
    video: "",
    recorder: true,
    exerciseKey: "img/FriendsPlus/Page23/Key/E3answerKey.png",
    inputSize: 500,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    component: Circle_Write,
    titleQuestion: [
      {
        num: "3",
        title:
          "Read the chant again. Underline the words that  end with s and es. Then say. ",
        color: "#203c8f",
        width: "25cm",
        left: -50,
        numberImg: ["b", "c"],
      },
    ],
    // hideBtnFooter: true,
    question: {
      // leftContent: 100,
      DrawLines: {
        multipleLine: true,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "1px 4px",
          // border: "2px solid",
          border: "none",
          // borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          // padding: "1px 4px",
          // borderRadius: "50%",
          color: "black",
          border: "none",
          borderBottom: "2px solid #00aeef",
          // borderColor: "#00aeef",
        },
        limitSelect: 6,
        listWords: [
          "What's in the boxes|?",
          "What's in there|?",
          "Are there some cakes|?",
          "Or some cookies to share|?",
          "Are there some sandwiches|?",
          "Or some balloons|?",
          "Look|! Masks and hats|!",
          "The party's this afternoon|.",
        ],
        answers: ["0-6", "2-6", "3-4", "4-6", "5-4", "6-4", "6-8"],
        initialValue: ["0-6"],
      },
      Layout: `
      <div style='position: relative'>
        <img style='height:14cm;margin-top:20px' src='img/FriendsPlus/Page23/E3/1.jpg'/>
        <div style='position: absolute; top: 90px; font-size: 23px; left: 61px; text-Align:center'>
          <div><input id=0 type='Circle' /></div>
          <div><input id=1 type='Circle' /></div>
          <div><input id=2 type='Circle' /></div>
          <div><input id=3 type='Circle' /></div>
        </div>
        <div style='position: absolute; top: 253px; font-size: 23px; left: 61px; text-Align:center'>
          <div><input id=4 type='Circle' /></div>
          <div><input id=5 type='Circle' /></div>
          <div><input id=6 type='Circle' /></div>
          <div><input id=7 type='Circle' /></div>
        </div>
      </div>
      
      `,
    },
  },
  4: {
    unit: "Unit 3",
    id: "SB5-2024-U3-P23-E4",
    audio: "",
    video: "",
    recorder: true,
    exerciseKey: "img/FriendsPlus/Page23/Key/E4answerKey.png",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "4",
        title: "Look, match, and say.",
        color: "#203c8f",
        width: "25cm",
        left: -50,
        numberImg: ["b", "c"],
      },
    ],
    // hideBtnFooter: true,
    question: {
      // leftContent: 100,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "119px",
              left: "63px",
              width: "121px",
              height: "65px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "63px",
              left: "571px",
              width: "189px",
              height: "65px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "249px",
              left: "171px",
              width: "119px",
              height: "65px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "280px",
              left: "406px",
              width: "113px",
              height: "65px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "235px",
              left: "633px",
              width: "132px",
              height: "65px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 4
        ],
        answers: ["0-3", "1-2"],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "1px 4px",
          // border: "2px solid",
          border: "none",
          // borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          // padding: "1px 4px",
          // borderRadius: "50%",
          color: "black",
          border: "none",
          borderBottom: "2px solid #00aeef",
          // borderColor: "#00aeef",
        },
        limitSelect: 6,
        listWords: [
          "What's in the boxes|?",
          "What's in there|?",
          "Are there some cakes|?",
          "Or some cookies to share|?",
          "Are there some sandwiches|?",
          "Or some balloons|?",
          "Look|! Masks and hats|!",
          "The party's this afternoon|.",
        ],
        answers: [],
        initialValue: [],
      },
      Layout: `
        <img style='height:8cm;margin-top:20px' src='img/FriendsPlus/Page23/E4/1.jpg'/>
        <input id=0 type='boxMatch'/>
        <input id=1 type='boxMatch'/>
        <input id=2 type='boxMatch'/>
        <input id=3 type='boxMatch'/>
        <input id=4 type='boxMatch'/>
        
      
      `,
    },
  },
  5: {
    unit: "Unit 3",
    id: "SB5-2024-U3-P23-E5",
    // audio: "img/FriendsPlus/Page230/Audio/audio-e2-p40.mp3",
    video: "",
    component: UI,
    recorder: true,
    styleContent: {
      width: "35%",
      marginLeft: 350,
    },
    questionImage: [[{ url: "img/FriendsPlus/Page23/E5/1.jpg" }]],
  },
};

export default json;
