import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 10",
    id: "SB5-2024-U10-P80-E1",
    audio: "Audios/Page80/120.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    styleContent: {
      width: "95%",
      marginLeft: 15,
    },
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen, point and repeat. <headphone name='120' src='Audios/Page80/120 tieu-de.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page80/E1/1.jpg",
          audioUrl: "Audios/Page80/chips.mp3",
        },
        {
          url: "img/FriendsPlus/Page80/E1/2.jpg",
        },
        {
          url: "img/FriendsPlus/Page80/E1/3.jpg",
          audioUrl: "Audios/Page80/sugar.mp3",
        },
        {
          url: "img/FriendsPlus/Page80/E1/4.jpg",
        },
        {
          url: "img/FriendsPlus/Page80/E1/5.jpg",
          audioUrl: "Audios/Page80/exercise.mp3",
        },
        {
          url: "img/FriendsPlus/Page80/E1/6.jpg",
        },
        {
          url: "img/FriendsPlus/Page80/E1/7.jpg",
          audioUrl: "Audios/Page80/healthy.mp3",
        },
        {
          url: "img/FriendsPlus/Page80/E1/8.jpg",
        },
        {
          url: "img/FriendsPlus/Page80/E1/9.jpg",
          audioUrl: "Audios/Page80/strong.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 10",
    id: "SB5-2024-U10-P80-E2",
    // audio: "Audios/Page80/audio-e2.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    styleContent: {
      width: "90%",
      marginLeft: 40,
    },
    titleQuestion: [
      {
        num: "2",
        title: "Look at the text. What can you see in the photos?",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page80/E2/1.jpg",
          // audioUrl: "Audios/Page80/candy.mp3",
        },
      ],
    ],
  },
  3: {
    unit: "Unit 10",
    id: "SB5-2024-U10-P80-E3",
    audio: "Audios/Page80/121.mp3",
    exerciseKey: "img/FriendsPlus/Page80/Key/E3answerKey.png",
    video: "",
    component: T6,
    // maxLength: 6,
    textAlign: "center",
    inputSize: 200,
    stylesTextInput: {
      background: "none",
      borderBottom: "1.5px solid",
      fontSize: 22,
      paddingBottom: 3,
      height: 40,
      textTransform: "lowercase",
    },
    // hintBox: [
    //   {
    //     src: ["English", "math", "P.E", "art", "Vietnamese"],
    //     width: 582,
    //   },
    // ],
    titleQuestion: [
      {
        num: "3",
        title:
          "Listen and read. <headphone name='121' src='Audios/Page80/121 tieu-de.mp3'></headphone>",
        color: "#203c8f",
        // numberImg: ["a"],
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img style='height:15cm;margin-top:20px' src='img/FriendsPlus/Page80/E2/1.jpg'/>
      
        </div>
        
        `,
        // initialValue: ["math", "", "", "", "", "", "", ""],
        answer: [],
      },
    ],
  },
  4: {
    unit: "Unit 10",
    id: "SB5-2024-U10-P80-E4",
    // audio: "Audios/Page80/audio-e1.mp3",
    exerciseKey: "img/FriendsPlus/Page80/Key/E4answerKey.png",
    video: "",
    component: T6,
    // maxLength: 6,
    textAlign: "left",
    inputSize: 900,
    stylesTextInput: {
      background: "none",
      marginTop: -40,
      // borderBottom: "1.5px solid",
      fontSize: 22,
      paddingTop: 5,
      // height: 40,
      // textTransform: "lowercase",
    },
    // hintBox: [
    //   {
    //     src: ["English", "math", "P.E", "art", "Vietnamese"],
    //     width: 582,
    //   },
    // ],
    titleQuestion: [
      {
        num: "4",
        title:
          "Read again and write <i>T</i> (true) or <i>F</i> (false). Then correct the false sentences.",
        color: "#203c8f",
        left: 50,
        numberImg: ["b", "a"],
      },
    ],
    selectStyle: {
      height: 35,
      width: 35,
      textAlign: "center",
    },
    selectOptionRandom: true,
    selectOptionValues: ["T", "F"],
    character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img style='height:15.5cm' src='img/FriendsPlus/Page80/E2/1.jpg'/>
        <div style='line-height: 25px; font-size: 22px'>
          <div style='display: flex; margin-bottom: 20px'><b>1.</b>&ensp;You should get lots of exercise.&ensp; <u style='color: rgb(54,166,215)'>&ensp;T&ensp;</u></div>
          <div style='display: flex;height:1cm'><b>2.</b>&ensp;Playing outside with friends is good exercise.&ensp; <select id=5></select></div>
          <div style='margin-bottom: 20px'><input id=0/></div>
          <div style='display: flex;height:1cm'><b>3.</b>&ensp;You should eat candy every day.&ensp; <select id=6></select></div>
          <div style='margin-bottom: 20px'><input id=1/></div>
          <div style='display: flex;height:1cm'><b>4.</b>&ensp;Meat and fish aren't good for you.&ensp; <select id=7></select></div>
          <div style='margin-bottom: 20px'><input id=2/></div>
          <div style='display: flex;height:1cm'><b>5.</b>&ensp;Sugar is bad for your teeth.&ensp; <select id=8></select></div>
          <div style='margin-bottom: 20px'><input id=3/></div>
          <div style='display: flex;height:1cm'><b>6.</b>&ensp;You shouldn't drink milk.&ensp; <select id=9></select></div>
          <div style='margin-bottom: 20px'><input id=4/></div>
        </div>

        `,
        // initialValue: ["T"],
        answer: [
          ``,
          `You shouldn't eat candy every day.`,
          `Meat and fish are good for you.`,
          ``,
          `You should drink milk. / You should drink milk because it keeps your teeth strong.`,
          "T",
          "F",
          "F",
          "T",
          "F",
        ],
        InputRong: true,
      },
    ],
  },
  5: {
    unit: "Unit 10",
    id: "SB5-2024-U10-P80-E5",
    // audio: "Audios/Page80/audio-e1.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    styleContent: {
      width: "20%",
      marginLeft: 15,
    },
    titleQuestion: [
      {
        num: "5",
        title: "What is your favorite healthy food?",
        color: "#203c8f",
        left: 250,
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page64/E5/1.jpg",
          // audioUrl: "Audios/Page80/candy.mp3",
        },
      ],
    ],
  },
};

export default json;
