import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Review 1",
    id: "SB5-2024-R1-P26-E1",
    audio: "Audios/Page26/36.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page26/Key/E1answerKey.png",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen and match the children to the food and drink. There is one extra. <headphone name='36' src='Audios/Page26/36 tieude.mp3'></headphone>",
        color: "#203c8f",
        width: "24cm",
        // left: -50,
        numberImg: ["b"],
      },
    ],
    // hideBtnFooter: true,
    question: {
      // leftContent: 100,
      DrawLines: {
        multipleLine: true,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "46px",
              left: "250px",
              width: "173px",
              height: "65px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "46px",
              left: "846px",
              width: "218px",
              height: "65px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "238px",
              left: "120px",
              width: "121px",
              height: "65px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "238px",
              left: "922px",
              width: "176px",
              height: "65px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "429px",
              left: "234px",
              width: "165px",
              height: "65px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "429px",
              left: "841px",
              width: "175px",
              height: "65px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "367px",
              left: "534px",
              width: "188px",
              height: "234px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "116px",
              left: "534px",
              width: "170px",
              height: "234px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 7
        ],
        answers: ["2-7", "3-7", "4-6", "0-6", "1-6", "5-6"],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "1px 4px",
          // border: "2px solid",
          border: "none",
          // borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          // padding: "1px 4px",
          // borderRadius: "50%",
          color: "black",
          border: "none",
          borderBottom: "2px solid #00aeef",
          // borderColor: "#00aeef",
        },
        limitSelect: 6,
        listWords: [
          "What's in the boxes|?",
          "What's in there|?",
          "Are there some cakes|?",
          "Or some cookies to share|?",
          "Are there some sandwiches|?",
          "Or some balloons|?",
          "Look|! Masks and hats|!",
          "The party's this afternoon|.",
        ],
        answers: [],
        initialValue: [],
      },
      Layout: `
        <img style='height:13cm;margin-left:50px' src='img/FriendsPlus/Page26/E1/1.jpg'/>
        <input id=0 type='boxMatch'/>
        <input id=1 type='boxMatch'/>
        <input id=2 type='boxMatch'/>
        <input id=3 type='boxMatch'/>
        <input id=4 type='boxMatch'/>
        <input id=5 type='boxMatch'/>
        <input id=6 type='boxMatch'/>
        <input id=7 type='boxMatch'/>
        
      
      `,
    },
  },
  2: {
    unit: "Review 1",
    id: "SB5-2024-R1-P26-E2",
    exerciseKey: "img/FriendsPlus/Page26/Key/E2answerKey.png",
    video: "",
    hintBox: [
      {
        src: [
          "get up",
          "brush my teeth",
          "start school",
          "finish school",
          "do homework",
          "take a shower",
        ],
        width: 1119,
      },
    ],
    stylesTextInput: { textAlign: "center", width: 200 },
    // isHello: true,
    // KeyMau: true,
    component: T6,
    titleQuestion: [
      {
        num: "2",
        title:
          "Read about Mai's trip  to the supermarket. Write 2 words for each answer.",
        color: "#203c8f",
        numberImg: ["a"],
        left: -20,
      },
    ],
    questions: [
      {
        title: `
        <img style='height:7cm' src='img/FriendsPlus/Page26/E2/1.jpg'/>
        <div>
          <b>1.</b>&ensp;Mai and her mother go to the supermarket #.<br>
          <b>2.</b>&ensp;Mom often makes # on the weekend.<br>
          <b>3.</b>&ensp;They buy some chicken, some onions, and #.<br>
          <b>4.</b>&ensp;Dad sometimes # to cook dinner.<br>
          <b>5.</b>&ensp;The meal is #.<br>
        </div>
      `,
        answer: [
          "on Saturdays|every Saturdays",
          "chicken rice",
          "two cucumbers",
          "helps Mom",
          "always delicious|delicious",
        ],
      },
    ],
  },
};

export default json;
