import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 1",
    id: "SB5-2024-U1-P12-E1",
    audio: "Audios/Page12/13.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen, point, and repeat. <headphone name='13' src='Audios/Page12/13 tieude.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    styleContent: {
      width: "90%",
      marginLeft: 50,
    },
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page12/E1/1.jpg",
          audioUrl: "Audios/Page12/first.mp3",
        },
        {
          url: "img/FriendsPlus/Page12/E1/2.jpg",
        },
        {
          url: "img/FriendsPlus/Page12/E1/3.jpg",
          audioUrl: "Audios/Page12/then.mp3",
        },
        {
          url: "img/FriendsPlus/Page12/E1/4.jpg",
        },
        {
          url: "img/FriendsPlus/Page12/E1/5.jpg",
          audioUrl: "Audios/Page12/next.mp3",
        },
        {
          url: "img/FriendsPlus/Page12/E1/6.jpg",
        },
        {
          url: "img/FriendsPlus/Page12/E1/7.jpg",
          audioUrl: "Audios/Page12/every day.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 1",
    id: "SB5-2024-U1-P12-E2",
    // audio: "Audios/Page12/E2/audio-e2.mp3",
    video: "",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["gets", "Vinh", "at", "seven", "o'clock", "dressed", "."],
      },
      {
        src: ["Vinh", "gets", "dressed", "at", "seven", "o'clock."],
        styleElement: { color: "#10adcf", marginRight: 10 },
        marginLeft: 20,
      },
      {
        src: ["Linh", "snack", "Does", "school", "after", "a", "?"],
      },
      {
        src: ["Tuan", "don't", "Mai", "catch", "and", "bus", "the", "."],
      },
      {
        src: ["brush", "at", "teeth", "Do", "their", "night", "they", "?"],
      },
      {
        src: ["Walk", "school", "doesn't", "He", "to", "."],
      },
    ],
    stylesTextInput: { width: 550 },
    hideBtnFooter: true,
    component: T6,
    titleQuestion: [
      {
        num: "2",
        title: "Look at the text. Where does Sung Ni live? ",
        color: "#203c8f",
        numberImg: ["a"],
        left: 50,
      },
    ],
    questions: [
      {
        title: `
        <img style='height:14cm' src='img/FriendsPlus/Page12/E3/1.jpg'/><br>
                  <div>
                    Where does Sung Ni live?<br>#
                  </div>
                  
        
      `,
        answer: ["s", "es", "s", "s", "s", "es"],
      },
    ],
  },
  3: {
    unit: "Unit 1",
    id: "SB5-2024-U1-P12-E1",
    audio: "Audios/Page12/14.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "3",
        title:
          "Listen and Read <headphone name='14' src='Audios/Page12/14 tieude.mp3'></headphone>",
        color: "#203c8f",
        left: 100,
      },
    ],
    styleContent: {
      width: "70%",
      marginLeft: 150,
    },
    questionImage: [[{ url: "img/FriendsPlus/Page12/E3/1.jpg" }]],
  },
  4: {
    unit: "Unit 1",
    id: "SB5-2024-U1-P11-E4",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page12/Key/E4answerKey.png",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["gets", "Vinh", "at", "seven", "o'clock", "dressed", "."],
      },
      {
        src: ["Vinh", "gets", "dressed", "at", "seven", "o'clock."],
        styleElement: { color: "#10adcf", marginRight: 10 },
        marginLeft: 20,
      },
      {
        src: ["Linh", "snack", "Does", "school", "after", "a", "?"],
      },
      {
        src: ["Tuan", "don't", "Mai", "catch", "and", "bus", "the", "."],
      },
      {
        src: ["brush", "at", "teeth", "Do", "their", "night", "they", "?"],
      },
      {
        src: ["Walk", "school", "doesn't", "He", "to", "."],
      },
    ],
    stylesTextInput: { textAlign: "center", width: 50 },
    // hideBtnFooter: true,
    component: T6,
    titleQuestion: [
      {
        num: "4",
        title: "Read again and write T(true) or F(false).",
        color: "#203c8f",
        numberImg: ["b"],
        left: 50,
      },
    ],
    questions: [
      {
        title: `
        <img style='height:14cm' src='img/FriendsPlus/Page12/E3/1.jpg'/><br>
              <div>
                <b>1</b>&ensp;Sung Ni lives in a town.#<br>
                <b>2</b>&ensp;Sung Ni gets up early every day.#<br>
                <b>3</b>&ensp;First, Sung Ni helps her parents on the farm.#<br>
                <b>4</b>&ensp;Sung Ni catches the bus to school.#<br>
                <b>5</b>&ensp;Sung Ni does her homework in the evenings.#<br>
                <b>6</b>&ensp;Sung Ni has a busy day.#<br>
              </div>
        
      `,
        answer: ["F", "T", "F", "F", "T", "T"],
      },
    ],
  },
  5: {
    unit: "Unit 1",
    id: "SB5-2024-U1-P11-E5",
    audio: "",
    video: "",
    recorder: true,
    exerciseKey: "img/FriendsPlus/Page12/Key/E5answerKey.png",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["gets", "Vinh", "at", "seven", "o'clock", "dressed", "."],
      },
      {
        src: ["Vinh", "gets", "dressed", "at", "seven", "o'clock."],
        styleElement: { color: "#10adcf", marginRight: 10 },
        marginLeft: 20,
      },
      {
        src: ["Linh", "snack", "Does", "school", "after", "a", "?"],
      },
      {
        src: ["Tuan", "don't", "Mai", "catch", "and", "bus", "the", "."],
      },
      {
        src: ["brush", "at", "teeth", "Do", "their", "night", "they", "?"],
      },
      {
        src: ["Walk", "school", "doesn't", "He", "to", "."],
      },
    ],
    textareaStyle: { paddingTop: 3, width: 650 },
    hideBtnFooter: true,
    component: T6,
    titleQuestion: [
      {
        num: "5",
        title: "How do children get to school in other countries.",
        color: "#203c8f",
        // numberImg: ["b"],
        left: 0,
      },
    ],
    questions: [
      {
        title: `
        
      `,
        answer: [""],
      },
    ],
  },
};

export default json;
