import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Fluency Time! 1",
    id: "SB5-2024-FL1-P29-E1",
    audio: "Audios/Page29/39.mp3",
    video: "",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen, point, and repeat. <headphone name='39'  src='Audios/Page29/39 tieude.mp3'></headphone>",
        color: "#07a859",
        // numberImg: "c",
        left: 100,
      },
    ],
    styleContent: {
      width: "80%",
      marginLeft: 170,
    },
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page29/E1/1.jpg",
          audioUrl: "Audios/Page29/fruit.mp3",
        },
        {
          url: "img/FriendsPlus/Page29/E1/2.jpg",
        },
        {
          url: "img/FriendsPlus/Page29/E1/3.jpg",
          audioUrl: "Audios/Page29/vegetables.mp3",
        },
        {
          url: "img/FriendsPlus/Page29/E1/4.jpg",
        },
        {
          url: "img/FriendsPlus/Page29/E1/5.jpg",
          audioUrl: "Audios/Page29/grains.mp3",
        },
        {
          url: "img/FriendsPlus/Page29/E1/6.jpg",
        },
        {
          url: "img/FriendsPlus/Page29/E1/7.jpg",
          audioUrl: "Audios/Page29/cheese.mp3",
        },
        {
          url: "img/FriendsPlus/Page29/E1/8.jpg",
        },
        {
          url: "img/FriendsPlus/Page29/E1/9.jpg",
          audioUrl: "Audios/Page29/dairy.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Fluency Time! 1",
    id: "SB5-2024-FL1-P29-E2",
    exerciseKey: "img/FriendsPlus/Page29/Key/E2answerKey.png",
    audio: "Audios/Page29/40.mp3",
    video: "",
    component: T6,
    inputSize: 180,
    recorder: true,
    hideBtnFooter: true,
    textAlign: "center",
    textareaStyle: { width: 800, paddingTop: 5 },
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and read. <headphone name='40' src='Audios/Page29/40 tieude.mp3'></headphone>",
        color: "#07a859",
        // numberImg: ["a"],
        left: 100,
      },
    ],

    questions: [
      {
        title: `
        <img style='height:14cm' src='img/FriendsPlus/Page29/E2/1.jpg'/>
      `,
        answer: [
          "twenty-one",
          "sixty-three",
          "eighty-nine",
          "thirty-four",
          "forty-five",
          "ninety-six",
          "seventy-seven",
          "a hundred",
        ],
      },
    ],
  },
  3: {
    unit: "Fluency Time! 1",
    id: "SB5-2024-FL1-P29-E3",
    // audio: "Audios/Page56/81.mp3",
    exerciseKey: "img/FriendsPlus/Page29/Key/E3answerKey.png",
    audio: "",
    video: "",
    component: T6,
    // inputSize: 180,
    checkDuplicated: true,
    hideBtnFooter: true,
    textAlign: "center",
    stylesTextInput: { borderBottom: "none" },
    titleQuestion: [
      {
        num: "3",
        title: "Complete the mind map about healthy foods.",
        color: "#07a859",
        numberImg: ["a"],
        left: 100,
      },
    ],

    questions: [
      {
        title: `
        <img style='height:14cm' src='img/FriendsPlus/Page29/E2/1.jpg'/>
        <table style="width: 100%; border-radius:20px; border-collapse: collapse;">
          <tr>
            <th style="width:25%; background-color: rgb(220,235,196); border: 3px solid green;text-align:center; padding: 8px; ">Fruit</th>
            <th style="width:25%; background-color: rgb(220,235,196); border: 3px solid green;text-align:center; padding: 8px; ">Vegetables</th>
            <th style="width:25%; background-color: rgb(220,235,196); border: 3px solid green;text-align:center; padding: 8px; ">Grains</th>
            <th style="width:25%; background-color: rgb(220,235,196); border: 3px solid green;text-align:center; padding: 8px; ">Dairy</th>
          </tr>
          <tr>
            <td style="border: 3px solid green;text-align:center; padding: 8px;">#</td>
            <td style="border: 3px solid green;text-align:center; padding: 8px;">#</td>
            <td style="border: 3px solid green;text-align:center; padding: 8px;">#</td>
            <td style="border: 3px solid green;text-align:center; padding: 8px;">#</td>
          </tr>
          <tr>
            <td style="border: 3px solid green;text-align:center; padding: 8px;">#</td>
            <td style="border: 3px solid green;text-align:center; padding: 8px;">#</td>
            <td style="border: 3px solid green;text-align:center; padding: 8px;">#</td>
            <td style="border: 3px solid green;text-align:center; padding: 8px;">#</td>
          </tr>
          <tr>
            <td style="border: 3px solid green;text-align:center; padding: 8px;">#</td>
            <td style="border: 3px solid green;text-align:center; padding: 8px;">#</td>
            <td style="border: 3px solid green;text-align:center; padding: 8px;">#</td>
            <td style="border: 3px solid green;text-align:center; padding: 8px;">#</td>
          </tr>
          <tr>
            <td style="border: 3px solid green;text-align:center; padding: 8px;">#</td>
            <td style="border: 3px solid green;text-align:center; padding: 8px;">#</td>
            <td style="border: 3px solid green;text-align:center; padding: 8px;">#</td>
            <td style="border: 3px solid green;text-align:center; padding: 8px;">#</td>
          </tr>
        </table>
      `,
        InputRong: true,
        answer: [
          "apples|bananas|oranges|lemons",
          "peppers|carrots|lettuce|broccoli",
          "rice|bread|noodles|cereal",
          "milk|yogurt|cheese|",
          "apples|bananas|oranges|lemons",
          "peppers|carrots|lettuce|broccoli",
          "rice|bread|noodles|cereal",
          "milk|yogurt|cheese|",
          "apples|bananas|oranges|lemons",
          "peppers|carrots|lettuce|broccoli",
          "rice|bread|noodles|cereal",
          "milk|yogurt|cheese|",
          "apples|bananas|oranges|lemons",
          "peppers|carrots|lettuce|broccoli",
          "rice|bread|noodles|cereal",
          "milk|yogurt|cheese|",
        ],
      },
    ],
  },
  4: {
    unit: "Fluency Time! 1",
    id: "SB5-2024-FL1-P29-E4",
    audio: "",
    video: "",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
    },
    hintBox: [
      {
        src: [
          "In the evening, always eat dinner dinner with my family. My mom get home at ...",
        ],
        styleElement: { color: "#10adcf", marginRight: 10 },
        marginLeft: 20,
      },
    ],
    textAlign: "center",
    stylesTextInput: {
      borderBottom: "none",
    },
    hideBtnFooter: true,
    component: T6,
    titleQuestion: [
      {
        num: "4",
        title:
          "<headphone name='' margin-left='-20px' typeimg='think'></headphone> Add more foods to the mind map.",
        color: "#07a859",
        left: 100,
        numberImg: ["a"],
      },
    ],
    questions: [
      {
        title: `
        <img style='height:14cm' src='img/FriendsPlus/Page29/E2/1.jpg'/>
        <table style="width: 100%; border-radius:20px; border-collapse: collapse;">
          <tr>
            <th style="width:25%; background-color: rgb(220,235,196); border: 3px solid green;text-align:center; padding: 8px; ">Fruit</th>
            <th style="width:25%; background-color: rgb(220,235,196); border: 3px solid green;text-align:center; padding: 8px; ">Vegetables</th>
            <th style="width:25%; background-color: rgb(220,235,196); border: 3px solid green;text-align:center; padding: 8px; ">Grains</th>
            <th style="width:25%; background-color: rgb(220,235,196); border: 3px solid green;text-align:center; padding: 8px; ">Dairy</th>
          </tr>
          <tr>
            <td style="border: 3px solid green;text-align:center; padding: 8px;">#</td>
            <td style="border: 3px solid green;text-align:center; padding: 8px;">#</td>
            <td style="border: 3px solid green;text-align:center; padding: 8px;">#</td>
            <td style="border: 3px solid green;text-align:center; padding: 8px;">#</td>
          </tr>
          <tr>
            <td style="border: 3px solid green;text-align:center; padding: 8px;">#</td>
            <td style="border: 3px solid green;text-align:center; padding: 8px;">#</td>
            <td style="border: 3px solid green;text-align:center; padding: 8px;">#</td>
            <td style="border: 3px solid green;text-align:center; padding: 8px;">#</td>
          </tr>
          <tr>
            <td style="border: 3px solid green;text-align:center; padding: 8px;">#</td>
            <td style="border: 3px solid green;text-align:center; padding: 8px;">#</td>
            <td style="border: 3px solid green;text-align:center; padding: 8px;">#</td>
            <td style="border: 3px solid green;text-align:center; padding: 8px;">#</td>
          </tr>
          <tr>
            <td style="border: 3px solid green;text-align:center; padding: 8px;">#</td>
            <td style="border: 3px solid green;text-align:center; padding: 8px;">#</td>
            <td style="border: 3px solid green;text-align:center; padding: 8px;">#</td>
            <td style="border: 3px solid green;text-align:center; padding: 8px;">#</td>
          </tr>
        </table>
      `,
        answer: [
          "on Saturdays",
          "chicken rice",
          "two cucumbers",
          "helps Mom",
          "always delicious",
        ],
      },
    ],
  },
  5: {
    unit: "Review 1",
    id: "SB5-2024-RV1-P29-E5",
    audio: "",
    video: "",
    component: Circle_Write,
    inputSize: 500,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "5",
        title: "Look and color.",
        color: "#07a859",
        left: -50,
        // numberImg: ["a"],
      },
    ],
    question: {
      ThSmile: true,
      // leftContent: 100,
      DrawLines: {
        multipleLine: true,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "8px 18px",
          border: "2px solid",
          color: "rgb(220,235,196)",
          marginLeft: 10,
          // background: "gray",
          borderRadius: "50%",
          borderColor: "gray",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          width: 10,
          borderRadius: "50%",
          background: "gray",
          color: "gray",
          border: "2px solid",
          borderColor: "gray",
        },
        limitSelect: 3,
        listWords: ["a", "a a", "a a a"],
        answers: ["0-0"],
        initialValue: [],
      },
      Layout: `

        <div style='background:rgb(220,235,196);border:3px solid rgb(4,173,94);padding:10px 20px;box-shadow:4px 2px 3px gray;border-radius:14px'>
          <span style='color:rgb(4,173,94); font-weight:bold;font-size:36px;margin-left:10px'>
            My work in Starter Unit, Units 1-3 & Fluency Time! 1 is
          </span>
            <div style='display:flex;margin-top:30px'>
              <div>
                <input id=0 select='Tick' type='Circle'/> 
                <span style='color:rgb(4,173,94); font-weight:bold;font-size:36px;margin-left:10px'>OK</span>
              </div>
              <div style='margin:0px 100px'>
                <input id=1 select='Tick' type='Circle'/> 
                <span style='color:rgb(4,173,94); font-weight:bold;font-size:36px;margin-left:10px'>GOOD</span>
              </div>
              <div>
                <input id=2 select='Tick' type='Circle'/> 
                <span style='color:rgb(4,173,94); font-weight:bold;font-size:36px;margin-left:10px'>EXCELLENT</span>
              </div>
            </div>
        </div>

      
    
     
      `,
    },
  },
};

export default json;
