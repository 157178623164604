import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  6: {
    unit: "Review 2",
    id: "SB5-2024-R2-P50-E6",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page50/Key/E6answerKey.png",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "6",
        title: "Read and match the descriptions with the words.",
        color: "#203c8f",
        width: "24cm",
        // left: -50,
        numberImg: ["b"],
      },
    ],
    // hideBtnFooter: true,
    question: {
      // leftContent: 100,
      DrawLines: {
        lineToNotCenter: true,
        // multipleLine: true,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "62px",
              left: "127px",
              width: "146px",
              height: "117px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "188px",
              left: "127px",
              width: "146px",
              height: "117px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "283px",
              left: "561px",
              width: "434px",
              height: "38px",

              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "102px",
              left: "585px",
              width: "490px",
              height: "38px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "192px",
              left: "542px",
              width: "395px",
              height: "38px",

              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "146px",
              left: "567px",
              width: "445px",
              height: "38px",

              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "57px",
              left: "538px",
              width: "389px",
              height: "38px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "62px",
              left: "1003px",
              width: "146px",
              height: "117px",

              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "314px",
              left: "1003px",
              width: "146px",
              height: "117px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "188px",
              left: "1003px",
              width: "146px",
              height: "117px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 9
          {
            boxMatchStyle: {
              position: "absolute",
              top: "236px",
              left: "589px",
              width: "488px",
              height: "38px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 10
          {
            boxMatchStyle: {
              position: "absolute",
              top: "314px",
              left: "127px",
              width: "146px",
              height: "117px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 11
        ],
        answers: ["11-6", "3-7", "5-8", "1-2", "0-4", "10-9"],
        initialValue: ["11-6"],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "1px 4px",
          // border: "2px solid",
          border: "none",
          // borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          // padding: "1px 4px",
          // borderRadius: "50%",
          color: "black",
          border: "none",
          borderBottom: "2px solid #00aeef",
          // borderColor: "#00aeef",
        },
        limitSelect: 6,
        listWords: [
          "What's in the boxes|?",
          "What's in there|?",
          "Are there some cakes|?",
          "Or some cookies to share|?",
          "Are there some sandwiches|?",
          "Or some balloons|?",
          "Look|! Masks and hats|!",
          "The party's this afternoon|.",
        ],
        answers: [],
        initialValue: [],
      },
      Layout: `
        <img style='height:10cm;margin-left:50px' src='img/FriendsPlus/Page50/E6/1.jpg'/>
        <input id=0 type='boxMatch'/>
        <input id=1 type='boxMatch'/>
        <input id=2 type='boxMatch'/>
        <input id=3 type='boxMatch'/>
        <input id=4 type='boxMatch'/>
        <input id=5 type='boxMatch'/>
        <input id=6 type='boxMatch'/>
        <input id=7 type='boxMatch'/>
        <input id=8 type='boxMatch'/>
        <input id=9 type='boxMatch'/>
        <input id=10 type='boxMatch'/>
        <input id=11 type='boxMatch'/>
        
      
      `,
    },
  },
  7: {
    unit: "Review 2",
    id: "SB5-2024-R2-P50-E7",
    exerciseKey: "img/FriendsPlus/Page50/Key/E7answerKey.png",
    video: "",
    // recorder: true,
    hintBox: [
      {
        src: [
          "get up",
          "brush my teeth",
          "start school",
          "finish school",
          "do homework",
          "take a shower",
        ],
        width: 1119,
      },
    ],
    component: Circle_Write,
    titleQuestion: [
      {
        num: "7",
        title: "Read and circle the correct word.",
        color: "#203c8f",
        width: "24cm",
        // left: -50,
        numberImg: ["b"],
      },
    ],
    // hideBtnFooter: true,
    question: {
      // leftContent: 100,
      DrawLines: {
        multipleLine: true,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "62px",
              left: "127px",
              width: "146px",
              height: "117px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        underlineStyle: { borderBottom: "none" },
        inputStyle: {
          borderBottom: "none",
          textAlign: "center",
          fontSize: 22,
          width: 138,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "1px 4px",
          border: "2px solid white",
          // borderColor: "transparent",
          // fontWeight: "bold",
        },
        selectWordStyle: {
          // fontWeight: "bold",
          // padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid #00aeef",
          // borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "a._wasn't <br> b._weren't",
          "a._were <br> b._was",
          "a._weren't <br> b._wasn't",
          "a._started <br> b._cooked",
          "a._live <br> b._didn't_live",
          "a._didn't_use <br> b._used",
        ],
        answers: ["0-0", "1-0", "2-0", "3-4", "4-4", "5-0"],
        initialValue: ["0-0"],
      },
      Layout: `
        <div style='position:relative'>
          <img style='height:15cm;margin-left:50px' src='img/FriendsPlus/Page50/E7/1.jpg'/>
          
        </div>
        <div style='display:flex;justify-content:center;margin-top:10px'>
          <div>
            <div style='display:flex'>
              <b>1.</b>
              <div style='margin-left:10px'><input id=0 type='Circle'/></div>
            </div>
            <div style='display:flex'>
              <b>4.</b>
              <div style='margin-left:10px'><input id=3 type='Circle'/></div>
            </div>
          </div>
          <div style='margin:0px 100px'>
            <div style='display:flex'>
              <b>2.</b>
              <div style='margin-left:10px'><input id=1 type='Circle'/></div>
            </div>
            <div style='display:flex'>
              <b>5.</b>
              <div style='margin-left:10px'><input id=4 type='Circle'/></div>
            </div>
          </div>
          <div>
            <div style='display:flex'>
              <b>3.</b>
              <div style='margin-left:10px'><input id=2 type='Circle'/></div>
            </div>
            <div style='display:flex'>
              <b>6.</b>
              <div style='margin-left:10px'><input id=5 type='Circle'/></div>
            </div>
          </div>
          
        </div>
        

        
      
      `,
    },
  },
  8: {
    unit: "Review 2",
    id: "SB5-2024-R2-P50-E8",
    exerciseKey: "img/FriendsPlus/Page50/Key/E8answerKey.png",
    video: "",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
    },
    hintBox: [
      {
        src: [
          "In the evening, always eat dinner dinner with my family. My mom get home at ...",
        ],
        styleElement: { color: "#10adcf", marginRight: 10 },
        marginLeft: 20,
      },
    ],
    inputSize: 700,
    textareaStyle: {
      textAlign: "center",
      paddingTop: 3,
      resize: "none",
      width: 900,
    },
    hideBtnFooter: true,
    component: T6,
    titleQuestion: [
      {
        num: "8",
        title: "Look at the picture and answer the questions.",
        color: "#203c8f",
        width: 1000,
        numberImg: ["c"],
        left: 50,
      },
    ],
    questions: [
      {
        title: `
        <img style='height:7cm' src='img/FriendsPlus/Page50/E5/1.jpg'/>
        <div>
          <b>1</b>&ensp;Where did the ancient Egyptians live?<br>#<br>
          <b>2</b>&ensp;How did they travel?<br>#<br>
          <b>3</b>&ensp;What special buildings did they have?<br>#<br>
          <b>4</b>&ensp;What did they use to build pyramids?<br>#<br>
        </div>
      `,
        answer: [
          "on Saturdays",
          "chicken rice",
          "two cucumbers",
          "helps Mom",
          "always delicious",
        ],
      },
    ],
  },
};

export default json;
