import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 7",
    id: "SB5-2024-U7-P59-E1",
    audio: "Audios/Page59/86.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page59/Key/E1answerKey.png",
    component: T6,
    maxLength: 1,

    textAlign: "center",
    stylesTextInput: {
      borderBottom: "none",
      fontSize: 24,
      padding: 0,
      background: "none",
    },
    inputSize: 35,
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen and write V (Vinh), L (Long), T (Thu), or M (Mai). <headphone name='86' src='Audios/Page59/86 tieu-de.mp3'></headphone>",
        color: "#203c8f",
        numberImg: ["b"],
      },
    ],
    questionImage: [],
    // isHello: true,
    questions: [
      {
        title: `
        <div style='position:relative'>
				  
          <div style='position:absolute;top:202px;left:207px'>#</div>
          <div style='position:absolute;top:202px;left:483PX'>#</div>
          <div style='position:absolute;top:202px;left:1046px'>#</div>
          <img style='height:65mm' src='img/FriendsPlus/Page59/E1/1.jpg'/>
        </div>
        
        `,
        answer: ["T", "L", "M"],
      },
    ],
  },
  2: {
    unit: "Unit 7",
    id: "SB5-2024-U7-P59-E2",
    audio: "Audios/Page59/86.mp3",
    video: "",
    checkDuplicated: true,
    exerciseKey: "img/FriendsPlus/Page59/Key/E2answerKey.png",
    component: T6,
    // maxLength: 5,
    textAlign: "center",
    textareaStyle: {
      width: 700,
      paddingTop: 3,
    },
    inputSize: 300,
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen again and write the children’s favorite things. <headphone name='86' src='Audios/Page59/86 tieu-de.mp3'></headphone>",
        color: "#203c8f",
        left: 50,
        numberImg: ["a"],
      },
    ],
    questionImage: [],
    // isHello: true,
    questions: [
      {
        title: `
        <div style='display:flex;'>
          <div style='margin-right:100px'>
            <b>1</b>&ensp;#<br>
            <b>2</b>&ensp;#
          </div>
          <div>
            <b>3</b>&ensp;#<br>
            <b>4</b>&ensp;#
          </div>
        </div>
        
        `,
        answer: [
          "going on the boat|the elephants and lions|the water slide|going swimming",
          "going on the boat|the elephants and lions|the water slide|going swimming",
          "going on the boat|the elephants and lions|the water slide|going swimming",
          "going on the boat|the elephants and lions|the water slide|going swimming",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 7",
    id: "SB5-2024-U7-P59-E3",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "3",
        title: " Ask and answer about the children above.",
        color: "#203c8f",
        numberImg: ["c"],
        left: 50,
      },
    ],
    styleContent: {
      width: "80%",
      marginLeft: 100,
    },
    questionImage: [[{ url: "img/FriendsPlus/Page59/E3/1.jpg" }]],
  },
  4: {
    unit: "Unit 7",
    id: "SB5-2024-U7-P59-E4",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "4",
        title: "Say what you did yesterday.",
        color: "#203c8f",
        numberImg: ["c"],
        left: 350,
      },
    ],
    styleContent: {
      width: "35%",
      marginLeft: 350,
    },
    questionImage: [[{ url: "img/FriendsPlus/Page59/E4/1.jpg" }]],
  },
  5: {
    unit: "Unit 7",
    id: "SB5-2024-U7-P59-E5",
    audio: "",
    video: "",

    component: T6,
    // maxLength: 5,
    // textAlign: "center",
    textareaStyle: {
      width: 778,
      paddingTop: 5,
    },
    inputSize: 720,
    titleQuestion: [
      {
        num: "5",
        title: "Write a tourist leaflet. Use the example to help you.",
        color: "#203c8f",
        numberImg: ["a"],
        left: 80,
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='position:relative'>
          <img style='height:20cm' src='img/FriendsPlus/Page59/E5/1.jpg'/>
				  <div style='position:absolute;top:360px;left:57px'>
            <textarea id='0' rows=10></textarea>
          </div>
        </div>
        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
