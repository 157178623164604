import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Fluency Time! 2",
    id: "SB5-2024-FL2-P52-E1",
    audio: "Audios/Page52/73.mp3",
    video: "",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen. Read and say. <headphone name='73' src='Audios/Page52/73 tieude.mp3'></headphone>",
        color: "#ef6136",
        numberImg: ["c"],
        left: 100,
      },
    ],
    styleContent: {
      width: "80%",
      marginLeft: 100,
    },
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page52/E1/1.jpg",
          audioUrl: "Audios/Page52/73 01.mp3",
        },
        {
          url: "img/FriendsPlus/Page52/E1/2.jpg",
          audioUrl: "Audios/Page52/73 02.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Fluency Time! 2",
    id: "SB5-2024-FL2-P52-E2",
    exerciseKey: "img/FriendsPlus/Page52/Key/E2answerKey.png",
    audio: "Audios/Page52/74.mp3",
    video: "",
    component: T6,
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and complete the sentences. <headphone name='74' src='Audios/Page52/74 tieude.mp3'></headphone>",
        color: "#ef6136",
        width: "24cm",
        left: 50,
        numberImg: ["a"],
      },
    ],
    stylesTextInput: {
      borderBottom: "none",
      textAlign: "center",
      background: "none",
      width: 161,
    },
    questions: [
      {
        title: `
        <div style='position:relative'>
          <img style='height:7cm' src='img/FriendsPlus/Page52/E2/1.jpg'/>
          <div style='position:absolute;top:71px;left:134px'>#</div>
          <div style='position:absolute;top:39px;left:769px'>#</div>
          <div style='position:absolute;top:174px;left:167px'>#</div>
          <div style='position:absolute;top:160px;left:755px'>#</div>
          <div style='position:absolute;top:201px;left:773px'>#</div>
        </div>
      `,
        answer: ["vacation", "wasn't", "bad", "foggy", "cold"],
      },
    ],
  },
  3: {
    unit: "Fluency Time! 2",
    id: "SB5-2024-FL2-P52-E3",
    // audio: "Audios/Page56/81.mp3",
    video: "",
    recorder: true,
    hintBox: [
      {
        src: [
          "noodles",
          "sandwich",
          "juice",
          "pizza",
          "mushrooms",
          "fish",
          "rice",
          "chicken",
        ],
        width: 999,
      },
    ],
    stylesTextInput: { textAlign: "center", width: 200 },
    hideBtnFooter: true,
    component: T6,
    titleQuestion: [
      {
        num: "3",
        title: "Ask and answer.",
        color: "#ef6136",
        numberImg: ["c"],
        left: 50,
      },
    ],
    questions: [
      {
        title: `
        <img style='height:12cm' src='img/FriendsPlus/Page52/E3/1.jpg'/>
        
      `,
        answer: [
          "on Saturdays",
          "chicken rice",
          "two cucumbers",
          "helps Mom",
          "always delicious",
        ],
      },
    ],
  },
};

export default json;
