import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";

const json = {
  1: {
    unit: "Fluency Time! 4",
    id: "SB5-2024-FT4-P98-E1",
    audio: "Audios/Page98/145.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    styleContent: {
      width: "85%",
      marginLeft: 100,
    },
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen. Read and say. <headphone name='145' src='Audios/Page98/145 tieu-de.mp3'></headphone>",
        color: "#203c8f",
        numberImg: ["c"],
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page98/E1/1.jpg",
          audioUrl: "Audios/Page98/145 01.mp3",
        },
        {
          url: "img/FriendsPlus/Page98/E1/2.jpg",
          audioUrl: "Audios/Page98/145 02.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Fluency Time! 4",
    id: "SB5-2024-FT4-P98-E2",
    audio: "Audios/Page98/146.mp3",
    exerciseKey: "img/FriendsPlus/Page98/Key/E2answerKey.png",
    video: "",
    component: T6,
    maxLength: 1,
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen. Check (✓) or cross (✗) the programs that the children want to watch. <headphone name='146' src='Audios/Page98/146 tieu-de.mp3'></headphone>",
        color: "#203c8f",
        numberImg: ["b"],
        left: 50,
      },
    ],
    selectStyle: {
      color: "#00ADFE",
      width: 45,
      height: 45,
      textAlign: "center",
    },
    select: true, //phân biệt dấu "✓", "✗"
    selectOptionValues: ["✓", "✗"],
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      textAlign: "center",
      width: 35,
    },
    questions: [
      {
        title: `
        <div style='position:relative'>
          <img style='height:5cm' src='img/FriendsPlus/Page98/E2/1.jpg'/><br>
          <div style='position:absolute; top:66px; left:305px'><select id=0></select></div>
          <div style='position:absolute; top:66px; left:583px'><select id=1></select></div>
          <div style='position:absolute; top:66px; left:820px'><select id=2></select></div>

          <div style='position:absolute; top:112px; left:305px'><select id=3></select></div>
          <div style='position:absolute; top:112px; left:583px'><select id=4></select></div>
          <div style='position:absolute; top:112px; left:820px'><select id=5></select></div>
        </div>
        
      `,
        answer: ["✓", "✗", "✓", "✗", "✓", "✓"],
      },
    ],
  },
  3: {
    unit: "Fluency Time! 4",
    id: "SB5-2024-FT4-P98-E3",
    // audio: "Audios/Page98/audio-e1.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    styleContent: {
      width: "85%",
      marginLeft: 100,
    },
    titleQuestion: [
      {
        num: "3",
        title: "Ask and answer.",
        color: "#203c8f",
        numberImg: ["c"],
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page98/E3/1.jpg",
          // audioUrl: "Audios/Page64/candy.mp3",
        },
      ],
    ],
  },
};

export default json;
