import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 9",
    id: "SB5-2024-U9-P67-E1",
    audio: "Audios/Page67/99.mp3",
    video: "Videos/Unit 9.mp4",
    component: UI,
    recorder: true,
    styleContent: {
      width: "80%",
      marginLeft: 50,
    },
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen to the story and repeat. <headphone name='99' src='Audios/Page67/99 tieu-de.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page66/E3/1.jpg",
          audioUrl: "Audios/Page66/hinh1.mp3",
        },
        {
          url: "img/FriendsPlus/Page66/E3/2.jpg",
          audioUrl: "Audios/Page66/hinh2.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page66/E3/3.jpg",
          audioUrl: "Audios/Page66/hinh3.mp3",
        },
        {
          url: "img/FriendsPlus/Page66/E3/4.jpg",
          audioUrl: "Audios/Page66/hinh4.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 9",
    id: "SB5-2024-U9-P67-E2",
    audio: "Audios/Page67/100.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    styleContent: {
      width: "95%",
      // marginTop: 300,
    },
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and say. <headphone name='100' src='Audios/Page67/100 tieu-de.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [{ url: "img/FriendsPlus/Page67/E2/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page67/E2/2.jpg" },
        {
          url: "img/FriendsPlus/Page67/E2/3.jpg",
          // audioUrl: "Audios/Page67/100 01.mp3",
        },
        { url: "img/FriendsPlus/Page67/E2/4.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page67/E2/5.jpg" },
        {
          url: "img/FriendsPlus/Page67/E2/6.jpg",
          // audioUrl: "Audios/Page67/100 02.mp3",
        },
        { url: "img/FriendsPlus/Page67/E2/7.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page67/E2/8.jpg" }],
    ],
  },
  3: {
    unit: "Unit 9",
    id: "SB5-2024-U9-P67-E3",
    // audio: "Audios/Page55/80.mp3",
    exerciseKey: "img/FriendsPlus/Page67/Key/E3answerKey.png",
    video: "",
    component: T6,
    // maxLength: 6,
    textAlign: "center",
    inputSize: 180,
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      // fontSize: 22,
      height: 27,
      textTransform: "lowercase",
      // borderStyle: "dotted",
      textAlign: "center",
    },
    hintBox: [
      {
        src: ["fast", "polite", "hard", "neat", "loud", "<s>good</s>"],
        width: 682,
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: "Write.",
        color: "#203c8f",
        numberImg: ["a"],
      },
    ],
    // hideBtnFooter: true,
    selectStyle: {
      height: 35,
      width: 35,
      textAlign: "center",
    },
    selectOptionRandom: true,
    selectOptionValues: ["T", "F"],
    questions: [
      {
        title: `
        <hintbox id='0'></hintbox>
        <div style='line-height: 50px; margin-top: 30px'>
          <div style=''><b>1.</b>&ensp;She always does her homework <span style='color: rgb(54,166,215) '>well</span>.</div>
          <div style=''><b>2.</b>&ensp;You mustn't talk # in the library.</div>
          <div style=''><b>3.</b>&ensp;You should study # at school.</div>
          <div style=''><b>4.</b>&ensp;Please write # in your notebook.</div>
          <div style=''><b>5.</b>&ensp;Nam speaks # to his grandma.</div>
          <div style=''><b>6.</b>&ensp;I can't run #.</div>
        </div>
          
        
        `,
        // initialValue: ["math", "", "", "", "", "", "", ""],
        answer: ["loudly", "hard", "neatly", "politely", "fast"],
      },
    ],
  },
  4: {
    unit: "Unit 9",
    id: "SB5-2024-U9-P67-E4",
    // audio: "Audios/Page55/80.mp3",
    exerciseKey: "img/FriendsPlus/Page67/Key/E4answerKey.png",
    // video: "",
    component: T6,
    maxLength: 1,
    textAlign: "center",
    inputSize: 50,
    stylesTextInput: {
      background: "none",
      borderBottom: "1.5px solid",
      // fontSize: 22,
      paddingBottom: 3,
      height: 40,
      textTransform: "lowercase",
    },
    // hintBox: [
    //   {
    //     src: ["English", "math", "P.E", "art", "Vietnamese"],
    //     width: 582,
    //   },
    // ],
    titleQuestion: [
      {
        num: "4",
        title:
          "Look, read and write. <img style='height:1cm; width: 2cm; margin-bottom: 5px' src='img/FriendsPlus/IconTitle/1.jpg'/>",
        color: "#203c8f",
        // numberImg: ["a"],
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='line-height: 50px'>
          <img style='height:5cm;margin-top:20px' src='img/FriendsPlus/Page67/E4/1.jpg'/>
          <div style='display: flex; margin-top: 30px'><b>1.</b>&ensp;Always wash your hands well. &ensp;<u style='color:rgb(54,166,215);'>&ensp;d&ensp;</u></div>
          <div style='display: flex'><b>2.</b>&ensp;Throw your litter neatly. #</div>
          <div style='display: flex'><b>3.</b>&ensp;Please don't run fast inside the school. #</div>
          <div style='display: flex'><b>4.</b>&ensp;Speak quietly in the library. #</div>
        </div>
      
        </div>
        
        `,
        // initialValue: ["math", "", "", "", "", "", "", ""],
        answer: ["b", "a", "c"],
      },
    ],
  },
  5: {
    unit: "Unit 9",
    id: "SB5-2024-U9-P67-E5",
    // audio: "img/FriendsPlus/Page110/Audio/audio-e2-p40.mp3",
    video: "",
    component: UI,
    recorder: true,
    styleContent: {
      width: "35%",
      marginLeft: 350,
    },
    questionImage: [[{ url: "img/FriendsPlus/Page67/E4/2.jpg" }]],
  },
};

export default json;
